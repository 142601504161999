import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "@abb/abb-common-ux-react";
import { useSelector } from "react-redux";
import "./SourceTree.css";
import { Updater } from "use-immer";
import { CreateComponentModelStates } from "../../../../Models/CreateCompModels/CreateCompModel";
import { NodeSetEditorData } from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import { nodeSetEditorValuesSelector } from "../../Action/nodeTreeStore/nodeTreeReducers";
import { REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE } from "./TreeComponentSourceNode";
import { IState } from "../../../../Reducer";

interface Props {
  getNodeId: (id: any) => void;
  closeNotification: () => void;
  stateValidation: CreateComponentModelStates;
  setState: Updater<CreateComponentModelStates>;
}

const ValidationNotification: React.FC<Props> = ({
  getNodeId,
  closeNotification,
  stateValidation,
  setState,
}) => {
  const { errorMessage } = useSelector(nodeSetEditorValuesSelector);
  const [selectedNode, setSelectedNode] = useState<string>("");
  const commonState = useSelector((state: IState) => state.commonState);

  const getParentNodes = useCallback(
    (nodeId: string, optionalParent?: string[]) => {
      let parentNodeItems: string[] = optionalParent ? optionalParent : [];
      let item: NodeSetEditorData | undefined;

      item = stateValidation.targetNodeMappedDataNodeKey.get(nodeId);

      if (item) {
        parentNodeItems.unshift(item.DisplayName[0].Value);
        if (
          item.ParentNodeId &&
          item.ParentNodeId !== REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
        ) {
          getParentNodes(item.ParentNodeId, parentNodeItems);
        } else if (
          item.ParentNodeId === REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
        ) {
          parentNodeItems.unshift("Objects ");
        } else {
          parentNodeItems.unshift("Types ");
        }
      }
      return parentNodeItems;
    },
    [stateValidation.targetNodeMappedDataNodeKey]
  );

  useEffect(() => {
    if (errorMessage && errorMessage?.length) {
      const newErrors = errorMessage.map((x) => {
        let path = getParentNodes(x.nodeId);
        return {
          ...x,
          displayPath: "Root >" + path.join(">"),
        };
      });
      setState((updateState) => {
        updateState.errorNodes = newErrors;
      });
    }
  }, [errorMessage, getParentNodes, setState]);

  return (
    <>
      <div
        className={`validationContainer ${
          commonState.theme === "dark" && "validation-dark"
        }`}
      >
        <div className="validationWidth">
          <div className="validationHeader">
            <h4>Error Messages</h4>
          </div>
          <div className="validationButton">
            <Icon
              name="abb/close"
              className="editIcon"
              sizeClass="medium"
              onClick={closeNotification}
            />
          </div>
        </div>
        <div className="validationList">
          {stateValidation.errorNodes &&
            stateValidation.errorNodes.length > 0 &&
            stateValidation.errorNodes.map((index) => (
              <ul
                onClick={() => {
                  setSelectedNode(index.nodeId);
                  getNodeId(index.nodeId);
                  const getElementError =
                    stateValidation.targetNodeMappedDataNodeKey.get(
                      index.nodeId
                    )?.index;

                  const idOfElement = document.getElementById(
                    getElementError as string
                  );

                  idOfElement?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                  });
                }}
                className={`highLightError ${
                  index.nodeId === selectedNode && `selectedHighLightError`
                }`}
                style={{ listStyle: "none" }}
                key={index.nodeId}
              >
                <li>
                  <div className="errorList">
                    {index.message}

                    <Icon
                      name="abb/warningTriangle"
                      className="errorIcon-notification padding nodeTypeImage"
                      sizeClass="small"
                      color="#F03040"
                    />

                    <br />
                    <span className="displayGrey">
                      Path : &nbsp;{index.displayPath}
                    </span>
                  </div>
                </li>
              </ul>
            ))}
        </div>
        <div className="totalError-Container">
          <span>Total Errors: {errorMessage.length}</span>
        </div>
      </div>
    </>
  );
};

export default ValidationNotification;
