import * as serverTypes from "../Type/TypeServers";
import * as commonTypes from "../../../Common/Type/TypeCommon";
import _ from "underscore";
import { viewByserversGridHeaders } from "../DataFormatter/DataFormatterServers";

export interface IServersDetailsStateType {
  rawServersData: serverTypes.IRawServersObj[];
  viewByEdgesData: serverTypes.IServersGridRowObj[];
  viewByServersData: serverTypes.IServersGridRowObj[];
  viewByEdgesDataCopy: serverTypes.IServersGridRowObj[];
  viewByServersDataCopy: serverTypes.IServersGridRowObj[];
  viewBy: "server" | "edge";
  isLoading: boolean;
  isLoadingAllServers: boolean;
  selectedServer: serverTypes.IServersGridRowObj;
  selectedRawServer: serverTypes.IRawServersObj;
  showWarningDialog: boolean;
  filterText: string;
  showOptionsPopup: boolean;
  showUpload: boolean;
  showConnectToServerModal: boolean;
  nodeSetFiles: any[];
  fileNames: string[];
  serverName: string;
  serverDscription: string;
  endPoint: string;
  edgeList: serverTypes.IEachEdgeObj[];
  edgeDeropDownData: commonTypes.IEdgeDropDownData[];
  selectedEdgeInDropdown: commonTypes.IEdgeDropDownData[];
  requiresSecureConnection: boolean;
  selectedCertificate: string;
  selectedCertificateName: string;
  isValidCertificate: boolean;
  sortInfo: {
    colId: string;
    direction: "asc" | "desc" | "unsorted";
  };
  browseData: serverTypes.INodeObj[];
  browseDataCopy: serverTypes.INodeObj[];
  browseDataUnModified: serverTypes.INodeObj[];
  flattenedData: serverTypes.IFlattenedNodeDataObj;
  flattenedTreeData: serverTypes.IFlattenedNodeDataObj;
  unModifiedFlattenedData: serverTypes.IFlattenedNodeDataObj;
  unModifiedFlattenedTreeData: serverTypes.IFlattenedNodeDataObj;
  selectedNodeIds: string[];
  showBrowse: boolean;
  filters: serverTypes.IFilterDetails;
  showFilters: boolean;
  nodeTypesOptions: serverTypes.INodeTypeOptionObj[];
  modifiedCells: serverTypes.IModifyCell[];
  subscriptionStatusOptions: serverTypes.INodeTypeOptionObj[];
  isEdittingServer: boolean;
  isNodeSetUploadDialogOpen: boolean;
  selectedUploadOption: string;
  isConfigureStatePolling: boolean;
  isSessionStatePolling: boolean;
  sessionStateData: serverTypes.ISessionStateData;
  isSessionStateTimedOut: boolean;
  isLoadedAfterUpload: boolean;
  shouldRefreshAfterClosingUpdate: boolean;
  username: string;
  password: string;
  userAuthentication: boolean;
  historicalData: boolean;
}

const initialState: IServersDetailsStateType = {
  rawServersData: [],
  viewByEdgesData: [],
  viewByServersData: [],
  viewByEdgesDataCopy: [],
  viewByServersDataCopy: [],
  viewBy: "server",
  isLoading: false,
  isLoadingAllServers: false,
  selectedServer: {} as serverTypes.IServersGridRowObj,
  selectedRawServer: {} as serverTypes.IRawServersObj,
  showWarningDialog: false,
  filterText: "",
  showOptionsPopup: false,
  showUpload: false,
  showConnectToServerModal: false,
  nodeSetFiles: [],
  fileNames: [],
  serverName: "",
  endPoint: "",
  serverDscription: "",
  edgeList: [],
  edgeDeropDownData: [],
  selectedEdgeInDropdown: [],
  requiresSecureConnection: false,
  selectedCertificate: "",
  selectedCertificateName: "",
  isValidCertificate: false,
  sortInfo: {
    colId: "servername",
    direction: "desc",
  },
  browseData: [],
  browseDataCopy: [],
  browseDataUnModified: [],
  flattenedData: {} as serverTypes.IFlattenedNodeDataObj,
  flattenedTreeData: {} as serverTypes.IFlattenedNodeDataObj,
  unModifiedFlattenedData: {} as serverTypes.IFlattenedNodeDataObj,
  unModifiedFlattenedTreeData: {} as serverTypes.IFlattenedNodeDataObj,
  selectedNodeIds: [],
  showBrowse: false,
  filters: {
    displayName: "",
    dataBindingName: "",
    samplingInterval: [0, 1000],
  },
  showFilters: false,
  nodeTypesOptions: [
    {
      value: "uaObject",
      label: "uaObject",
    },
    {
      value: "uaVariable",
      label: "uaVariable",
    },
    {
      value: "xyz",
      label: "xyz",
    },
  ],
  subscriptionStatusOptions: [
    {
      value: "subscribed",
      label: "Subscribed",
    },
    {
      value: "paused",
      label: "Paused",
    },
  ],
  modifiedCells: [],
  isEdittingServer: false,
  isNodeSetUploadDialogOpen: false,
  selectedUploadOption: "automatic",
  isConfigureStatePolling: false,
  isSessionStatePolling: false,
  sessionStateData: {} as serverTypes.ISessionStateData,
  isSessionStateTimedOut: false,
  isLoadedAfterUpload: false,
  shouldRefreshAfterClosingUpdate: false,
  username: "",
  password: "",
  userAuthentication: false,
  historicalData: false,
};

export default function (
  state: IServersDetailsStateType = initialState,
  action: serverTypes.IServersActions
): IServersDetailsStateType {
  switch (action.type) {
    case "GET_SERVERS":
      return {
        ...state,
        isLoadingAllServers: true,
        shouldRefreshAfterClosingUpdate: false,
      };
    case "RECEIVED_SERVERS":
      const receivedViewByServersData = [...action.viewByServersData];
      let sortColumnIndex: number = 0;
      viewByserversGridHeaders.map((item, index) => {
        if (item.id === state.sortInfo.colId) {
          sortColumnIndex = index;
        }
      });
      receivedViewByServersData.sort((a: any, b: any) => {
        let x = `${
          a.data[sortColumnIndex].rawVal ? a.data[sortColumnIndex].rawVal : ""
        }`.toLowerCase();
        let y = `${
          b.data[sortColumnIndex].rawVal ? b.data[sortColumnIndex].rawVal : ""
        }`.toLowerCase();
        if (state.sortInfo.direction === "desc") {
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
        } else if (state.sortInfo.direction === "asc") {
          if (x > y) {
            return -1;
          }
          if (x < y) {
            return 1;
          }
        }
        return 0;
      });
      return {
        ...state,
        viewByEdgesData: action.viewByEdgesData,
        viewByEdgesDataCopy: action.viewByEdgesData,
        viewByServersData: receivedViewByServersData,
        viewByServersDataCopy: receivedViewByServersData,
        rawServersData: action.rawServersData,
        isLoadingAllServers: false,
      };
    case "GET_SERVERS_STATUS":
      return {
        ...state,
      };
    case "RECEIVED_SERVERS_STATUS":
      return {
        ...state,
        viewByEdgesData: action.viewByEdgesData,
        viewByEdgesDataCopy: action.viewByEdgesData,
        viewByServersData: action.viewByServersData,
        viewByServersDataCopy: action.viewByServersData,
        rawServersData: action.rawServersData,
        isLoadingAllServers: false,
      };
    case "GET_CURRENT_SERVER":
      return {
        ...state,
        isConfigureStatePolling: true,
        shouldRefreshAfterClosingUpdate: true,
      };
    case "RECEIVED_CURRENT_SERVER":
      const newSelectedServer =
        Object.keys(state.selectedServer).length !== 0
          ? { ...state.selectedServer }
          : {
              id: action.payload.id,
              displayName: action.payload.name,
              rawData: { ...action.payload },
            };
      return {
        ...state,
        selectedRawServer: { ...action.payload },
        isConfigureStatePolling: action.isPollingNeeded,
        selectedServer: {
          ...newSelectedServer,
        } as serverTypes.IServersGridRowObj,
        serverName: action.payload.name,
        endPoint: action.payload.endpointUrl,
        //serverDscription: action.payload.serverDescription,
        requiresSecureConnection:
          action.payload.certificate &&
          action.payload.certificate !== null &&
          action.payload.certificate !== ""
            ? true
            : false,
        isValidCertificate:
          action.payload.certificate &&
          action.payload.certificate !== null &&
          action.payload.certificate !== ""
            ? true
            : false,
        selectedCertificate:
          action.payload.certificate &&
          action.payload.certificate !== null &&
          action.payload.certificate
            ? action.payload.certificate
            : "",
        selectedCertificateName: "",
        isLoadingAllServers: false,
      };
    case "GET_SESSION_STATE_DATA":
      return {
        ...state,
        isSessionStatePolling: true,
      };
    case "RECEIVED_SESSION_STATE_DATA":
      return {
        ...state,
        sessionStateData: { ...action.payload },
        isSessionStatePolling: action.pollingStatus,
      };
    case "HANDLE_SESSION_STATE_TIMEOUT":
      return {
        ...state,
        isSessionStateTimedOut: true,
        isSessionStatePolling: false,
      };
    case "GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD":
      return {
        ...state,
        isLoadedAfterUpload: true,
        selectedRawServer: !state.isConfigureStatePolling
          ? {
              ...state.selectedRawServer,
              configureState: 5,
              configureStatusMessage: "",
            }
          : { ...state.selectedRawServer },
        isConfigureStatePolling: true,
      };
    case "RECEIVED_SERVER_IN_BACKGROUND_AFTER_UPLOAD":
      return {
        ...state,
        selectedRawServer: { ...action.payload },
        isConfigureStatePolling: action.isPollingNeeded,
      };
    case "TOGGLE_VIEW":
      return {
        ...state,
        viewBy: action.viewType,
      };
    case "ON_EXPAND_COLLAPSE":
      const updatedData = [...state.viewByEdgesData];
      updatedData.map((item) => {
        if (item.id === action.clickedRow.id) {
          item.isExpanded = !item.isExpanded;
          if (item.rowType === "edge" && item.isExpanded) {
            item.data[1].className = "hidden-cell";
          } else {
            item.data[1].className = "";
          }
        }
      });
      if (action.clickedRow.isExpanded) {
        updatedData.map((item) => {
          if (
            item["id"].indexOf(action.clickedRow.id) >= 0 &&
            item.level > action.clickedRow.level
          ) {
            item.isExpanded = false;
            item.isVisible = false;
          }
        });
      } else {
        updatedData.map((item) => {
          if (
            item.id.indexOf(action.clickedRow.id) >= 0 &&
            item.level === action.clickedRow.level + 1
          ) {
            item.isExpanded = false;
            item.isVisible = true;
          }
        });
      }
      return {
        ...state,
        viewByEdgesData: [...updatedData],
      };
    case "SELECT_SERVER_TO_DELETE":
      return {
        ...state,
        selectedServer: { ...action.rowObj },
        selectedRawServer: { ...action.rowObj.rawData },
        showWarningDialog: true,
      };
    case "HIDE_WARNING_DIALOG":
      return {
        ...state,
        showWarningDialog: false,
        selectedServer: {} as serverTypes.IServersGridRowObj,
        selectedRawServer: {} as serverTypes.IRawServersObj,
      };
    case "HANDLE_FILTER_INPUT_TEXT":
      return {
        ...state,
        filterText: action.text,
      };
    case "PERFORM_FILTER":
      const viewByEdgeDataToBeFiltered = [...state.viewByEdgesDataCopy];
      const viewByServersDataToBeFiltered = [...state.viewByServersDataCopy];
      const filteredData: serverTypes.IServersGridRowObj[] = [];
      const filteredViewByServersData: serverTypes.IServersGridRowObj[] = [];
      const filteredIds: string[] = [];
      if (state.filterText !== "") {
        viewByEdgeDataToBeFiltered.map((item) => {
          let isMatch = false;
          item.data.map((eachObj) => {
            if (
              item.displayName
                .toLowerCase()
                .indexOf(state.filterText.toLowerCase()) >= 0
            ) {
              isMatch = true;
            }
            if (item.rowType === "server" && eachObj.id === "serverstatus") {
              if (eachObj.rawVal) {
                (eachObj.rawVal as string[]).map((eachStatus) => {
                  if (
                    eachStatus
                      .toLowerCase()
                      .indexOf(state.filterText.toLowerCase()) >= 0
                  ) {
                    isMatch = true;
                  }
                });
              }
            }
          });
          if (isMatch) {
            filteredIds.push(item.id);
          }
        });
        viewByEdgeDataToBeFiltered.map((item) => {
          let objToBeKept = false;
          let itemCopy = { ...item };
          filteredIds.map((filteredId) => {
            if (
              filteredId.indexOf(item.id) >= 0 ||
              item.id.indexOf(filteredId) >= 0
            ) {
              objToBeKept = true;
              if (
                !(item.id.indexOf(filteredId) >= 0 && filteredId !== item.id)
              ) {
                itemCopy.isVisible = true;
              }
              if (
                (filteredId.indexOf(item.id) >= 0 ||
                  item.id.indexOf(filteredId) >= 0) &&
                filteredId !== item.id &&
                item.isExpandable
              ) {
                itemCopy.isExpanded = true;
              }
            }
          });
          if (objToBeKept) {
            filteredData.push({ ...itemCopy });
          }
        });
        viewByServersDataToBeFiltered.map((serverItem, index) => {
          if (
            serverItem.rawData.name
              .toLowerCase()
              .indexOf(state.filterText.toLowerCase()) >= 0 ||
            (serverItem.rawData.serverDescription &&
              serverItem.rawData.serverDescription
                .toLowerCase()
                .indexOf(state.filterText.toLowerCase()) >= 0) ||
            serverItem.rawData.edgeName
              .toLowerCase()
              .indexOf(state.filterText.toLowerCase()) >= 0 ||
            serverItem.rawData.configureStatusMessage
              .toLowerCase()
              .indexOf(state.filterText.toLowerCase()) >= 0
          ) {
            filteredViewByServersData.push({ ...serverItem });
          }
        });
      } else {
        filteredData.push(...viewByEdgeDataToBeFiltered);
        filteredViewByServersData.push(...viewByServersDataToBeFiltered);
      }
      let sortColIndex: number = 0;
      viewByserversGridHeaders.map((item, index) => {
        if (item.id === state.sortInfo.colId) {
          sortColIndex = index;
        }
      });
      filteredViewByServersData.sort((a: any, b: any) => {
        let x = `${
          a.data[sortColIndex].rawVal ? a.data[sortColIndex].rawVal : ""
        }`.toLowerCase();
        let y = `${
          b.data[sortColIndex].rawVal ? b.data[sortColIndex].rawVal : ""
        }`.toLowerCase();
        if (state.sortInfo.direction === "desc") {
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
        } else if (state.sortInfo.direction === "asc") {
          if (x > y) {
            return -1;
          }
          if (x < y) {
            return 1;
          }
        }
        return 0;
      });
      return {
        ...state,
        viewByEdgesData: [...filteredData],
        viewByServersData: [...filteredViewByServersData],
      };
    case "SHOW_MORE_OPTIONS":
      return {
        ...state,
        showOptionsPopup: true,
        selectedServer: { ...action.rowObj } as serverTypes.IServersGridRowObj,
        selectedRawServer: { ...action.rowObj.rawData },
      };
    case "HIDE_MORE_OPTIONS":
      return {
        ...state,
        showOptionsPopup: false,
        selectedServer: {} as serverTypes.IServersGridRowObj,
        selectedRawServer: {} as serverTypes.IRawServersObj,
      };
    case "SHOW_CONNECT_TO_SERVER_MODAL":
      const selectedEdgeForDropDown =
        action.method === "update"
          ? state.edgeDeropDownData.filter(
              (item) => item.value === action.rowObj.rawData.edgeId
            )
          : [];
      return {
        ...state,
        showConnectToServerModal: true,
        selectedServer: { ...action.rowObj },
        selectedRawServer: { ...action.rowObj.rawData },
        isEdittingServer: action.method === "update" ? true : false,
        serverName:
          action.method === "update" ? action.rowObj.rawData.name : "",
        endPoint:
          action.method === "update"
            ? action.rowObj.rawData.endpointUrl
            : "opc.tcp://",
        serverDscription:
          action.method === "update"
            ? action.rowObj.rawData.serverDescription
            : "",
        requiresSecureConnection:
          action.method === "update" &&
          action.rowObj.rawData.certificate &&
          action.rowObj.rawData.certificate !== null &&
          action.rowObj.rawData.certificate !== ""
            ? true
            : false,
        isValidCertificate:
          action.method === "update" &&
          action.rowObj.rawData.certificate &&
          action.rowObj.rawData.certificate !== null &&
          action.rowObj.rawData.certificate !== ""
            ? true
            : false,
        selectedEdgeInDropdown: [...selectedEdgeForDropDown],
        selectedCertificate:
          action.method === "update" &&
          action.rowObj.rawData.certificate &&
          action.rowObj.rawData.certificate !== null &&
          action.rowObj.rawData.certificate
            ? action.rowObj.rawData.certificate
            : "",
        selectedCertificateName: "",
        shouldRefreshAfterClosingUpdate:
          action.method === "update" ? true : false,
        // selectedServer:{
        //   ...state.selectedServer,
        //   rawData: {
        //     ...state.selectedServer.rawData,
        //     userAuthenticaion: action.rowObj.userAuthentication,
        //     userName: action.rowObj.username
        //   }
        // }
      };
    case "HIDE_CONNECT_TO_SERVER_MODAL":
      return {
        ...state,
        showConnectToServerModal: false,
        selectedServer: {} as serverTypes.IServersGridRowObj,
        selectedRawServer: {} as serverTypes.IRawServersObj,
        selectedEdgeInDropdown: [],
        requiresSecureConnection: false,
        selectedCertificate: "",
        selectedCertificateName: "",
        isValidCertificate: false,
        isConfigureStatePolling: false,
        isSessionStatePolling: false,
        sessionStateData: {} as serverTypes.ISessionStateData,
        isSessionStateTimedOut: false,
        isLoadedAfterUpload: false,
      };
    case "GET_EDGE_LIST":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVED_EDGE_LIST":
      return {
        ...state,
        edgeList: [...action.payload],
        edgeDeropDownData: [...action.edgeDropDownData],
        isLoading: false,
      };
    case "PICK_NODESET_FILE":
      return {
        ...state,
        fileNames: [...action.fileNames],
        nodeSetFiles: [...action.nodeSetFiles],
      };
    case "REMOVE_NODESET_FILE":
      const currentFiles: any[] = [...state.nodeSetFiles];
      const currentFileNames = [...state.fileNames];
      currentFiles.splice(action.index, 1);
      currentFileNames.splice(action.index, 1);

      return {
        ...state,
        nodeSetFiles: [...currentFiles],
        fileNames: [...currentFileNames],
      };
    case "CLEAR_ALL_NODESET_FILES":
      return {
        ...state,
        nodeSetFiles: [],
        fileNames: [],
      };
    case "SHOW_NODESET_UPLOAD_DIALOG":
      return {
        ...state,
        isNodeSetUploadDialogOpen: true,
      };
    case "HIDE_NODESET_UPLOAD_DIALOG":
      return {
        ...state,
        isNodeSetUploadDialogOpen: false,
        nodeSetFiles: [],
        fileNames: [],
        selectedUploadOption: "automatic",
      };
    case "SET_NODESET_UPLOAD_OPTION":
      return {
        ...state,
        selectedUploadOption: action.value,
      };
    case "HANDLE_SERVER_NAME":
      return {
        ...state,
        serverName: action.serverName,
      };
    case "HANDLE_OPC_UA_ENDPOINT":
      return {
        ...state,
        endPoint: action.endPoint,
      };
    case "HANDLE_SERVER_DESCRIPTION":
      return {
        ...state,
        serverDscription: action.serverDescription,
      };
    case "HANDLE_EDGE_DROPDOWN":
      return {
        ...state,
        selectedEdgeInDropdown: [...action.selectedEdge],
      };
    case "HANDLE_REQUIRE_SECURE_CONNECTION": {
      let isRequired = !state.requiresSecureConnection;
      if (action.isEnabled !== undefined) {
        isRequired = action.isEnabled;
      }
      return {
        ...state,
        requiresSecureConnection: isRequired,
      };
    }
    case "HANDLE_CERTIFICATE_SELECT":
      return {
        ...state,
        selectedCertificate: action.certificate,
        selectedCertificateName: action.certificateName,
      };
    case "HANDLE_REPLACE_CERTIFICATE":
      return {
        ...state,
        selectedCertificate: "",
        selectedCertificateName: "",
      };
    case "SET_FILE_VALIDITY":
      return {
        ...state,
        isValidCertificate: action.status,
      };
    case "HANDLE_SORT":
      let newDirection: "desc" | "asc" | "unsorted" =
        action.sortDirection === "asc" ? "desc" : "asc";
      if (action.colId !== state.sortInfo.colId) {
        newDirection = "desc";
      }
      let colIdIndex = 0;
      const sortedData: serverTypes.IServersGridRowObj[] = [];
      const sortedColArray: any[] = [];
      viewByserversGridHeaders.map((item, index) => {
        if (item.id === action.colId) {
          colIdIndex = index;
        }
      });
      const currData = [...state.viewByServersData];
      currData.sort((a: any, b: any) => {
        let x = `${
          a.data[colIdIndex].rawVal ? a.data[colIdIndex].rawVal : ""
        }`.toLowerCase();
        let y = `${
          b.data[colIdIndex].rawVal ? b.data[colIdIndex].rawVal : ""
        }`.toLowerCase();
        if (newDirection === "desc") {
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
        } else if (newDirection === "asc") {
          if (x > y) {
            return -1;
          }
          if (x < y) {
            return 1;
          }
        }
        return 0;
      });
      return {
        ...state,
        sortInfo: {
          colId: action.colId,
          direction: newDirection,
        },
        viewByServersData: [...currData],
      };
    case "ON_SUCCESS_SERVERS":
      return {
        ...state,
        isLoading: false,
      };
    case "SUBMIT_ADD_SERVER":
      return {
        ...state,
        isLoading: true,
      };
    case "SUBMIT_UPDATE_SERVER":
      return {
        ...state,
        isLoading: true,
        isConfigureStatePolling: false,
        isSessionStatePolling: false,
        sessionStateData: {} as serverTypes.ISessionStateData,
        isSessionStateTimedOut: false,
        isLoadedAfterUpload: false,
      };
    case "SUBMIT_DELETE_SERVER":
      return {
        ...state,
        isLoading: true,
      };
    case "SUBMIT_NODE_SET_AUTO_CONFIG":
      return {
        ...state,
        isLoading: true,
        isConfigureStatePolling: false,
        isSessionStatePolling: false,
        sessionStateData: {} as serverTypes.ISessionStateData,
        isSessionStateTimedOut: false,
        isLoadedAfterUpload: false,
      };
    case "SUBMIT_NODE_SET_AFTER_UPLOAD":
      return {
        ...state,
        isLoading: true,
        isConfigureStatePolling: false,
        isSessionStatePolling: false,
        sessionStateData: {} as serverTypes.ISessionStateData,
        isSessionStateTimedOut: false,
        isLoadedAfterUpload: false,
      };
    case "ON_SUCCESS_SERVERS":
      return {
        ...state,
        isLoading: false,
      };
    case "ON_FAIL_SERVERS":
      return {
        ...state,
        isLoading: false,
        isLoadingAllServers: false,
      };
    default:
      return state;
  }
}
