import React, { useCallback, useEffect, useRef } from "react";
import { useImmer } from "use-immer";
import produce from "immer";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Input,
  AppMainContent,
  Button,
  Spinner,
  MessageDialog,
} from "@abb/abb-common-ux-react";

import "./NodeLinkPage.css";
import {
  CreateComponentModelStates,
  CreateUpdateNodeRequest,
} from "../../../../Models/CreateCompModels/CreateCompModel";
import { SaveTargetNodeData } from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import { variablesMappingTool } from "../../../../Utils/ConstantsMappingTool";
import {
  clearIdFromExportComponent,
  updateReducerPayloadNodeSetData,
} from "../../Action/homePageStore";
import { homePageValuesSelector } from "../../Action/homePageStore/homePageReducers";
import {
  clearNodeSetEditorByIdDataList,
  clearErrorValidation,
  clearImportTargetCSVDataList,
  fetchNodeSetDataByIdAction,
  saveTargetNodeSetDataAction,
} from "../../Action/nodeTreeStore";
import { nodeSetEditorValuesSelector } from "../../Action/nodeTreeStore/nodeTreeReducers";
import { NewComplexTree } from "../TreeComponent/TreeComponentSourceNode";
import { hideMoreOptions } from "../../../Servers/Action/ActionServers";
import { NodeLinkStates } from "./NodeLinkStates";
import { IState } from "../../../../Reducer";

const NodeLinkPage: React.FC = (): JSX.Element => {
  const [state, setState] =
    useImmer<CreateComponentModelStates>(NodeLinkStates);

  const history = useHistory();
  const dispatch = useDispatch();
  const { edGenieDataId } = useSelector(homePageValuesSelector);
  const { nodeEditorByIdData, isLoading } = useSelector(
    nodeSetEditorValuesSelector
  );
  const commonState = useSelector((state: IState) => state.commonState);

  const {
    state: { id },
  } = useLocation<any>();

  const mounted = useRef<boolean>();

  useEffect(() => {
    const unblock = history.block(({ pathname }: any) => {
      if (mounted.current || !state.changeTargetFlag || state.changeDataModal) {
        // we can now unblock
        unblock();
        // proceed with the blocked navigation
        history.push(pathname);
      } else if (state.changeTargetFlag && !state.changeDataModal) {
        setState((prevState) => {
          prevState.navigationBlockModalPath = pathname;
          prevState.navigationBlockModal = true;
        });
      }
      // prevent navigation
      return false;
    });
    // just in case theres an unmount we can unblock if it exists
    return unblock;
  }, [history, setState, state.changeDataModal, state.changeTargetFlag]);

  useEffect(() => {
    return () => {
      dispatch(clearNodeSetEditorByIdDataList());
      dispatch(clearIdFromExportComponent());
      dispatch(hideMoreOptions());
    };
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchNodeSetDataByIdAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && nodeEditorByIdData) {
      setState((draft) => {
        draft.edUuid = nodeEditorByIdData.id;
        draft.edName = nodeEditorByIdData.name;
        draft.edUri = nodeEditorByIdData.uri;
        draft.edVersion = nodeEditorByIdData.version;
        draft.oldCreateNodeSetData = {
          id: nodeEditorByIdData.id,
          name: nodeEditorByIdData.name,
          uri: nodeEditorByIdData.uri,
          version: nodeEditorByIdData.version,
        };
      });
    }
  }, [id, nodeEditorByIdData, setState]);

  const onChangeValue = (value: string, field: string) => {
    setState((draft) => {
      const validationKeyObj =
        value.length > 0 ? { [`${field}ErrMsg`]: undefined } : {};
      const updateValue = { ...draft, [field]: value };
      return {
        ...updateValue,
        validation: { ...draft.validation, ...validationKeyObj },
      };
    });
  };

  const saveUpdateValidation = (stateData: CreateComponentModelStates) => {
    let tempFlag = true;
    if (!stateData.edName || stateData.edName.trim().length === 0) {
      tempFlag = false;
      stateData.validation.edNameErrMsg = "* Name cannot be empty";
    }

    if (!stateData.edUri || stateData.edUri.trim().length === 0) {
      tempFlag = false;
      stateData.validation.edUriErrMsg = "* URI cannot be empty";
    }
    if (!stateData.edVersion || stateData.edVersion.trim().length === 0) {
      tempFlag = false;
      stateData.validation.edVersionErrMsg = "* Version cannot be empty";
    }

    return tempFlag;
  };

  const saveDataToReducerNodeSetData = useCallback(() => {
    const nextState = produce(state, (draft: CreateComponentModelStates) => {
      const data: CreateUpdateNodeRequest = {
        id: "",
        name: state.edName,
        uri: state.edUri,
        version: state.edVersion,
      };

      draft.payloadToSaveNodeSetData = data;
    });

    dispatch(
      updateReducerPayloadNodeSetData(
        nextState.payloadToSaveNodeSetData as CreateUpdateNodeRequest
      )
    );
  }, [dispatch, state]);

  // Update Reducer when state is changed
  useEffect(() => {
    if (
      !id &&
      state.edName &&
      state.edName.length > 0 &&
      state.edUri &&
      state.edUri.length > 0 &&
      state.edVersion &&
      state.edVersion.length > 0
    ) {
      saveDataToReducerNodeSetData();
    }
  }, [
    id,
    saveDataToReducerNodeSetData,
    state.edName,
    state.edUri,
    state.edVersion,
  ]);

  const saveFunction = (saveOrSaveProcess: number) => {
    let updatedArray: SaveTargetNodeData[] = [];
    if (
      (id || edGenieDataId) &&
      state.sourceNodeMappedData &&
      state.sourceNodeMappedData.size > 0
    ) {
      if (state.targetNodeMappedData && state.targetNodeMappedData.size > 0) {
        const arrayedData = Array.from(state.targetNodeMappedData.values());
        updatedArray = arrayedData.map(
          ({
            data,
            index,
            children,
            isFolder,
            hasChildren,
            isTypeObjectChild,
            sourceIndex,
            parentIndex,
            ...restData
          }) => restData
        );
      }
      dispatch(
        saveTargetNodeSetDataAction(
          id || edGenieDataId,
          updatedArray,
          saveOrSaveProcess,
          state.edName,
          state.edUri,
          saveOrSaveProcess === variablesMappingTool.SAVE_AND_SUBMIT
            ? "major"
            : "minor",
          () => {
            setState((draft) => {
              draft.changeTargetFlag = false;
            });
            dispatch(clearErrorValidation());
            dispatch(clearImportTargetCSVDataList());

            if (saveOrSaveProcess === variablesMappingTool.SAVE_AND_SUBMIT) {
              history.push("/");
            } else {
              dispatch(fetchNodeSetDataByIdAction(id || edGenieDataId));
            }
          }
        )
      );
    }
  };

  const cancelOption = () => {
    setState((draft) => {
      draft.edName = state.oldCreateNodeSetData?.name
        ? state.oldCreateNodeSetData.name
        : "";
      draft.edUri = state.oldCreateNodeSetData?.uri
        ? state.oldCreateNodeSetData.uri
        : "";
      draft.edVersion = state.oldCreateNodeSetData?.version
        ? state.oldCreateNodeSetData?.version
        : "1.0";
    });
  };

  const closeModalNavigation = () => {
    if (state.changeTargetFlag && !state.changeDataModal) {
      setState((prevState) => {
        prevState.navigationBlockModalPath = null;
        prevState.navigationBlockModal = false;
      });
    } else {
      setState((prevState) => {
        prevState.changeDataModal = false;
      });
    }
  };

  const goBackToHome = () => {
    history.push("/");
  };
  return (
    <>
      <AppMainContent>
        <div
          className={
            commonState.theme === "light" ? "content-area" : "content-area-dark"
          }
        >
          {isLoading && (
            <div className="SpinnerBackground Spinner">
              <Spinner sizeClass="medium" style={{ margin: "auto" }} />
            </div>
          )}
          <div className="NodePageButtonsAndText">
            <div style={{ flex: "1 1 auto" }}>
              <b>{variablesMappingTool.CreateNodeset}</b>
            </div>
            <div className="NodePageButtons">
              <Button
                type="normal"
                text={variablesMappingTool.Cancel}
                sizeClass="medium"
                onClick={() => {
                  setState((draft) => {
                    draft.changeDataModal = true;
                  });
                }}
              />

              <Button
                type="primary-blue"
                text="Save as Draft"
                className="buttonMargin"
                sizeClass="medium"
                onClick={() => saveFunction(variablesMappingTool.SAVE_ONLY)}
              />

              <Button
                type="primary-blue"
                text="Save and Submit"
                className="buttonMargin"
                sizeClass="medium"
                onClick={() =>
                  saveFunction(variablesMappingTool.SAVE_AND_SUBMIT)
                }
              />
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ flex: "1 1 auto" }}>
              <Input
                dataType="text"
                label="Name"
                placeholder="Name"
                value={state.edName}
                onValueChange={(val) => {
                  if (val.length < 100) {
                    const regexp = /^\S*$/;

                    if (val.match(regexp)) onChangeValue(val, "edName");
                  }
                }}
                className="channelName"
                required
                style={{
                  flex: "1",
                }}
                validationResult={state.validation.edNameErrMsg}
              />

              <span className="validationInput">
                {""}
                {state.validation.edNameErrMsg}
              </span>
            </div>
            <div style={{ marginLeft: "10px", flex: "1 1 auto" }}>
              <Input
                dataType="text"
                label="URI"
                placeholder="URI"
                required
                value={state.edUri}
                onValueChange={(val) => {
                  if (val.length < 250) {
                    const regexp = /^\S*$/;

                    if (val.match(regexp)) onChangeValue(val, "edUri");
                  }
                }}
                className="channelName"
                style={{
                  flex: "1",
                }}
                validationResult={state.validation.edUriErrMsg}
              />
              <span className="validationInput">
                {""}
                {state.validation.edUriErrMsg}
              </span>
            </div>
            <div style={{ marginLeft: "10px", flex: "1 1 auto" }}>
              <Input
                dataType="text"
                label="Version"
                placeholder="Version"
                value={state.edVersion}
                disabled={true}
                onValueChange={(val) => onChangeValue(val, "edVersion")}
                className="channelName"
                style={{
                  flex: "1",
                }}
              />
            </div>
          </div>
          <NewComplexTree
            setState={setState}
            state={state}
            saveUpdateValidation={saveUpdateValidation}
          />
        </div>
      </AppMainContent>

      <div className="popup">
        <div>
          <MessageDialog
            isOpen={state.navigationBlockModal || state.changeDataModal}
            buttons="okcancel"
            message={
              state.changeTargetFlag
                ? "Are you sure you want to leave this page? As a result, unsaved changes will be discarded."
                : "Are you sure you want to leave this page?"
            }
            title="Confirm"
            onExit={closeModalNavigation}
            onCancel={closeModalNavigation}
            onOk={() => {
              if (state.navigationBlockModalPath) {
                mounted.current = true;

                history.push(state.navigationBlockModalPath);
                setState((prevState) => {
                  prevState.navigationBlockModal = false;
                });
              } else {
                cancelOption();
                goBackToHome();
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(NodeLinkPage);
