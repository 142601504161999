import { variablesMappingTool } from "../../Utils/ConstantsMappingTool";

const Navigation = {
  EDGENIUS_URL: {
    login: "/login",
    main: {
      label: variablesMappingTool.mainPage,
      url: "/",
    },
    homepage: {
      label: variablesMappingTool.productName,
      url: "/home",
      createNode: {
        label: variablesMappingTool.createNameSpacePage,
        url: "/nodeset/new",
      },
      editNode: {
        label: variablesMappingTool.editNodeset,
        url: "/nodeset",
        path: "/nodeset",
      },
      createNodeCollapsible: {
        label: "createNodeCollapsible",
        url: "/createNameSpace/createNodeCollapsible",
      },
    },
  },
};
export { Navigation };
