import { put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as commonTypes from "../Type/TypeCommon";
import { envSettings } from "../../EnviornmentSettings/Settings";
import * as apiEndPoints from "../../Utils/ApiEndPoints";
import { getErrorMessage, setCommonHeaders } from "../../Utils/HelperFunctions";

function* fetchPreference(action: commonTypes.ICommonActions): any {
  setCommonHeaders();
  try {
    const fetchedData = yield axios.post<any>(apiEndPoints.getPreference, {});
    const receivedData = !envSettings.isOfflineMode
      ? { ...fetchedData.data }
      : { ...fetchedData.data.data };
    // const formattdServersData: any = formatServersData([...receivedData]);
    yield put({
      type: "RECEIVED_PREFERENCE",
      preferenceData: { ...receivedData },
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "RECEIVED_PREFERENCE",
        preferenceData: {},
      });
    }
  }
}
function sleep(milliseconds: number) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}
function* setPreference(action: commonTypes.IChangePreference): any {
  setCommonHeaders();
  try {
    const fetchedData = yield axios.post<any>(
      `${apiEndPoints.updatePreference}`,
      {},
      {
        params: {
          theme: action.selectedTheme,
        },
      }
    );
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
  }
}

function* getPublicKey(_action: commonTypes.IGetPublicKey): any {
  setCommonHeaders();
  try {
    const fetchedData = yield axios.request<any>({
      method: envSettings.isOfflineMode ? 'get' : 'post',
      url: `${apiEndPoints.getPublicKey}`
    }
    );
    const receivedData = envSettings.isOfflineMode ? fetchedData.data : fetchedData.data.data;
    console.log("PUBKEY -", receivedData);
  } catch (e) {
    console.log("error", e);
  }
}

export default function* actionWatcherServers() {
  yield takeEvery("GET_PREFERENCE", fetchPreference);
  yield takeEvery("GET_PUBLIC_KEY", getPublicKey);
  yield takeEvery("CHANGE_PREFERENCE", setPreference);
}
