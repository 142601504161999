import { SaveTargetNodeData } from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import {
  FetchNodeSetDataActionType,
  FetchNodeSetDataByIdActionType,
  ImportCsvDataActionType,
  SaveTargetNodeSetDataType,
} from "./nodeTreeActionTypes";
import {
  FETCH_NODE_BY_ID_SET_DATA,
  FETCH_NODE_SET_DATA,
  IMPORT_TARGET_CSV_DATA,
  SAVE_TARGET_NODE_SET_DATA,
} from "./nodeTreeTypesConstants";

export const importXmlDataAction = (
  id: string,
  document: FormData,
  append: boolean
): FetchNodeSetDataActionType => {
  return {
    type: FETCH_NODE_SET_DATA,
    payload: { id, document, append },
  };
};

export const fetchNodeSetDataByIdAction = (
  id: string
): FetchNodeSetDataByIdActionType => {
  return {
    type: FETCH_NODE_BY_ID_SET_DATA,
    payload: id,
  };
};

export const saveTargetNodeSetDataAction = (
  id: string,
  data: SaveTargetNodeData[],
  saveType: number,
  name: string,
  uri: string,
  version: string,
  callBack: () => void
): SaveTargetNodeSetDataType => {
  return {
    type: SAVE_TARGET_NODE_SET_DATA,
    payload: { id, data, saveType, name, uri, version },
    callBack,
  };
};

export const importCsvDataAction = (
  id: string,
  document: FormData
): ImportCsvDataActionType => {
  return {
    type: IMPORT_TARGET_CSV_DATA,
    payload: { id, document },
  };
};
