import { CreateComponentModelStates } from "../../../../Models/CreateCompModels/CreateCompModel";

export const NodeLinkStates: CreateComponentModelStates = {
  edName: "",
  edUri: "",
  edVersion: "1.0",
  edUuid: "",
  addSuccessId: "",
  editFlag: false,
  addSuccess: false,
  validation: {
    edNameErrMsg: null,
    edUriErrMsg: null,
    edVersionErrMsg: null,
  },
  notification: [],
  toastMessage: [],
  payloadToSaveNodeSetData: null,
  oldCreateNodeSetData: null,
  nodeError: "",
  // for nodeset Editor
  treeItems: {},
  sourceNodeItems: [],
  targetNodeItems: [],
  targetNodeItemsBackup: new Map(),
  objectInstanceDisabled: true,
  objectTypeDisabled: true,
  findObjectOrInstanceInTarget: null,
  targetNodeMappedData: new Map(),
  targetNodeMappedDataNodeKey: new Map(),
  sourceNodeMappedDataOriginal: new Map(),
  sourceNodeMappedData: new Map(),
  sourceNodeMappedDataNodeKey: new Map(),
  targetNodeItemsBackupNodeKey: new Set(),
  colorBackGround: "",
  errorNodes: [],
  uniqueKeys: [],
  uniqueKeysReverse: [],
  renamedSelectedIndex: -1,
  renamedItemObject: null,
  searchedItemFlag: false,
  sourceTypesIndexes: new Set(),
  sourceObjectIndexes: new Set(),
  associatedInstanceFound: null,
  // searched element array
  targetNodeItemsSearched: [],
  selectedItemsInTarget: null,
  findSelectedItemSource: null,
  searchTargetFlag: false,
  searchSourceFlag: false,

  // ExportImportCompStates below
  isEdit: false,
  xmlFileSourceInput: "",
  append: false,
  fileSelected: "",
  importCheck: false,
  openAssociatedTypeModal: false,
  // navigation state
  changeTargetFlag: false,
  navigationBlockModal: false,
  navigationBlockModalPath: "",
  changeDataModal: false,
  openAssociatedTypeModalConfirm: "",
  openAssociatedTypeModalMessage: "",
  associatedTypeAssociationRender: null,
};
