import React from "react";
import Servers from "./Servers/Servers";
import UnAuthorizedView from "../Common/Components/UnAuthorizedView/UnAuthorizedView";
import Documentation from "../Common/Components/Documentation/Documentation";
import { PrivateRoute } from "./PrivateRoutes";
import Browse from "./Browse/Browse";
import { useSelector } from "react-redux";
import { IState } from "../Reducer";
import { Switch, Route, withRouter } from "react-router-dom";
import { Navigation } from "../Common/Navigation/Navigation";
import NodeLinkPage from "./Browse/Components/CreatePage/NodeLinkPage";

const AppRoutes = () => {
  const commonState = useSelector((state: IState) => state.commonState);
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/"
        component={!commonState.showUnAuthorized ? Servers : UnAuthorizedView}
      />
      <PrivateRoute
        path="/browse"
        component={!commonState.showUnAuthorized ? Browse : UnAuthorizedView}
      />
      <Route
        path="/help"
        component={
          !commonState.showUnAuthorized ? Documentation : UnAuthorizedView
        }
      />

      <PrivateRoute
        exact
        path={Navigation.EDGENIUS_URL.homepage.editNode.url}
        component={NodeLinkPage}
      />
    </Switch>
  );
};
export default withRouter(AppRoutes);
