import React, { useEffect } from "react";
import "./Documentation.css";
import Tree, { ITreeDataObj } from "../Tree/Tree";
import { Icon, AppMainContent } from "@abb/abb-common-ux-react";
import { helpPageTreeData } from "../../../Utils/Constants";
import { RouteComponentProps, withRouter } from "react-router";
import * as commonActions from "../../Action/ActionCommon";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import CommonLoader from "../CommonLoader/CommonLoader";

const refs: any = {};
helpPageTreeData.map((item: any) => {
  refs[item.id] = React.createRef();
});

const Documentation = (props: RouteComponentProps) => {
  const [tocData, handleExpandCollapse] = React.useState([...helpPageTreeData]);
  const [selectedNodeId, setSelectedNodeId] = React.useState(
    helpPageTreeData[0].id
  );
  const [nodeClickCount, setNodeClickCount] = React.useState(0);
  const [showLeftPane, toggleLeftPane] = React.useState(true);
  React.useEffect(() => {
    const elmnt = document.getElementById(selectedNodeId);
    if (elmnt && elmnt !== null) {
      elmnt?.scrollIntoView();
    }
  }, [nodeClickCount]);
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.getPreference());
  }, []);
  const clickedNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    setSelectedNodeId(clickedObj.id);
    setNodeClickCount(nodeClickCount + 1);
  };
  const handleExpandCollapseNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    if (clickedObj.isExpandable) {
      const updatedData = [...tocData];
      updatedData.map((item) => {
        if (item.id === clickedObj.id) {
          item.isExpanded = !item.isExpanded;
        }
      });
      if (clickedObj.isExpanded) {
        updatedData.map((item) => {
          if (
            item["id"].indexOf(clickedObj.id) >= 0 &&
            item.level > clickedObj.level
          ) {
            item.isExpanded = false;
            item.isVisible = false;
          }
        });
      } else {
        updatedData.map((item) => {
          if (
            item.id.indexOf(clickedObj.id) >= 0 &&
            item.level === clickedObj.level + 1
          ) {
            item.isExpanded = false;
            item.isVisible = true;
          }
        });
      }
      handleExpandCollapse([...updatedData]);
    }
  };

  const handleDocClick = (clickedId: string) => {
    let modifiedId = clickedId;
    const updatedData = [...tocData];
    updatedData.reverse();
    updatedData.some((item) => {
      if (clickedId.indexOf(item.id) >= 0 && item.isVisible) {
        modifiedId = item.id;
        return true;
      }
      return false;
    });
    setSelectedNodeId(modifiedId);
  };

  const tooglePane = () => {
    toggleLeftPane(!showLeftPane);
  };
  return (
    <React.Fragment>
      {commonState.isLoading ? <CommonLoader /> : <div />}
      {!commonState.isPreferenceLoaded ? (
        <div className="theme-loader-mask" />
      ) : (
        <div />
      )}
      <AppMainContent>
        <div className="docs">
          <div className="docs-view">
            <div className="menu" onClick={tooglePane}>
              <Icon name="abb/menu" sizeClass="large" />
            </div>
            {showLeftPane ? (
              <Tree
                data={tocData}
                selectedNodeId={selectedNodeId}
                handleExpandCollapse={handleExpandCollapseNode}
                handleNodeSelection={clickedNode}
              />
            ) : (
              <React.Fragment />
            )}
          </div>
          <DisplayNewDoc onDocClick={handleDocClick} />
        </div>
      </AppMainContent>
    </React.Fragment>
  );
};

const DisplayNewDoc = (props: any) => {
  return (
    <div className="doc-view-container" id="scrolling-div">
      <div style={{ width: "100%" }}>
        <div
          id="%1.introduction%"
          className="level-1-padding"
          ref={refs["%1.introduction%"]}
          onMouseOver={() => props.onDocClick("%1.introduction%")}
        >
          <p className="headers-document first-header">1. Introduction</p>
          Edgenius consists of two modules - OPC UA connect and OPC UA config
          utility and both these components must be installed to establish an
          connection with the OPC UA Server. The OPC UA Configuration Utility
          enables the user to configure the OPC UA Server connection via the
          Edgenius Edge node, browse the OPC UA Server and add variables to the
          edge data stream which can then be subscribed by other applications
          like ABB Ability™ Edgenius Dashboard. The OPC UA connect module is
          responsible to provide actual connectivity as per configuration done.
        </div>

        <div
          id="%1.introduction%%1.1rbac%"
          className="level-2-padding"
          ref={refs["%1.introduction%%1.1rbac%"]}
          onMouseOver={() => props.onDocClick("%1.introduction%%1.1rbac%")}
        >
          <p className="headers-document first-header">1.1 RBAC</p>
          <p>
            Role Based Access Control (RBAC) is used to authorize functionality.
            For access to OPC UA Configuration Utility, “ApplicationEngineer”
            grant is required. Grants are provided by the edge administrators.
          </p>
        </div>
        <div
          id="%1.introduction%%1.2opcuaconnect%"
          className="level-2-padding"
          ref={refs["%1.introduction%%1.2opcuaconnect%"]}
          onMouseOver={() =>
            props.onDocClick("%1.introduction%%1.2opcuaconnect%")
          }
        >
          <p className="headers-document">1.2 OPC UA Connect</p>
          <p>
            The OPC UA Connect application is an OPC UA client running on
            Edgenius. It provides southbound connectivity to an OPC UA
            compatible device or system.
          </p>
        </div>
        <div
          id="%1.introduction%%1.3installation%"
          className="level-2-padding"
          ref={refs["%1.introduction%%1.3installation%"]}
          onMouseOver={() =>
            props.onDocClick("%1.introduction%%1.3installation%")
          }
        >
          <p className="headers-document">1.3 Installation</p>
          <p>
            OPC UA Connect is available as an Edgenius application. For
            information about installation, update or uninstall of Edgenius
            application please refer : Installing Edgenius Application in the
            Disconnected Edgenius Node Installation Guide (7PAA005993*).
          </p>
        </div>

        <div
          id="%2.opcuaconfigurationtool%"
          ref={refs["%2.opcuaconfigurationtool%"]}
          className="level-1-padding"
          onMouseOver={() => props.onDocClick("%2.opcuaconfigurationtool%")}
        >
          <p className="headers-document">2. OPC UA Configuration Utility</p>
          <p>
            OPC UA Configuration Utility is a web application hosted in Ability
            Cloud.
          </p>
          <p>The utility allows users to:</p>
          <ul>
            <li>
              Establish connection and browse data entities from OPC UA Servers
              that are connected to ABB Ability Edgenius devices.
            </li>
            <li>
              Select or unselect the required data entities to be activated and
              published to Ability Cloud for subscription.
            </li>
          </ul>
          <p>
            <img
              src="images/UserDocsImages/opcuaconfigtoolupdated.png"
              className="image-margin"
              height="65%"
              width="65%"
            />
            <div>Figure 1: OPC UA Configuration Utility</div>
          </p>
          <p>
            Users should have "ApplicationEngineer" grant for accessing OPC UA
            Configuration Tool. Please contact Administrator for getting access
            grants.
          </p>
        </div>

        <div
          id="%2.opcuaconfigurationtool%%2.1.configuration%"
          ref={refs["%2.opcuaconfigurationtool%%2.1.configuration%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconfigurationtool%%2.1.configuration%")
          }
        >
          <p className="headers-document">2.1. Configuration</p>
        </div>
        <div
          id="%2.opcuaconfigurationtool%%2.1.configuration%2.1.1.connectopcuaserver"
          ref={
            refs[
              "%2.opcuaconfigurationtool%%2.1.configuration%2.1.1.connectopcuaserver"
            ]
          }
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconfigurationtool%%2.1.configuration%2.1.1.connectopcuaserver"
            )
          }
        >
          <p className="headers-document">2.1.1. Connect OPC UA Server</p>
          <p>
            Execute the following steps to establish the connection to a UA
            Server.
          </p>
          <ol>
            <li>
              Login to OPC UA Configuration Utility. The OPC Servers page lists
              all the configured OPC UA servers, connected to the Edge devices
              for the logged in tenant. The User will also have an option to
              view the configured OPC UA servers grouped by their host edge
              devices.
            </li>
            <p className="image-margin">
              <img
                src="images/UserDocsImages/connectopcuaupdated1.png"
                className="image-margin"
              />
              <div>Figure 2: Configured OPC UA Servers</div>
            </p>
            <li>
              The Servers will be listed with their corresponding hosting edge,
              status, and server description.
            </li>
            <li>
              To add a new connection to an OPC UA Server, click on the{" "}
              <img
                src="images/UserDocsImages/connectuaicon1.png"
                className="image-margin"
              />
              button in ‘OPC Servers’ page.
            </li>
            <p className="image-margin">
              <img
                src="images/UserDocsImages/connecttoopcuaupdated6.png"
                className="image-margin"
              />
              <div>Figure 3: Connecting to a new OPC UA Server</div>
            </p>
            <li>
              In the Connect to OPC UA Server dialog (see Figure 3) enter the
              details for:
              <ul>
                <li>
                  Hosting Edge- Edge from which connection to a southbound OPC
                  UA Server will be established.
                </li>
                <li>
                  OPC UA Server Name- Name provided by the user to identify OPC
                  UA Server.
                </li>
                <li>OPC UA End Point– URL of the OPC UA Server.</li>
                <li>
                  Server Description– Sever description provided by the user.
                </li>
                <li>
                  Profile - Server profile to be either HA or DA selected by
                  user.
                </li>
                <li>
                  Configure secure connection – Enables ‘Upload File’ button for
                  uploading OPC UA Server certificate.
                </li>
                <li>
                  User Authentication – Check this option to enable secure
                  communication between the OPC UA Server and the OPC UA
                  Configuration Utility client. Enter the Username and Password
                  in order to authenticate the user identity, the OPC UA server
                  uses the user identity token it received to accept or reject
                  the communication.
                </li>
              </ul>
            </li>
            <div className="icon-wrapper">
              <div className="info-icon">&#8505;</div>
              <div className="info-text">
                <i>
                  Support for User Authentication is planned for a future
                  release.
                </i>
              </div>
            </div>
            <div className="icon-wrapper">
              <div className="info-icon">&#8505;</div>
              <div className="info-text">
                <i>
                  A security certificate must be uploaded if a password based
                  authentication is chosen.
                </i>
              </div>
            </div>
            <li>
              Once the details are provided click Connect to Server (see Figure
              3) to connect to the OPC UA Server. Once the connection is
              successful, the Upload Nodeset file button will be enabled. Follow
              the procedure mentioned in Configure OPC UA Server to work with
              the Nodeset files and update the server.
            </li>
          </ol>
        </div>

        <div
          id="%2.opcuaconfigurationtool%%2.1.configuration%2.1.2.configureopcuaserver"
          ref={
            refs[
              "%2.opcuaconfigurationtool%%2.1.configuration%2.1.2.configureopcuaserver"
            ]
          }
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconfigurationtool%%2.1.configuration%2.1.2.configureopcuaserver"
            )
          }
        >
          <p className="headers-document">2.1.2. Configure OPC UA Server</p>
          <p>
            To browse and work with the OPC UA Server, the OPC UA Configuration
            utility must understand the information model of the connected OPC
            UA Server.
          </p>
          <p>
            This information model schema is exposed as a node-set configuration
            schema xml which needs to be uploaded first.
          </p>
          <p>
            <img
              src="images/UserDocsImages/configureopcua1.png"
              className="image-margin"
            />
          </p>
          <ol>
            <li>
              On clicking the Upload Nodeset files button the user is given two
              options for uploading the Nodeset configuration from the
              corresponding OPC UA Server. Automatic configuration option is
              selected by default.
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/configureopcua2.png"
                  className="image-margin"
                />
                <div>Figure 4: Upload Nodeset dialog</div>
              </p>
              <ul>
                <li>
                  <div>Automatic Configuration:</div>
                  <div>
                    In automatic configuration mode, the Nodeset configuration
                    of the OPC UA Server is extracted by the UA Connect module
                    in the Edge device and the corresponding types will be
                    created in the OPC UA Configuration Utility.
                  </div>
                  <div className="icon-wrapper">
                    <div className="info-icon">&#8505;</div>
                    <div className="info-text">
                      <i>
                        If Custom nodeset mapping is not selected while
                        uploading a Nodeset file then follow the instructions in
                        Nodeset Mapping Tool.
                      </i>
                    </div>
                  </div>
                </li>
                <li>
                  <div>Manual Configuration – Upload node set file:</div>
                  <div>
                    In manual configuration mode, instead of UA Connect module
                    uploading the Nodeset configuration, the user needs to
                    manually upload the Nodeset file directly from the OPC UA
                    Config Utility.
                  </div>
                  <p className="image-margin">
                    <img
                      src="images/UserDocsImages/configureopcua3.png"
                      className="image-margin"
                    />
                    <div>Figure 5: Manual Nodeset file upload</div>
                  </p>
                  <div className="icon-wrapper">
                    <div className="info-icon">&#8505;</div>
                    <div className="info-text">
                      <i>
                        Selecting the Use Custom Nodeset Mapping check box in
                        the dialog to modify the nodeset hierarchy (on the
                        client side) before creating a browse structure.
                      </i>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li>
              Click the Submit button to upload the Nodeset configuration. An
              acknowledge message confirms the Nodeset configuration upload has
              been initiated.
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/custommappingflow.png"
                  className="image-margin"
                />
                <div>Figure 6: Using custom nodeset mapping</div>
              </p>
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/configureopcua4.png"
                  className="image-margin"
                />
                <div>Figure 7: Initiate Nodeset configuration upload</div>
              </p>
            </li>
            <li>
              The Update server button will be enabled after the nodeset file is
              uploaded. The time taken for configuration of types depends on the
              bandwidth and the size of the file.<br></br>
              Click Update server to complete the Server configuration.<br></br>
              Nodeset mapping is used to add nodes from the source nodes to the
              target area allowing the user to create an object hierarchy and
              place variables based on their requirements.
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/uploadNodesetComplete.png"
                  className="image-margin"
                />
                <div>Figure 8: Nodeset configuration complete</div>
              </p>
            </li>
            <li>
              Nodeset mapping page lists the Source Nodeset files and allows the
              user to configure a target nodeset files based on the project
              requirements. Clicking the Save as Draft button will save the
              Nodeset file. However, to deploy the target Nodeset file, click
              the Save and Submit button as shown in Figure 9.
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/nodesetmappingpage.png"
                  className="image-margin"
                />
                <div>Figure 9. Nodeset Mapping page</div>
              </p>
              <div className="icon-wrapper">
                <div className="info-icon">&#8505;</div>
                <div className="info-text">
                  <i>
                    The Save as Draft option saves the nodeset mapping but does
                    not deploy it whereas the Save and Submit option will save
                    the configuration and deploy it.
                  </i>
                </div>
              </div>
            </li>
            <li>
              Once the Server configuration is done with or without the nodeset
              mapping the OPC UA Server will be added to the list of connected
              Servers on the Edge Device with the status set to Connected.
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/opcserverspage.png"
                  className="image-margin"
                />
                <div>Figure 10: OPC Servers Page</div>
              </p>
            </li>
            <li>
              While connecting to OPC UA Server or during Nodeset upload, even
              if the 'Connect to OPC UA Server' popup is closed at any time by
              clicking on the X button the operation will progress in the
              background. In the OPC Servers page, the status row will show the
              status of the server configuration. Click button in the server
              row, select ‘Server Settings’ option and the 'Update Server' popup
              will reopen.
            </li>
          </ol>
        </div>

        <div
          id="%2.opcuaconfigurationtool%%2.2.nodesetmapping%"
          ref={refs["%2.opcuaconfigurationtool%%2.2.nodesetmapping%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconfigurationtool%%2.2.nodesetmapping%")
          }
        >
          <p className="headers-document">2.2. Nodeset Mapping Tool</p>
          <p>
            The Nodeset Mapping tool enables the user to create and use dynamic
            information model, a custom hierarchy of objects and variables that
            differ from those on the source OPC server without changing anything
            on the server side.<br></br>
            It allows the user to import and visualize a nodeset. By default,
            the OPC server nodeset is visible in the Source Nodeset (see Figure
            11) while a blank Target Nodeset in the right pane of the Nodeset
            mapping page.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/nodesetmapping.png"
              className="image-margin"
            />
            <div>Figure 11: Nodeset Mapping Tool</div>
          </p>

          <div className="icon-wrapper">
            <div className="info-icon">&#8505;</div>
            <div className="info-text">
              <i>
                Association with Base Object Type removes any type association
                done by the user. Technically, all object links to Base Object
                Type by default so removing a user defined type means linking
                with the base type.
              </i>
            </div>
          </div>
          <p>The user can: </p>

          <ul>
            <li>Create new types in the target namespace.</li>
            <li>
              Copy existing types from source to the target namespace. Type name
              can be configured or kept the same as in source.
            </li>
            <li>
              Create folder or base object nodes during type creation for
              grouping and creating hierarchy.
            </li>
            <li>
              Create instances of types in target namespace, instance name can
              be configured or same as in source.
            </li>
            <li>
              Copy instances from source to the target namespace. Instance name
              can be changed or kept the same as in source.
            </li>
            <li>
              Map nodes to type’s child nodes for instance creation, node names
              can be changed.
            </li>
            <li>
              Export source or target nodeset and import a target nodeset file
              in CSV format. Importing or exporting CSV files is especially
              useful when operations involve large data sets.
            </li>
          </ul>
          <br></br>
          <div className="icon-wrapper">
            <div className="info-icon">&#8505;</div>
            <div className="info-text">
              <i>
                No errors checks or validation is carried out on CSV files
                outside the OPC UA Configuration Utility. It is assumed that the
                user has the experience and expertise in OPC protocol and
                engineering concepts to work with a CSV file.
              </i>
            </div>
          </div>
          <br></br>
          <div className="icon-wrapper">
            <div className="info-icon">&#8505;</div>
            <div className="info-text">
              <i>
                Any errors in the configuration or creation of the target
                nodeset will be validated upon save.
              </i>
            </div>
          </div>
          <br></br>
          <div className="icon-wrapper">
            <div className="warning-icon">!</div>
            <div className="info-text">
              <i>
                Unsubscribe a configured object before using nodeset mapping, if
                you want to modify the browse structure using nodeset mapping
                option.
              </i>
              <br />
              <i>
                Subscription need to be reconfigured after submitting a custom
                nodeset map.
              </i>
            </div>
          </div>
        </div>

        <div
          id="%2.opcuaconfigurationtool%%2.3.browseopcuahierarchy%"
          ref={refs["%2.opcuaconfigurationtool%%2.3.browseopcuahierarchy%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconfigurationtool%%2.3.browseopcuahierarchy%"
            )
          }
        >
          <p className="headers-document">2.3. Browse OPC UA Hierarchy</p>
          <p>
            The OPC Servers page lists the OPC UA Servers connected to an Edge
            Device. UA Servers with status set to 'Connected' state will be
            available for browse.
          </p>
          <ol>
            <li>
              Click on a 'Connected' OPC UA Server row from the OPC UA Servers
              list to navigate to the Browse Server page.
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/mcibrowse.png"
                  className="image-margin"
                />
                <div>Figure 12: Browse Server Page</div>
              </p>
            </li>
            <li>
              The Browse Server page lists the data entities of the selected OPC
              UA Server in a tree view, which can be browsed by the user.
              Selecting a node from the browse tree displays the available
              variables for the selected node object.
              <div className="icon-wrapper">
                <div className="info-icon">&#8505;</div>
                <div className="info-text">
                  <i>
                    If custom mapping is used, the browse structure will be
                    created as per the target nodeset defined. Although there is
                    no change on the server side node, the variables will mapped
                    to the object hierarchy as defined in the target nodeset
                    area.
                    <br></br>
                    If custom mapping is not used, the browse structure is
                    created as per the nodeset structure in the source nodeset
                    from the OPC UA Server.
                  </i>
                </div>
              </div>
              <p>
                The ‘Selected node’ section displays the following fields when
                the user selects a node from the browse tree.
              </p>
              <i>Table 1. Field Information</i>
              <table>
                <colgroup>
                  <col span={1} style={{ width: "20%" }} />
                  <col span={1} style={{ width: "80%" }} />
                </colgroup>
                <tr>
                  <th>Field Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>Title</td>
                  <td>Name of the object (will be displayed in the title)</td>
                </tr>
                <tr>
                  <td>Desired Type</td>
                  <td>
                    Editable field. Disabled if an ability type is detected
                    during Nodeset configuration upload. If the ability type is
                    not detected or refers to FolderType or baseobjecttype, user
                    can specify the type name in this field which will be
                    assigned on subscription of any variables in the Object (by
                    clicking ‘Activate’)
                  </td>
                </tr>
                <tr>
                  <td>DataBinding Name</td>
                  <td>
                    Editable field. The data binding object name can be edited
                    before subscription.
                  </td>
                </tr>
                <tr>
                  <td>Sampling Interval</td>
                  <td>
                    Editable field. The default Sampling interval is set as 1000
                    milliseconds. Updates to the ‘Sampling interval’ is
                    applicable to all the variables under the object selected
                    for activation.
                  </td>
                </tr>
              </table>
              <p>
                The 'Selected node' section also displays the list of variable
                tags and the list of methods available in the Object. The
                Variables will be listed with their corresponding variable name,
                variable type id, and browse name. The Methods will be listed
                with their corresponding method name and browse name. User can
                subscribe to variable tags or enable the methods by selecting
                them from the list and clicking 'Activate'. Already subscribed
                variable tags and enabled methods are marked with a checkbox.
                Variables and Methods with unsupported data types (e.g., custom
                data types) are disabled (grey) and cannot be selected for
                activation.
              </p>
              <p>
                Additional Information for the OPC UA object can be viewed by
                clicking on the{" "}
                <img
                  src="images/UserDocsImages/browseupdated2.png"
                  className="image-margin"
                />{" "}
                icon near the object name.
              </p>
              <i>Table 2. Field Information</i>
              <table>
                <colgroup>
                  <col span={1} style={{ width: "20%" }} />
                  <col span={1} style={{ width: "80%" }} />
                </colgroup>
                <tr>
                  <td>OPC UA Type</td>
                  <td>OPC UA Type</td>
                </tr>
                <tr>
                  <td>NodeId</td>
                  <td>
                    Unique identifier for the node in the OPC UA Address space
                  </td>
                </tr>
                <tr>
                  <td>Detected Ability Type</td>
                  <td>
                    Ability type corresponding to the OPC UA Type generated
                    during Nodeset configuration upload. If the OPC UA Type
                    cannot be mapped during Nodeset upload this field is set to
                    ‘Undefined’. User then has the option to manually specify
                    the type via the ‘Desired Type’ field
                  </td>
                </tr>
                <tr>
                  <td>Object Existing As</td>
                  <td>
                    Blank by default. If any variable is subscribed an Object Id
                    generated in Ability Information Model, which uniquely
                    identifies the object.
                  </td>
                </tr>
              </table>
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/mcinodeDetails.png"
                  className="image-margin"
                />
                <div>Figure 13: Node Details popup</div>
              </p>
              <p>
                Additional Information regarding the input parameters and output
                parameters for the Methods can be viewed by clicking on the{" "}
                <img
                  src="images/UserDocsImages/browseupdated2.png"
                  className="image-margin"
                />{" "}
                icon in the method row.
              </p>
              <table>
                <colgroup>
                  <col span={1} style={{ width: "20%" }} />
                  <col span={1} style={{ width: "80%" }} />
                </colgroup>
                <tr>
                  <td>Input/Output Parameter Name</td>
                  <td>Input/Output parameter</td>
                </tr>
                <tr>
                  <td>Data Type</td>
                  <td>Data Type</td>
                </tr>
                <tr>
                  <td>Node Type Id</td>
                  <td>Corresponding Node Type Id</td>
                </tr>
              </table>
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/mciinputoutputparams.png"
                  className="image-margin"
                />
                <div>Figure 14: Input and Output Parameters details</div>
              </p>
            </li>
          </ol>
        </div>

        <div
          id="%2.opcuaconfigurationtool%%2.4.activatevariablesandmethods%"
          ref={
            refs["%2.opcuaconfigurationtool%%2.4.activatevariablesandmethods%"]
          }
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconfigurationtool%%2.4.activatevariablesandmethods%"
            )
          }
        >
          <p className="headers-document">
            2.4. Activate Variables and Methods
          </p>
          <p>
            In the Browse Server page, navigate through the object hierarchy to
            select the object that contains the required variables. From the
            'Selected Node' section, select the variables for subscription
            and/or the methods to be enabled and click Activate.
          </p>

          <p className="image-margin">
            <img
              src="images/UserDocsImages/mcicollectnew.png"
              className="image-margin"
            />
            <div>Figure 15: Select Variables and Methods for Activation</div>
          </p>
          <p>
            Check for confirmation message See Figure 16 for a reference of the
            confirmation message.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/mcicollectnewsuccess.png"
              className="image-margin"
            />
            <div>Figure 16: Activate Variables and Methods</div>
          </p>
          <p>
            The user can filter through the variables and methods by clicking on
            the
            <img
              src="images/UserDocsImages/browsefiltericon.png"
              className="image-margin"
            />{" "}
            button in the respective tables. See Figure 17 for reference.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/MCIFilter.png"
              className="image-margin"
            />
            <div>Figure 17: Variables and Methods Filter</div>
          </p>
          <p>
            The users can view the lists of objects that has variables activated
            and published to Ability Instance in the ‘Configured Data’ tab under
            ‘Configured Objects’ section. In this tab users can either pause
            subscription or enable subscription for the published objects, or
            remove objects from the collections, as needed. The action will be
            applicable to all the variables under the selected object.
          </p>
          <p>
            Variables that are already selected for activation will be shown as
            checked in the 'Browse Server - Selected Node' section. Remove the
            selection and click Modify button to remove the variable from the
            subscribed list.
          </p>
          <p>
            The users can also view the methods that were enabled in the
            ‘Configured Data’ tab under ‘Enabled Methods’ section. The user can
            disable the methods by selecting them and clicking on Disable
            button. Methods those are activated will be shown as checked in the
            'Browse server - Selected Node' section. Alternatively, user can
            also remove the selection and click Modify button to disable methods
            of a selected node.
          </p>
          <p>
            OPC UA Method can also be invoked from the Edgenius Applications
            (e.g. Asset Hub) using Method APIs.
          </p>
          <br></br>
          <div className="icon-wrapper">
            <div className="info-icon">&#8505;</div>
            <div className="info-text">
              <i>
                The parameters of Method APIs do not support any space in the
                parameter name. For example, Floatvalue is supported but Float
                Value as a parameter name is not supported.
              </i>
            </div>
          </div>

          <p className="image-margin">
            <img
              src="images/UserDocsImages/mciconfiguredDatanew.png"
              className="image-margin"
            />
            <div>Figure 18: Configured Data</div>
          </p>
        </div>

        <div
          id="%2.opcuaconfigurationtool%%2.5.configureopcuatypes%"
          ref={refs["%2.opcuaconfigurationtool%%2.5.configureopcuatypes%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconfigurationtool%%2.5.configureopcuatypes%"
            )
          }
        >
          <p className="headers-document">2.5. Configuring OPC UA Types</p>
          <p>
            Configuring OPC UA Types Once variables are activated then the
            subscribed variables available to other applications like ABB
            Edgenius™ Dashboard and ABB Edgenius™ Streaming Calculation
            Engineering Tool.
          </p>
          <p>
            As part of the Nodeset configuration upload the OPC Types are
            processed and mapped to corresponding types in Edgenius. It's
            possible that OPC UA server has objects without a OPC UA Type
            associated or associated with generic OPC UA type such as Folder
            Type.
          </p>

          <p>
            The 'Detected Ability Type' field will be set to 'undefined' for
            objects whose Type could not be mapped during Nodeset configuration
            upload. It is possible to activate and subscribe variables from
            Objects whose Ability Type is set as undefined, but their type will
            be set to either opcfoundation.org.ua.baseobjecttype or
            opcfoundation.org.UA.FolderType based on the associated UA type, on
            subscription.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/mciopcuatypesnodedetails.png"
              className="image-margin"
            />
            <div>Figure 19: Detected Ability Type: Undefined</div>
          </p>
          <p>
            It is also possible to configure the Desired Type while subscribing
            to the variables. For objects whose Detected Ability Type is set as
            undefined, the Desired Type field will be enabled, and user can give
            a type name for the object when variables or methods are selected
            for activation.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/mcicreatingtypeone.png"
              className="image-margin"
            />
            <div>Figure 20: Desired Type</div>
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/mcitypepopupone.png"
              className="image-margin"
            />
            <div>Figure 21: User defined Type</div>
          </p>
          <p>
            A confirmation box will ask the user whether the new type should be
            created with the selected Variables. On clicking ok the type is
            mapped for that object.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/confirmationDialogue.png"
              className="image-margin"
            />
            <div>Figure 22: Confirmation Dialogue</div>
          </p>

          <p>
            Once the variables or methods are activated, the configured type is
            mapped to the object.
          </p>
          <p>
            If the same Type name is used to map a different Folder Type object
            or the same object with a different set of variables, a confirmation
            message will check whether the user wants to proceed. While it is
            possible to proceed and map the type to multiple objects, the
            recommendation is to use unique type names for different objects.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/mcitypenodedetailspopup.png"
              className="image-margin"
            />
            <div>Figure 23: User defined Type</div>
          </p>
          <p>
            Deleting the object from the 'Configured Data' allows the user to
            give a different type mapping name as well as select a different set
            of tags for subscription.
          </p>
        </div>

        <div
          id="%2.opcuaconfigurationtool%%2.6.editdeleteserver%"
          ref={refs["%2.opcuaconfigurationtool%%2.6.editdeleteserver%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconfigurationtool%%2.6.editdeleteserver%")
          }
        >
          <p className="headers-document">
            2.6. Edit/Delete OPU UA Server Configuration
          </p>
          <p>
            Click OPC Servers on the top left corner of the Browse Server Page
            to go back to OPC Servers page.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/mcirouting.png"
              className="image-margin"
            />
            <div>Figure 24: Back to OPC Servers page</div>
          </p>
          <ul>
            <li>
              <p>Edit Server Info</p>
              <p>
                Click on{" "}
                <img
                  src="images/UserDocsImages/serverOptionsicon.png"
                  className="image-margin"
                />{" "}
                on the server row and select “Server Settings”. It will open the
                “Update OPC UA Server” popup where the user can rename the OPC
                UA Server, update the server description or initiate upload of
                node set configuration again in case the Server information
                model has been updated.
              </p>
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/connecttoopcuaupdated8.png"
                  className="image-margin"
                />
                <div>Figure 25: Server actions pop up</div>
              </p>
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/detectingserverupdated1.png"
                  className="image-margin"
                />
                <div>Figure 26: Update OPC UA Server popup</div>
              </p>
            </li>
            <li>
              Nodeset Mapping
              <p>
                Else, the user must navigate to the home page, find the server
                and click on ellipsis and select NodeSet File to the mapping
                page.
              </p>
            </li>
            <li>
              <p>Delete Server</p>
              <p>
                Click on{" "}
                <img
                  src="images/UserDocsImages/DeleteBinIcon.png"
                  className="image-margin"
                />{" "}
                button on the server row. It will open a warning popup asking
                for confirmation. Click on 'Remove' button to remove the Server.
              </p>
              <p className="image-margin">
                <img
                  src="images/UserDocsImages/removeserverupdated1.png"
                  className="image-margin"
                />
                <div>Figure 27: Remove OPC Server Page</div>
              </p>
            </li>
          </ul>
        </div>

        <div
          id="%3.troubleshooting%"
          ref={refs["%3.troubleshooting%"]}
          className="level-1-padding"
          onMouseOver={() => props.onDocClick("%3.troubleshooting%")}
        >
          <p className="headers-document">3. Troubleshooting</p>
          <p>
            This section describes solutions to common problems that you might
            encounter while using the OPC UA Configuration tool.
          </p>
        </div>

        <div
          id="%3.troubleshooting%%3.1.validationerror%"
          ref={refs["%3.troubleshooting%%3.1.validationerror%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%3.troubleshooting%%3.1.validationerror%")
          }
        >
          <p className="headers-document">
            3.1. Validation error - source file has changed
          </p>
          <p>
            <b>Issue:</b> Validation error as source file has changed
          </p>
          <p>
            <b>Possible cause:</b> This error occurs when a nodeset upload is
            initiated for an existing Target nodeset file, it is considered as
            an update to the Source Nodeset. Thus, leading a mismatch between
            the existing Target and Source nodesets.
          </p>
          <p>
            <b>Solution:</b> The user must clear all target nodes, save it as
            blank nodeset and then create a target nodeset.
          </p>
          <p>
            To save engineering efforts, the existing target nodeset can be
            exported as CSV and imported back on the target side.
          </p>
        </div>

        <div
          id="%3.troubleshooting%%3.2.variablenotmapped%"
          ref={refs["%3.troubleshooting%%3.2.variablenotmapped%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%3.troubleshooting%%3.2.variablenotmapped%")
          }
        >
          <p className="headers-document">
            3.2. Variable is not mapped to any source node
          </p>
          <p>
            <b>Issue:</b> Variable is not mapped to any source node
          </p>
          <p>
            <b>Possible cause:</b> When a type is used to create an object a
            node ID is not associated with the object. This is because the node
            ID is provided only by the source.
          </p>
          <p>
            <b>Solution:</b> Ensure that the node is linked to the variable
            created. Find the variable in the source, drag and drop it to
            establish the link and map the node ID.
          </p>
          <p>
            <img
              src="images/UserDocsImages/variableNotMap.png"
              className="image-margin"
            />
          </p>
        </div>

        <div
          id="%3.troubleshooting%%3.2.datatyperemoved%"
          ref={refs["%3.troubleshooting%%3.2.datatyperemoved%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%3.troubleshooting%%3.2.datatyperemoved%")
          }
        >
          <p className="headers-document">
            3.3. Existing data types will be removed
          </p>
          <p>
            <b>Issue:</b> Selected object will be structured as per associated
            type and existing child nodes will be removed to match the type
            structure.
          </p>
          <p>
            <b>Possible cause:</b> This is more of a warning to inform the user
            that the existing child notes will be removed to match the type
            structure.
          </p>
          <p>
            <b>Solution:</b>Click OK to confirm the change and proceed with the
            replacement, else click Cancel.
          </p>
          <p>
            <img
              src="images/UserDocsImages/dataTypeRemovedIcon.png"
              className="image-margin"
            />
          </p>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Documentation);
