import * as serverTypes from "../Type/TypeServers";
import * as commonTypes from "../../../Common/Type/TypeCommon";
import { PROCESS_TRANSFORM_NODESET_FILE } from "../Type/TypeServers";
export const getServers = (): serverTypes.IGetServers => {
  return {
    type: "GET_SERVERS",
  };
};
export const getServersStatus = (
  rawServersData: serverTypes.IRawServersObj[]
): serverTypes.IGetServersStatus => {
  return {
    type: "GET_SERVERS_STATUS",
    rawServersData,
  };
};
export const getCurrentServer = (
  serverId: string,
  uaModuleId: string,
  edgeId: string
): serverTypes.IGetCurrentServer => {
  return {
    type: "GET_CURRENT_SERVER",
    serverId,
    uaModuleId,
    edgeId,
  };
};
export const getServerInBackground = (
  serverId: string,
  uaModuleId: string,
  edgeId: string
): serverTypes.IGetServerInBackgroundAfterUpload => {
  return {
    type: "GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
    serverId,
    uaModuleId,
    edgeId,
  };
};
export const getSessionState = (
  serverId: string
): serverTypes.IGetSessionStateData => {
  return {
    type: "GET_SESSION_STATE_DATA",
    serverId,
  };
};
export const handleSessionStatTimeOut =
  (): serverTypes.IHandleSessionStateTimeOut => {
    return {
      type: "HANDLE_SESSION_STATE_TIMEOUT",
    };
  };
export const getEdgeList = (): serverTypes.IGetEdgeList => {
  return {
    type: "GET_EDGE_LIST",
  };
};
export const toggleView = (
  value: "edge" | "server"
): serverTypes.IToggleView => {
  return {
    type: "TOGGLE_VIEW",
    viewType: value,
  };
};
export const handleExpandCollpase = (
  clickedRow: serverTypes.IServersGridRowObj
): serverTypes.IExpandCollapse => {
  return {
    type: "ON_EXPAND_COLLAPSE",
    clickedRow,
  };
};

export const selectServerToDelete = (
  rowObj: serverTypes.IServersGridRowObj
): serverTypes.ISelectServerToDelete => {
  return {
    type: "SELECT_SERVER_TO_DELETE",
    rowObj,
  };
};
export const hideDeleteWarning = (): serverTypes.IHideWarningDialog => {
  return {
    type: "HIDE_WARNING_DIALOG",
  };
};
export const handleFilterInput = (
  text: string
): serverTypes.IHandleFilterInputText => {
  return {
    type: "HANDLE_FILTER_INPUT_TEXT",
    text,
  };
};

export const performFilter = (): serverTypes.IPerformFilter => {
  return {
    type: "PERFORM_FILTER",
  };
};
export const showMoreOptions = (
  rowObj: serverTypes.IServersGridRowObj
): serverTypes.IShowMoreOptions => {
  return {
    type: "SHOW_MORE_OPTIONS",
    rowObj,
  };
};
export const hideMoreOptions = (): serverTypes.IHideMoreOptions => {
  return {
    type: "HIDE_MORE_OPTIONS",
  };
};
export const showUpload = (
  rowObj: serverTypes.IServersGridRowObj
): serverTypes.IShowUpload => {
  return {
    type: "SHOW_UPLOAD",
    rowObj,
  };
};
export const hideUpload = (): serverTypes.IHideUpload => {
  return {
    type: "HIDE_UPLOAD",
  };
};

export const showConnectToServerModal = (
  rowObj: serverTypes.IServersGridRowObj,
  method: "add" | "update"
): serverTypes.IShowConnectToServerModal => {
  return {
    type: "SHOW_CONNECT_TO_SERVER_MODAL",
    rowObj,
    method,
  };
};
export const hideConnectToServerModal = (
  shouldRefreshAfterClosingUpdate: boolean
): serverTypes.IHideConnectToServerModal => {
  return {
    type: "HIDE_CONNECT_TO_SERVER_MODAL",
    shouldRefreshAfterClosingUpdate,
  };
};
export const handleServerName = (
  serverName: string
): serverTypes.IHandleServerName => {
  return {
    type: "HANDLE_SERVER_NAME",
    serverName,
  };
};
export const handleEndPoint = (
  endPoint: string
): serverTypes.IHandleOpcUaEndPoint => {
  return {
    type: "HANDLE_OPC_UA_ENDPOINT",
    endPoint,
  };
};
export const handleServerDescription = (
  serverDescription: string
): serverTypes.IHandleServerDescription => {
  return {
    type: "HANDLE_SERVER_DESCRIPTION",
    serverDescription,
  };
};
export const handleEdgeDropdown = (
  selectedEdge: commonTypes.IEdgeDropDownData[]
): serverTypes.IHandleEdgeDropdown => {
  return {
    type: "HANDLE_EDGE_DROPDOWN",
    selectedEdge,
  };
};
export const handleSecureConnection =
  (isEnabled?: boolean): serverTypes.IHandleRequireSecureConnection => {
    return {
      type: "HANDLE_REQUIRE_SECURE_CONNECTION",
      isEnabled
    };
  };

export const handleSelectCertificate = (
  certificate: string,
  certificateName: string
): serverTypes.ISelectCertificate => {
  return {
    type: "HANDLE_CERTIFICATE_SELECT",
    certificate,
    certificateName,
  };
};
export const replaceCertificate = (): serverTypes.IReplaceCertificate => {
  return {
    type: "HANDLE_REPLACE_CERTIFICATE",
  };
};
export const setFileValidity = (
  status: boolean
): serverTypes.ISetFileValidity => {
  return {
    type: "SET_FILE_VALIDITY",
    status,
  };
};
export const handleSort = (
  id: string,
  direction: "asc" | "desc" | "unsorted"
): serverTypes.IHandleSort => {
  return {
    type: "HANDLE_SORT",
    colId: id,
    sortDirection: direction,
  };
};
export const pickNodeSetFile = (
  fileNames: string[],
  nodeSetFiles: any[]
): serverTypes.IPickNodeSetFile => {
  return {
    type: "PICK_NODESET_FILE",
    fileNames,
    nodeSetFiles,
  };
};
export const showNodeSetUploadModal =
  (): serverTypes.IShowNodeSetFileUpload => {
    return {
      type: "SHOW_NODESET_UPLOAD_DIALOG",
    };
  };
export const hideNodeSetUploadModal =
  (): serverTypes.IHideNodeSetFileUpload => {
    return {
      type: "HIDE_NODESET_UPLOAD_DIALOG",
    };
  };
export const setNodeSetUploadOption = (
  value: string
): serverTypes.ISetNodeSetUploadOption => {
  return {
    type: "SET_NODESET_UPLOAD_OPTION",
    value,
  };
};
export const removeNodeSetFile = (
  index: number
): serverTypes.IRemoveNodeSetFile => {
  return {
    type: "REMOVE_NODESET_FILE",
    index,
  };
};
export const clearAllNodeSetFiles = (): serverTypes.IClearAllNodeSetFiles => {
  return {
    type: "CLEAR_ALL_NODESET_FILES",
  };
};
export const submitAddServer = (
  paramsToBeSubmitted: serverTypes.IAddServerParams
): serverTypes.ISubmitAddServer => {
  return {
    type: "SUBMIT_ADD_SERVER",
    paramsToBeSubmitted,
  };
};
export const submitUpdateServer = (
  paramsToBeSubmitted: serverTypes.IUpdateServerParams
): serverTypes.ISubmitUpdateServer => {
  return {
    type: "SUBMIT_UPDATE_SERVER",
    paramsToBeSubmitted,
  };
};
export const submitDeleteServer = (
  serverId: string,
  uaModuleId: string
): serverTypes.ISubmitDeleteServer => {
  return {
    type: "SUBMIT_DELETE_SERVER",
    serverId,
    uaModuleId,
  };
};

export const submitNodeSetAutoConfig = (
  uaModuleId: string,

  serverId: string,
  typeName: string,
  callBack: () => void
): serverTypes.ISubmitAutoConfig => {
  return {
    type: "SUBMIT_NODE_SET_AUTO_CONFIG",
    uaModuleId,
    serverId,
    typeName,
    callBack,
  };
};

export const submitNodeSetAfterUpload = (
  nodeSetFiles: any[],
  uaModuleId: string,

  serverId: string,
  typeName: string,
  callBack: () => void
): serverTypes.ISubmitNodeSetAfterUpload => {
  return {
    type: "SUBMIT_NODE_SET_AFTER_UPLOAD",
    selectedNodeSetFiles: [...nodeSetFiles],
    uaModuleId,
    serverId,
    typeName,
    callBack,
  };
};

export const processTransformedNodeSetFileAction = (
  nodeSetFiles: any[],
  opcServerId: string,
  typeName: string
): serverTypes.IProcessTransformedNodeSetFileUpload => {
  return {
    type: PROCESS_TRANSFORM_NODESET_FILE,
    selectedNodeSetFiles: [...nodeSetFiles],
    opcServerId,
    typeName,
  };
};
