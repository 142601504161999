import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateUpdateNodeRequest } from "../../../../Models/CreateCompModels/CreateCompModel";
import {
  HomeComponentContainerState,
  LandingPageDataAPIGrid,
} from "../../../../Models/HomeComponent/HomeCompModel";
import { ReducerTypesContainerState } from "../../../../Reducer/reducerTypes/reducerTypes";

const initialStateInitialization: HomeComponentContainerState = {
  edGenieData: [],
  errorMessage: "",
  toastMessage: [],
  payloadToSaveNodeSetData: null,
  edGenieDataId: "",
};

const homePageReducer = createSlice({
  name: "homePageReduce",
  initialState: initialStateInitialization,
  reducers: {
    getEdgenieDataList: (
      state,
      { payload }: PayloadAction<LandingPageDataAPIGrid[]>
    ) => {
      state.edGenieData = [];
      state.edGenieData = payload;
    },
    createEdgenieData: (
      state,
      { payload }: PayloadAction<CreateUpdateNodeRequest>
    ) => {
      state.edGenieData.unshift(payload);
      state.edGenieDataId = payload.id;
    },
    updateEdgenieData: (
      state,
      { payload }: PayloadAction<CreateUpdateNodeRequest>
    ) => {
      const updateType = state.edGenieData.map((post) => {
        if (post.id === payload.id) {
          return {
            ...post,
            id: payload.id,
            name: payload.name,
            uri: payload.uri,
            version: payload.version,
          };
        } else return post;
      });
      state.edGenieData = updateType;
    },
    deleteEdgenieData: (state, { payload }: PayloadAction<string>) => {
      const findIndexDel = state.edGenieData.findIndex((x) => x.id === payload);
      state.edGenieData.splice(findIndexDel, 1);
    },
    toastMessagesReducer: (state, { payload }: PayloadAction<string[]>) => {
      state.toastMessage = [];
      state.toastMessage = payload;
    },

    updateReducerPayloadNodeSetData: (
      // store the same data available in state; Used to send to api
      state,
      { payload }: PayloadAction<CreateUpdateNodeRequest | null>
    ) => {
      state.payloadToSaveNodeSetData = payload;
    },

    clearIdFromExportComponent: (state) => {
      state.edGenieDataId = "";
      state.toastMessage = [];
    },
  },
});

export const {
  getEdgenieDataList,
  createEdgenieData,
  updateEdgenieData,
  deleteEdgenieData,
  toastMessagesReducer,
  updateReducerPayloadNodeSetData,
  clearIdFromExportComponent,
} = homePageReducer.actions;

export const homePageValuesSelector = (
  state: ReducerTypesContainerState
): HomeComponentContainerState => state.homePageReduce;

export default homePageReducer.reducer;
