import React from "react";
import {
  Button,
  WithDialog,
  Dialog,
  Tooltip,
  WithTooltip,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import { Updater } from "use-immer";
import {
  mappingToolAPI,
  variablesMappingTool,
} from "../../../Utils/ConstantsMappingTool";

import { useDispatch, useSelector } from "react-redux";
import "./ExportImportComp.css";

import { CreateComponentModelStates } from "../../../Models/CreateCompModels/CreateCompModel";
import { TreeItemIndex } from "react-complex-tree";
import { homePageValuesSelector } from "../../../Views/Browse/Action/homePageStore/homePageReducers";
import {
  importCsvDataAction,
  isLoadingFor,
  saveTargetNodeSetDataAction,
  fetchNodeSetDataByIdAction,
  clearErrorValidation,
} from "../../../Views/Browse/Action/nodeTreeStore";

interface Props {
  idString: string | undefined;
  importApi: boolean;
  copyTypeFunction: (typeOrObject: string) => void;
  disabledExportSource?: boolean;
  selectedItems: TreeItemIndex[];
  objectInstanceDisabled?: boolean;
  objectTypeDisabled?: boolean;
  disableDownloadAndExportTarget?: boolean;
  state: CreateComponentModelStates;
  setState: Updater<CreateComponentModelStates>;
  saveUpdateValidation: (stateData: CreateComponentModelStates) => boolean;
}

const ExportImportComp: React.FC<Props> = ({
  idString,
  importApi,
  copyTypeFunction,
  disabledExportSource,
  objectInstanceDisabled,
  objectTypeDisabled,
  disableDownloadAndExportTarget,
  state,
  setState,
}) => {
  const dispatch = useDispatch();
  const { edGenieDataId } = useSelector(homePageValuesSelector);

  const importData = (closeDialogCallback: () => void) => {
    const formData = new FormData();

    if (state.fileSelected) {
      formData.append("fileImported", state.fileSelected);
      const fileName = state.fileSelected?.name;
      const fileExtension = fileName
        .split(".")
        [fileName.split(".").length - 1].toLowerCase();

      if (fileExtension === "xlsx" || fileExtension === "csv") {
        dispatch(importCsvDataAction(idString || edGenieDataId, formData));
        closeDialogCallback();
      } else {
        const message = "xlsx or csv";

        dispatch({
          type: "SHOW_NOTIFICATION",
          notificationType: "alarm",
          message: `Please provide a valid ${message} file`,
        });
      }
    }
  };

  const downloadTargetXML = (fileType: string, sourceOrTarget: string) => {
    axios({
      url: `${mappingToolAPI.localhost}/OpcServers/${idString}/nodesets/${sourceOrTarget}?format=${fileType}`, //your urlmethod: 'GET',
      responseType: "blob", // important
    }).then((response) => {
      if (response.data) {
        const blob = response.data;
        const nameDownload = `${sourceOrTarget}.${fileType}`;

        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = blobUrl;

        link.download = nameDownload;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        // Remove link from body
        document.body.removeChild(link);
      }
    });
  };

  const downloadTargetXMLAfterSave = (dlg: Dialog) => {
    if (state.targetNodeMappedData && state.targetNodeMappedData.size > 0) {
      const arrayedData = Array.from(state.targetNodeMappedData.values());
      const updatedArray = arrayedData.map(
        ({
          data,
          index,
          children,
          isFolder,
          hasChildren,
          isTypeObjectChild,
          sourceIndex,
          parentIndex,
          ...restData
        }) => restData
      );
      dispatch(
        saveTargetNodeSetDataAction(
          idString || edGenieDataId,
          updatedArray,
          1,
          state.edName,
          state.edUri,
          "",
          () => {
            setTimeout(() => {
              dispatch(fetchNodeSetDataByIdAction(idString || edGenieDataId));
              downloadTargetXML("xml", "target");

              dispatch(clearErrorValidation());
            }, 500);
          }
        )
      );
    }
    dlg.close();
  };

  return (
    <div className="headerClass" style={{ display: "flex" }}>
      {importApi && (
        <div style={{ flex: "1 1 auto" }}>
          <WithTooltip>
            <Button
              type="normal"
              icon="abb/paste"
              className={"editIcon m-4"}
              sizeClass="small"
              shape="rounded"
              style={{ marginLeft: "8px" }}
              onClick={() => {
                if (state.sourceNodeItems.length > 5000) {
                  dispatch(isLoadingFor(true));
                }
                setTimeout(() => {
                  copyTypeFunction(variablesMappingTool.TYPE);
                }, 50);
              }}
              disabled={objectTypeDisabled}
            />
            <Tooltip
              triggerOnClick={false}
              lazyLoading={false}
              followCursor={true}
              disabled={false}
              delayShow={0}
              delayHide={0}
            >
              <span>{variablesMappingTool.CopyType}</span>
            </Tooltip>
          </WithTooltip>
        </div>
      )}
      {importApi && (
        <WithTooltip>
          <Button
            type="normal"
            icon="abb/copy"
            className="editIcon"
            sizeClass="small"
            shape="rounded"
            style={{ marginLeft: "8px" }}
            onClick={() => {
              if (state.sourceNodeItems.length > 5000) {
                dispatch(isLoadingFor(true));
              }
              setTimeout(() => {
                copyTypeFunction(variablesMappingTool.OBJECT);
              }, 50);
            }}
            disabled={objectInstanceDisabled}
          />
          <Tooltip
            triggerOnClick={false}
            lazyLoading={false}
            followCursor={true}
            disabled={false}
            delayShow={0}
            delayHide={0}
          >
            <span>{variablesMappingTool.CopyInstance}</span>
          </Tooltip>
        </WithTooltip>
      )}
      {!importApi && (
        <WithTooltip>
          <WithDialog>
            <Button
              type="normal"
              icon="abb/download"
              className="editIcon"
              sizeClass="small"
              shape="rounded"
              style={{ marginLeft: "8px" }}
              disabled={disableDownloadAndExportTarget}
            />
            <Dialog
              showCloseButton={true}
              closeOnEscape={false}
              closeOnLostFocus={false}
              dimBackground={true}
              title="Warning!"
              standardButtonsOnBottom={[
                {
                  text: "No",
                  type: "discreet-black",
                  handler: (dlg) => {
                    downloadTargetXML("xml", "target");
                    dlg.close();
                  },
                },
                {
                  text: "Yes",
                  type: "primary-black",
                  handler: (dlg) => {
                    downloadTargetXMLAfterSave(dlg);
                  },
                },
              ]}
            >
              <div>Do you want to save changes and then download?</div>
            </Dialog>
          </WithDialog>
          <Tooltip
            triggerOnClick={false}
            lazyLoading={false}
            followCursor={true}
            disabled={false}
            delayShow={0}
            delayHide={0}
          >
            <span>Download Nodeset</span>
          </Tooltip>
        </WithTooltip>
      )}
      {/* display import in target only   */}
      {!importApi && (
        <WithTooltip>
          <WithDialog>
            <Button
              type="normal"
              icon="abb/import"
              className="editIcon"
              sizeClass="small"
              shape="rounded"
              style={{ marginLeft: "8px" }}
            />
            <Dialog
              showCloseButton={true}
              closeOnEscape={false}
              closeOnLostFocus={false}
              dimBackground={true}
              onClose={() =>
                setState((draft) => {
                  draft.fileSelected = null;
                })
              }
              title={variablesMappingTool.importTitle}
              render={(dialog: Dialog) => (
                <div className="iconClass">
                  <div className="importAndCheckBox">
                    <input
                      type="file"
                      id="importFile"
                      name={variablesMappingTool.importFile}
                      accept=".csv ,.xlsx"
                      onChange={(event) => {
                        if (!event.target.files) return;
                        else {
                          const listFile = event.target.files;
                          if (listFile) {
                            setState((draft) => {
                              draft.fileSelected = listFile[0];
                            });
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="importButton">
                    <Button
                      type="primary-blue"
                      text={variablesMappingTool.Import}
                      style={{ marginLeft: "8px" }}
                      onClick={() => {
                        importData(dialog.close);
                      }}
                      disabled={!state.fileSelected}
                    />
                  </div>
                </div>
              )}
            />
          </WithDialog>
          <Tooltip
            triggerOnClick={false}
            lazyLoading={false}
            followCursor={true}
            disabled={false}
            delayShow={0}
            delayHide={0}
          >
            <span>{importApi ? "Import XML" : "Import CSV"}</span>
          </Tooltip>
        </WithTooltip>
      )}
      <WithTooltip>
        <Button
          type="normal"
          icon="abb/export"
          className="editIcon"
          sizeClass="small"
          shape="rounded"
          style={{ marginLeft: "8px" }}
          // onClick={() => exportData(true)}
          onClick={
            () => downloadTargetXML("csv", importApi ? "source" : "target")
            // downloadTargetXMLs(importApi ? "sourcecsv" : "targetcsv")
          }
          disabled={
            (!importApi && disableDownloadAndExportTarget) ||
            (importApi && disabledExportSource)
          }
        />

        <Tooltip
          triggerOnClick={false}
          lazyLoading={false}
          followCursor={true}
          disabled={false}
          delayShow={0}
          delayHide={0}
          className=""
          style={{ width: "70px", position: "fixed", right: "0", top: "0" }}
        >
          <span>Export CSV</span>
        </Tooltip>
      </WithTooltip>
    </div>
  );
};

export default ExportImportComp;
