import React from "react";
import "./SourceTree.css";
import { Icon } from "@abb/abb-common-ux-react";
import icon1 from "./icon1.png";
import icon2 from "./icon2.png";
import { useSelector } from "react-redux";
import { IState } from "../../../../Reducer";

export const IconDisplay = ({
  folderIs,
  NodeClassName,
}: {
  folderIs: boolean | undefined;
  NodeClassName: string | undefined;
}) => {
  const commonState = useSelector((state: IState) => state.commonState);

  return (
    <>
      {folderIs && (
        <Icon name="abb/folder" className="folderIcon nodeTypeImage" />
      )}{" "}
      {!folderIs && NodeClassName?.includes("UAObject") && (
        <img
          src={icon1}
          alt="logo"
          className={`nodeTypeImage ${
            commonState.theme === "dark" && "image-1"
          }`}
          draggable="false"
        />
      )}
      {!folderIs && NodeClassName?.includes("UAMethod") && (
        <img
          src={icon2}
          alt="logo"
          className={`nodeTypeImage ${
            commonState.theme === "dark" && "image-1"
          }`}
          draggable="false"
        />
      )}
    </>
  );
};
