import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Tree,
  ControlledTreeEnvironment,
  TreeItemIndex,
  TreeItem,
  DraggingPosition,
} from "react-complex-tree";
import "react-complex-tree/lib/style.css";
import {
  AppTopNavi,
  AppTopNaviItem,
  Button,
  Input,
  Menu,
  MenuItem,
  MenuItemDivider,
  SubMenu,
} from "@abb/abb-common-ux-react";
import "./SourceTree.css";
import { Updater } from "use-immer";
import { useDispatch, useSelector } from "react-redux";
import ValidationNotification from "./ValidationNotification";
import ExportImportComp from "../../../../Common/Components/ExportImport/ExportImportComp";
import { CreateComponentModelStates } from "../../../../Models/CreateCompModels/CreateCompModel";
import { NodeSetEditorData } from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import { variablesMappingTool } from "../../../../Utils/ConstantsMappingTool";
import { isLoadingFor } from "../../Action/nodeTreeStore";
import { nodeSetEditorValuesSelector } from "../../Action/nodeTreeStore/nodeTreeReducers";
import { IconDisplay } from "./IconDisplay";
import {
  BASE_OBJECT_REFERENCE_VALUE,
  HAS_TYPE_DEFINITION,
  REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE,
} from "./TreeComponentSourceNode";

interface Props {
  items: any;
  edId: string | undefined;
  copyTypeFunction: (typeOrObject: string) => void;
  menuFlag: boolean;
  addNewTypeOrInstance: (
    typeOrInstance: string,
    callBackScrollToItem: (name: string) => void
  ) => void;
  renameTargetItems: (
    item: NodeSetEditorData,
    name: string,
    treeId: string
  ) => void;
  clickButtonEvent: () => void;
  OnDropOfSource: (items: TreeItem[], target: DraggingPosition) => void;
  deleteItems: () => void;
  disableDelete: () => boolean;
  newFolderCreation: (selectedItems: TreeItemIndex[]) => void;
  selectedItems: TreeItemIndex[];
  focusedItem: TreeItemIndex | undefined;
  expandedItems: TreeItemIndex[];
  setSelectedItems: (value: TreeItemIndex[]) => void;
  setFocusedItem: (value: TreeItemIndex) => void;
  setExpandedItems: (value: TreeItemIndex[]) => void;
  setMenuFlag: (value: boolean) => void;
  // for export component below
  objectInstanceDisabled: boolean;
  objectTypeDisabled: boolean;
  disabledExportSource: boolean;
  disableDownloadAndExportTarget: boolean;
  setState: Updater<CreateComponentModelStates>;
  state: CreateComponentModelStates;
  associatedTypeToInstance: (
    selectedType: NodeSetEditorData,
    selectedTargetInstance: NodeSetEditorData
  ) => void;
  CreateNewTypeInstanceScroll: (name: string) => void;
  saveUpdateValidation: (stateData: CreateComponentModelStates) => boolean;
  loadTimerSet: () => void;
  findInstanceAssociatedWithTypeToBeDeleted: (
    typeElement: NodeSetEditorData
  ) => boolean;
  disassociateTypeExisting: (selectedTargetInstance: NodeSetEditorData) => void;
  associateTypeModalRenderer: (
    instanceOrTarget: string,
    selectedType: NodeSetEditorData,
    selectedTargetInstance: NodeSetEditorData
  ) => void;
}

const NComplexTree: React.FC<Props> = ({
  items,
  edId,
  copyTypeFunction,
  menuFlag,
  addNewTypeOrInstance,
  renameTargetItems,
  OnDropOfSource,
  deleteItems,
  disableDelete,
  clickButtonEvent,
  newFolderCreation,
  selectedItems,
  focusedItem,
  expandedItems,
  setSelectedItems,
  setFocusedItem,
  setExpandedItems,
  setMenuFlag,
  // for export component below
  objectInstanceDisabled,
  objectTypeDisabled,
  disabledExportSource,
  setState,
  state,
  disableDownloadAndExportTarget,
  associatedTypeToInstance,
  CreateNewTypeInstanceScroll,
  saveUpdateValidation,
  loadTimerSet,
  findInstanceAssociatedWithTypeToBeDeleted,
  disassociateTypeExisting,
  associateTypeModalRenderer,
}) => {
  const { errorMessage } = useSelector(nodeSetEditorValuesSelector);
  const [errorFlag, setErrorFlag] = useState(false);
  const [nodeError, setNodeError] = useState("");
  const [searchTargetTree, setSearchTargetTree] = useState("");
  const [searchSourceTree, setSearchSourceTree] = useState("");

  const [originalData, setOriginalData] = useState<
    Map<TreeItemIndex, NodeSetEditorData>
  >(new Map());

  const dispatch = useDispatch();

  const onFocusInput = () => {
    if (state.targetNodeMappedDataNodeKey) {
      let originalDataTemp: Map<TreeItemIndex, NodeSetEditorData> = new Map();
      state.targetNodeMappedDataNodeKey.forEach((value) => {
        originalDataTemp.set(value.index, value);
      });
      setOriginalData(originalDataTemp);
    }
  };

  useEffect(() => {
    if (errorMessage && errorMessage.length > 0) {
      setErrorFlag(true);
    } else {
      setErrorFlag(false);
    }
  }, [setErrorFlag, errorMessage]);

  const getNodeId = (id: any) => {
    setNodeError(id);
  };

  const getParentNodeIndexes = useCallback(
    (
      nodeId: string,
      data: Map<TreeItemIndex, NodeSetEditorData>,
      optionalParent?: TreeItemIndex[]
    ) => {
      let parentNodeItems: TreeItemIndex[] = optionalParent
        ? optionalParent
        : [];

      let item = data.get(nodeId);

      if (item) {
        parentNodeItems.unshift(item.index);

        if (
          item.ParentNodeId &&
          item.ParentNodeId !== REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
        ) {
          getParentNodeIndexes(item.ParentNodeId, data, parentNodeItems);
        } else if (
          item.ParentNodeId === REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
        ) {
          parentNodeItems.unshift("targetObjects");
        } else {
          parentNodeItems.unshift("targetTypes");
        }
      }
      return parentNodeItems;
    },
    []
  );

  useEffect(() => {
    if (nodeError) {
      let findIndex = state.targetNodeMappedDataNodeKey.get(nodeError)?.index;
      if (findIndex) {
        let expandedIndex = getParentNodeIndexes(
          nodeError,
          state.targetNodeMappedDataNodeKey
        );
        expandedIndex.push("target");
        setExpandedItems([...expandedItems, ...expandedIndex]);
        setSelectedItems([findIndex]);
        setFocusedItem(findIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nodeError,
    setSelectedItems,
    setFocusedItem,
    getParentNodeIndexes,
    setExpandedItems,
  ]);

  const searchFunctionMenuFunction = useCallback(
    (
      sourceOrTarget: string,
      e: React.MouseEvent<
        HTMLDivElement | HTMLButtonElement | HTMLAnchorElement,
        MouseEvent
      >,
      inputIndex: TreeItemIndex
    ) => {
      if (sourceOrTarget === "targetSearch") {
        e.stopPropagation();
        e.preventDefault();
        setMenuFlag(true);
        setSelectedItems([inputIndex]);
        setFocusedItem(inputIndex);
      }
    },
    [setFocusedItem, setMenuFlag, setSelectedItems]
  );

  const findValueInMapElement = useCallback(
    (
      compareToValue: string,
      data: Map<TreeItemIndex, NodeSetEditorData>
    ): Set<string> => {
      let foundValueObject: Set<string> = new Set();
      for (const [key, value] of data) {
        if (
          value?.DisplayName[0]?.Value.toLowerCase().includes(
            compareToValue.toLowerCase()
          )
        ) {
          foundValueObject.add(key as string);
        }
      }
      return foundValueObject;
    },
    []
  );

  const getParentIndexSearch = useCallback(
    (
      node: string,
      sourceOrTarget: string,
      sourceOrTargetMappedNodeKey: Map<TreeItemIndex, NodeSetEditorData>,
      optionalParent?: TreeItemIndex[]
    ) => {
      let newSetArray: TreeItemIndex[] = optionalParent ? optionalParent : [];
      const foundNode = sourceOrTargetMappedNodeKey.get(node);
      if (foundNode) {
        newSetArray.push(foundNode.index);
        if (
          foundNode.ParentNodeId &&
          foundNode.ParentNodeId !== REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
        ) {
          getParentIndexSearch(
            foundNode.ParentNodeId,
            sourceOrTarget,
            sourceOrTargetMappedNodeKey,
            newSetArray
          );
        } else if (
          foundNode.ParentNodeId === REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
        ) {
          if (sourceOrTarget === "targetSearch") {
            newSetArray.push("targetObjects");
            newSetArray.push("target");
          } else {
            newSetArray.push("objects");
            newSetArray.push("roots");
          }
        } else {
          if (sourceOrTarget === "targetSearch") {
            newSetArray.push("targetTypes");
            newSetArray.push("target");
          } else {
            newSetArray.push("types");
            newSetArray.push("roots");
          }
        }
      }

      return newSetArray;
    },
    []
  );
  const searchFunctionCallback = useCallback(
    (
      targetTreeInput: string,
      sourceOrTarget: string,
      sourceOrTargetTree: Map<TreeItemIndex, NodeSetEditorData>,
      sourceOrTargetMappedNodeKey: Map<TreeItemIndex, NodeSetEditorData>
    ) => {
      setSelectedItems([]);
      setFocusedItem("");

      const searchItemArray = findValueInMapElement(
        targetTreeInput,
        sourceOrTargetTree
      );
      let newExpandedArray: TreeItemIndex[] = [];
      const searchMap = new Map(sourceOrTargetTree);

      searchItemArray?.forEach((key) => {
        const get = sourceOrTargetTree.get(key);
        let searchFlag = false;
        if (get && get.NodeId) {
          searchFlag = true;
          searchMap.set(get.index, {
            ...get,
            data: (
              <div className="mainDivElement">
                <IconDisplay
                  folderIs={get.isFolder}
                  NodeClassName={get.NodeClass}
                />
                <div
                  onContextMenu={(e) => {
                    searchFunctionMenuFunction(sourceOrTarget, e, get.index);
                  }}
                  className="flexColumn searchElements"
                  id={get.index as string}
                  title={get.TitleTooltip}
                >
                  {get.modifiedName}
                </div>
                {sourceOrTarget === "targetSearch" && (
                  <Button
                    className="ellipsisClass"
                    icon="abb/more"
                    onClick={(e) => {
                      searchFunctionMenuFunction(sourceOrTarget, e, get.index);
                    }}
                    type="discreet-black"
                    sizeClass="small"
                  />
                )}
              </div>
            ),

            searchFlag,
          });
          let indexToExpand = getParentIndexSearch(
            get.NodeId,
            sourceOrTarget,
            sourceOrTargetMappedNodeKey
          );
          newExpandedArray = [...newExpandedArray, ...indexToExpand];
        }
      });
      const searchToKey = [...searchItemArray];
      const searchToKeyRev = [...searchItemArray].reverse();
      if (sourceOrTarget === "targetSearch") {
        setState((updateState) => {
          updateState.targetNodeMappedData = searchMap;
          updateState.uniqueKeys = searchToKey;
          updateState.uniqueKeysReverse = searchToKeyRev;
        });
      } else if (sourceOrTarget === "sourceSearch") {
        setState((updateState) => {
          updateState.sourceNodeItems = Array.from(searchMap.values());
          updateState.uniqueKeys = searchToKey;
          updateState.uniqueKeysReverse = searchToKeyRev;
        });
      }
      const expandedNew = [...expandedItems, ...newExpandedArray];
      if (searchItemArray.size === 1) {
        const getId = document.getElementById([...searchItemArray][0]);
        getId?.scrollIntoView(true);
      }
      if (expandedNew) {
        setExpandedItems(expandedNew);
      }
    },
    [
      expandedItems,
      findValueInMapElement,
      getParentIndexSearch,
      searchFunctionMenuFunction,
      setExpandedItems,
      setFocusedItem,
      setSelectedItems,
      setState,
    ]
  );

  const debounce = (
    timeout: number,
    fn: (...params: any[]) => any,
    immed: boolean = false
  ) => {
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    return function (this: any, ...args: any[]) {
      if (timer === undefined && immed) {
        fn.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => fn.apply(this, args), timeout);
      return timer;
    };
  };

  const searchSourceTreeQuery = (val: string) => {
    setSearchSourceTree(val);
  };
  const searchTargetTreeQuery = (val: string) => {
    setSearchTargetTree(val);
  };

  const debounceCall = useMemo(
    () =>
      debounce(
        1500,
        (
          targetTreeInput: string,
          sourceOrTarget: string,
          sourceOrTargetTree: Map<TreeItemIndex, NodeSetEditorData>,
          sourceOrTargetMappedNodeKey: Map<TreeItemIndex, NodeSetEditorData>
        ) => {
          if (
            targetTreeInput &&
            targetTreeInput.length > 0 &&
            sourceOrTarget &&
            sourceOrTargetTree &&
            sourceOrTargetTree.size > 0
          ) {
            searchFunctionCallback(
              targetTreeInput,
              sourceOrTarget,
              sourceOrTargetTree,
              sourceOrTargetMappedNodeKey
            );
          }
        }
      ),
    [searchFunctionCallback]
  );

  const closeNotification = () => {
    setErrorFlag(false);
  };
  const displayAssociatedType = () => {
    let associatedArrayTypes: any[] = [];
    const filtered = Array.from(state.targetNodeMappedData.values()).filter(
      (filterType) => filterType.NodeClass === variablesMappingTool.UAOBJECTTYPE
    );

    if (filtered && filtered.length > 0) {
      associatedArrayTypes = filtered.map((itemType) => (
        <MenuItem
          key={itemType.index}
          text={itemType.DisplayName[0].Value}
          onSelect={() => {
            dispatch(isLoadingFor(true));
            if (state.selectedItemsInTarget && itemType) {
              setTimeout(() => {
                if (
                  state.selectedItemsInTarget &&
                  state.selectedItemsInTarget.children &&
                  state.selectedItemsInTarget.children.length > 0
                ) {
                  associateTypeModalRenderer(
                    variablesMappingTool.OBJECT,
                    itemType,
                    state.selectedItemsInTarget
                  );
                } else {
                  associatedTypeToInstance(
                    itemType,
                    state.selectedItemsInTarget as NodeSetEditorData
                  );
                }
              }, 100);
            }
          }}
        />
      ));
    }
    return associatedArrayTypes;
  };

  useEffect(() => {
    if (searchSourceTree.length === 0 && state.searchSourceFlag) {
      setState((updateState) => {
        updateState.sourceNodeItems = Array.from(
          state.sourceNodeMappedData.values()
        );
        updateState.searchSourceFlag = false;
      });
    }
    if (searchTargetTree.length === 0 && state.searchTargetFlag) {
      setState((updateState) => {
        updateState.targetNodeMappedData = originalData;
        updateState.searchTargetFlag = false;
      });
    }
  }, [
    originalData,
    searchSourceTree.length,
    searchTargetTree.length,
    setState,
    state.searchSourceFlag,
    state.searchTargetFlag,
    state.sourceNodeMappedData,
  ]);

  const disabledAssociatedTypes = useCallback(() => {
    let disableOrNot = true;
    if (state.selectedItemsInTarget) {
      const treeTypes = state.treeItems["targetTypes"]?.children;

      const array = state.selectedItemsInTarget;
      if (
        treeTypes &&
        treeTypes.length > 0 &&
        array.NodeClass === variablesMappingTool.UAOBJECT &&
        !array.isFolder &&
        !array.isTypeObjectChild &&
        array.ParentNodeId === REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
      ) {
        disableOrNot = false;
      }
    }
    return disableOrNot;
  }, [state.selectedItemsInTarget, state.treeItems]);

  const getItemsName = useMemo(
    () => (item: TreeItem<any>) => {
      return item.data;
    },
    []
  );

  const disabledDissociatedType = () => {
    let disabled = true;
    if (
      state.selectedItemsInTarget &&
      state.selectedItemsInTarget?.NodeClass === variablesMappingTool.UAOBJECT
    ) {
      const objectRefFind = state.selectedItemsInTarget?.References.find(
        (s) => s.IsForward && s.ReferenceType === HAS_TYPE_DEFINITION
      )?.Value;
      if (objectRefFind && objectRefFind !== BASE_OBJECT_REFERENCE_VALUE) {
        const findRefAssociate =
          state.targetNodeMappedDataNodeKey.get(objectRefFind);
        if (findRefAssociate) {
          disabled = false;
        }
      }
    }
    return disabled;
  };

  return (
    <>
      {errorFlag && (
        <div className="error-notification">
          <div style={{ width: "100%" }}>
            <ValidationNotification
              getNodeId={getNodeId}
              closeNotification={closeNotification}
              stateValidation={state}
              setState={setState}
            />
          </div>
        </div>
      )}

      <ControlledTreeEnvironment
        canDragAndDrop={true}
        canDropOnItemWithChildren={true}
        canDropOnItemWithoutChildren={true}
        canSearch={false}
        canReorderItems={true}
        items={items}
        getItemTitle={(item) => getItemsName(item)}
        viewState={{
          // eslint-disable-next-line no-useless-computed-key
          ["tree-1"]: {
            focusedItem,
            expandedItems,
            selectedItems,
          },
          // eslint-disable-next-line no-useless-computed-key
          ["tree-2"]: {
            focusedItem,
            expandedItems,
            selectedItems,
          },
        }}
        onFocusItem={(item) => {
          setFocusedItem(item.index);
        }}
        onExpandItem={(item) => {
          setExpandedItems([...expandedItems, item.index]);
        }}
        onCollapseItem={(item) => {
          setExpandedItems(
            expandedItems.filter(
              (expandedItemIndex) => expandedItemIndex !== item.index
            )
          );
        }}
        onDrop={(itemsDrop, target) => {
          OnDropOfSource(itemsDrop, target);
        }}
        canRename={!disableDelete()}
        canDropAt={(_itemsDropAt, target) => target.treeId === "tree-2"}
        onRenameItem={(item, name, treeId) => {
          if (name?.trim().length <= 74) {
            renameTargetItems(item as NodeSetEditorData, name, treeId);
          } else {
            dispatch({
              type: "SHOW_NOTIFICATION",
              notificationType: "alarm",
              message: "Max. 75 characters allowed",
            });
          }
        }}
        onSelectItems={(itemSelect) => {
          setSelectedItems(itemSelect);
        }}
        canDrag={() => true}
      >
        <div className="CardContainerClassLeft">
          <div style={{ display: "flex" }}>
            <b style={{ flexGrow: "1" }}>
              {variablesMappingTool.SourceNodeSet}
            </b>
            <div className="headerClass">
              <ExportImportComp
                idString={edId}
                importApi
                copyTypeFunction={copyTypeFunction}
                selectedItems={selectedItems}
                objectInstanceDisabled={objectInstanceDisabled}
                objectTypeDisabled={objectTypeDisabled}
                disabledExportSource={disabledExportSource}
                state={state}
                setState={setState}
                saveUpdateValidation={saveUpdateValidation}
              />
            </div>
          </div>

          <div className="treeElementClass">
            <Input
              style={{
                padding: "8px",
                flex: "1",
              }}
              placeholder="Search..."
              icon={"abb/search"}
              dataType="text"
              value={searchSourceTree}
              onValueChange={(val) => {
                searchSourceTreeQuery(val);

                setState((draft) => {
                  draft.searchSourceFlag = true;
                });
                debounceCall(
                  val,
                  "sourceSearch",
                  state.sourceNodeMappedData,
                  state.sourceNodeMappedDataNodeKey
                );
              }}
            />
            {/* </div> */}
            <Tree treeId="tree-1" rootItem="root" treeLabel="Tree 1" />
          </div>
          {/* </div> */}
        </div>
        <div className="CardContainerClassRight">
          <div style={{ display: "flex" }}>
            <b style={{ flexGrow: "1" }}>
              {variablesMappingTool.TargetNodeSet}
            </b>
            <div className="headerClass">
              <ExportImportComp
                idString={edId}
                importApi={false}
                copyTypeFunction={copyTypeFunction}
                selectedItems={selectedItems}
                disableDownloadAndExportTarget={disableDownloadAndExportTarget}
                state={state}
                setState={setState}
                saveUpdateValidation={saveUpdateValidation}
              />
            </div>
          </div>
          {menuFlag && (
            <div className="rightMenuItems">
              <AppTopNavi>
                <AppTopNaviItem>
                  <Menu>
                    <MenuItem
                      text="Folder"
                      itemId="item-0"
                      icon="abb/folder-new"
                      onSelect={() => {
                        newFolderCreation(selectedItems);
                      }}
                      disabled={
                        selectedItems[0] === "targetObjects" ||
                        selectedItems[0] === "targetTypes" ||
                        state.selectedItemsInTarget?.NodeClass ===
                          variablesMappingTool.UAVARIABLE
                      }
                    />
                    <MenuItemDivider />
                    <MenuItem
                      text="Instance"
                      itemId="item-0"
                      icon="abb/template-new"
                      onSelect={() => {
                        addNewTypeOrInstance(
                          variablesMappingTool.OBJECT,
                          CreateNewTypeInstanceScroll
                        );
                      }}
                      disabled={selectedItems[0] !== "targetObjects"}
                    />
                    <MenuItemDivider />
                    <MenuItem
                      text="Type"
                      itemId="item-0"
                      icon="abb/template-new"
                      onSelect={() => {
                        addNewTypeOrInstance(
                          variablesMappingTool.TYPE,
                          CreateNewTypeInstanceScroll
                        );
                      }}
                      disabled={selectedItems[0] !== "targetTypes"}
                    />
                    <MenuItemDivider />
                    <MenuItem
                      text="Delete"
                      itemId="item-0"
                      icon="abb/trash"
                      onSelect={() => {
                        dispatch(isLoadingFor(true));
                        loadTimerSet();
                        if (
                          state.selectedItemsInTarget &&
                          findInstanceAssociatedWithTypeToBeDeleted(
                            state.selectedItemsInTarget
                          )
                        ) {
                          setTimeout(() => {
                            deleteItems();
                          }, 50);
                        }
                      }}
                      disabled={disableDelete()}
                    />
                    <MenuItemDivider />
                    <MenuItem
                      text="Rename"
                      itemId="item-0"
                      icon="abb/edit"
                      onSelect={() => {
                        clickButtonEvent();
                      }}
                      disabled={disableDelete()}
                    />
                    <MenuItemDivider />
                    <SubMenu
                      text={"Associate Type"}
                      icon="abb/shuffle"
                      disabled={
                        disabledAssociatedTypes() ||
                        selectedItems[0] === "targetObjects" ||
                        selectedItems[0] === "targetTypes"
                      }
                    >
                      <MenuItem
                        key={"baseObjectType"}
                        text={"Base Object Type"}
                        disabled={disabledDissociatedType()}
                        onSelect={() => {
                          dispatch(isLoadingFor(true));
                          if (state.selectedItemsInTarget) {
                            setTimeout(() => {
                              disassociateTypeExisting(
                                state.selectedItemsInTarget as NodeSetEditorData
                              );
                            }, 100);
                          }
                        }}
                      />
                      {displayAssociatedType()}
                    </SubMenu>
                  </Menu>
                </AppTopNaviItem>
              </AppTopNavi>
            </div>
          )}

          <div className="treeElementClass">
            <Input
              onGotFocus={onFocusInput}
              style={{
                padding: "8px",
                flex: "1",
              }}
              placeholder="Search..."
              icon={"abb/search"}
              dataType="text"
              value={searchTargetTree}
              onValueChange={(val) => {
                searchTargetTreeQuery(val);

                setState((draft) => {
                  draft.searchTargetFlag = true;
                });
                debounceCall(
                  val,
                  "targetSearch",
                  originalData,
                  state.targetNodeMappedDataNodeKey
                );
              }}
            />

            <Tree treeId="tree-2" rootItem="targetRoot" treeLabel="Tree 2" />
          </div>
        </div>
      </ControlledTreeEnvironment>
    </>
  );
};

export default NComplexTree;
