import {
  getErrorMessage,
  setCommonHeaders,
} from "./../../../Utils/HelperFunctions";
import * as serverTypes from "../../Servers/Type/TypeServers";
import { put, takeEvery } from "redux-saga/effects";
import * as browseTypes from "../Type/TypeBrowse";
import {
  formatConfiguredData,
  getFormattedBrowsePageData,
  formatSelectedMethodData,
  formatEnabledMethodData,
} from "../DataFormatter/BrowseDataFormatter";
import * as apiEndPoints from "../../../Utils/ApiEndPoints";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import axios from "axios";
import * as uuid from "uuid";

function* fetchBrowseTreeData(action: browseTypes.IGetBrowseTreeData): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.get<any>(
      !envSettings.isOfflineMode
        ? `${apiEndPoints.getBrowseData}/${action.selectedServerId}/browse`
        : `${apiEndPoints.getBrowseData}`
    );
    const receivedData = !envSettings.isOfflineMode
      ? { ...fetchedData.data }
      : [...fetchedData.data.data];
    const treeData = [...receivedData.rootNodes];
    const [flattenedNodeData, formattedNodeTreeData] =
      getFormattedBrowsePageData([...treeData], "%i84%", 1);
    yield put({
      type: "RECEIVED_BROWSE_TREE_DATA",
      treeData,
      flattenedNodeData,
      formattedNodeTreeData,
      typeVersion: receivedData.version ? receivedData.version : 1,
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}
function* fetchSelectedServerForBrowse(
  action: browseTypes.IGetSelectedServerForBrowse
): any {
  try {
    setCommonHeaders();
    let fetchedData;
    let receivedData: any;

    if(envSettings.isOfflineMode){
      fetchedData = yield axios.get<any>(
        `${apiEndPoints.getServers}`
      );
      receivedData = fetchedData.data.data.filter( (server:any) => server.id === action.serverId)[0];
    }else{
      
     fetchedData = yield axios.get<any>(
      `${apiEndPoints.getCurrentServer}/${action.serverId}`
    );
    receivedData = !envSettings.isOfflineMode
      ? { ...fetchedData.data }
      : { ...fetchedData.data.data };
    }

    
    yield put({
      type: "RECEIVED_SELECTED_SERVER_FOR_BROWSE",
      payload: {
        ...receivedData,
        edgeId: action.edgeId,
        uaModuleId: action.uaModuleId,
      },
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

function* fetchDataBinding(action: browseTypes.IGetDataBindingResponse): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.get<any>(`${apiEndPoints.getDataBinding}`, {
      params: {
        nodeId: action.selectedNode.nodeId,
        endpointId: action.serverId,
      },
    });
    const receivedData: browseTypes.IDataBindingResponse =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    yield put({
      type: "RECEIVED_DATA_BINDING_RESPONSE",
      dataBindingData: { ...receivedData },
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else if (e.response && `${e.response.status}` === "404") {
      yield put({
        type: "RECEIVED_DATA_BINDING_RESPONSE",
        dataBindingData: {},
      });
      yield put({
        type: "GET_TYPE_INFO",
        selectedNode: { ...action.selectedNode },
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

function* fetchTypeInfo(action: browseTypes.IGetTypeInfo): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.post<any>(`${apiEndPoints.getTypeInfo}`, {
      nodeTypeId: action.selectedNode.nodeTypeId,
      nodeTypeName: action.selectedNode.nodeTypeName,
    });
    const receivedData: browseTypes.ITypeInformation =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    yield put({
      type: "RECEIVED_TYPE_INFO",
      typeInfo: { ...receivedData },
    });
  } catch (e) {
    console.log("error", e.response);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    }
    if (e.response && `${e.response.status}` === "404") {
      yield put({
        type: "RECEIVED_TYPE_INFO",
        typeInfo: {
          typeId: "notfound",
          name: "notfound",
          variables: {},
        },
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

function* fetchDesiredType(action: browseTypes.IGetDesiredType): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.get<any>(
      `${apiEndPoints.getDesiredType}/${action.desiredType}`
    );
    const receivedData: browseTypes.IDesiredTypeData =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    const selectedVariableAray = action.selectedVariables.map((item) => {
      return item.displayName;
    });
    const desiredTypeVariableArray = receivedData.variables
      ? Object.keys(receivedData.variables)
      : [];

    const uniqCurrentlySelectedVariableArray = selectedVariableAray.filter(
      (item, pos) => {
        return selectedVariableAray.indexOf(item) === pos;
      }
    );
    const uniqTypeVariableArray = desiredTypeVariableArray.filter(
      (item, pos) => {
        return desiredTypeVariableArray.indexOf(item) === pos;
      }
    );
    let isTypeVariableMatching = true;
    if (
      uniqCurrentlySelectedVariableArray.length > uniqTypeVariableArray.length
    ) {
      isTypeVariableMatching = false;
    } else {
      uniqCurrentlySelectedVariableArray.map((eachVar) => {
        if (uniqTypeVariableArray.indexOf(eachVar) < 0) {
          isTypeVariableMatching = false;
        }
      });
    }
    if (isTypeVariableMatching) {
      yield put({
        type: "HANDLE_FORM_COLLECT",
        requestData: { ...action.collectData },
      });
    } else {
      let truncatedString = receivedData.typeId;
      const splittedVesrion = receivedData.version
        ? receivedData.version.split(".")
        : [""];
      truncatedString = truncatedString.replace(/\.tenantId\..*/, "");

      let message = `A type with name "${truncatedString}" is present with different variables than selected.`;
      yield put({
        type: "RECEIVED_DESIRED_TYPE",
        showDesiredTypeModal: true,
        desiredTypeMessage: message,
      });
    }
  } catch (e) {
    console.log("error", e.response);
    let message = `A new type "${action.desiredType}" will be created with the selected variables.`;
    yield put({
      type: "RECEIVED_DESIRED_TYPE",
      showDesiredTypeModal: true,
      desiredTypeMessage: message,
    });
  }
}

function* handleCollect(action: browseTypes.ICollectForm): any {
  try {
    setCommonHeaders();
    let newId = uuid.v4();
    const truncatedSamplingInterval =
      action.requestData.samplingInterval.replace(/^0+/g, "");
    const desiredTypeCopy = action.requestData.desiredType;
    const objectBinding: browseTypes.ICollectObjectBindingsRequestData = {
      variableBindings: action.requestData.variable,
      methodBindings: action.requestData.method,
      uaModuleId: action.requestData.uaModuleId,
      id: newId,
      name: action.requestData.dataBindingName,
      nodeId: action.requestData.selectedNode.nodeId,
      nodeTypeId: action.requestData.selectedNode.nodeTypeId,
      nodeTypeName: action.requestData.selectedNode.nodeTypeName,
      nodeName: action.requestData.selectedNode.displayName,
      endpointId: action.requestData.serverId,
      isActive: true,
      relatedObjectId: newId,
      samplingInterval: (truncatedSamplingInterval as any) * 1,
      parent:
        Object.keys(action.requestData.parentObj).length > 0
          ? { ...action.requestData.parentObj }
          : null,
      desiredTypeId:
        desiredTypeCopy.replace(/\s/g, "").length !== 0
          ? action.requestData.desiredType
          : null,
    };
    const fetchedData = yield axios.post<any>(
      `${environmentSettings.webapiUrl}/DataBindings`,
      {
        ...objectBinding,
      }
    );
    const receivedData: browseTypes.IDataBindingResponse =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    yield put({
      type: "ON_COLLECT_SUCCESS",
    });

    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `Added object bindings for node ${action.requestData.selectedNode.displayName} (${action.requestData.selectedNode.nodeId})`,
    });
    yield put({
      type: "GET_DATA_BINDING_RESPONSE",
      serverId: action.requestData.serverId,
      selectedNode: action.requestData.selectedNode,
    });
    yield put({
      type: "GET_CONFIGURED_DATA",
      selectedUaModuleId: action.requestData.uaModuleId,
      selectedServerId: action.requestData.serverId,
      edgeId: action.requestData.edgeId,
    });
    yield put({
      type: "GET_ENABLED_METHOD_DATA",
      selectedUaModuleId: action.requestData.uaModuleId,
      selectedServerId: action.requestData.serverId,
      edgeId: action.requestData.edgeId,
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      // const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Activation/ Modification failed. Check the input data and try again. Please contact your system administrator if the problem persists.",
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

function* handleModify(action: browseTypes.IModifyForm): any {
  try {
    setCommonHeaders();
    let newId = uuid.v4();
    const truncatedSamplingInterval =
      action.requestData.samplingInterval.replace(/^0+/g, "");
    const objectBinding: browseTypes.ICollectObjectBindingsRequestData = {
      variableBindings: action.requestData.variable,
      methodBindings: action.requestData.method,
      uaModuleId: action.requestData.uaModuleId,
      id: newId,
      name: action.requestData.dataBindingName,
      nodeId: action.requestData.selectedNode.nodeId,
      nodeTypeId: action.requestData.selectedNode.nodeTypeId,
      nodeTypeName: action.requestData.selectedNode.nodeTypeName,
      nodeName: action.requestData.selectedNode.displayName,
      endpointId: action.requestData.serverId,
      isActive: true,
      relatedObjectId: newId,
      samplingInterval: (truncatedSamplingInterval as any) * 1,
      parent:
        Object.keys(action.requestData.parentObj).length > 0
          ? { ...action.requestData.parentObj }
          : null,
    };
    const fetchedData = yield axios.put<any>(
      `${environmentSettings.webapiUrl}/DataBindings`,
      {
        ...objectBinding,
      }
    );
    const receivedData: browseTypes.IDataBindingResponse =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    yield put({
      type: "ON_MODIFY_SUCCESS",
    });
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `Updated object bindings for node ${action.requestData.selectedNode.displayName} (${action.requestData.selectedNode.nodeId})`,
    });
    yield put({
      type: "GET_DATA_BINDING_RESPONSE",
      serverId: action.requestData.serverId,
      selectedNode: action.requestData.selectedNode,
    });
    yield put({
      type: "GET_CONFIGURED_DATA",
      selectedUaModuleId: action.requestData.uaModuleId,
      selectedServerId: action.requestData.serverId,
      edgeId: action.requestData.edgeId,
    });
    yield put({
      type: "GET_ENABLED_METHOD_DATA",
      selectedUaModuleId: action.requestData.uaModuleId,
      selectedServerId: action.requestData.serverId,
      edgeId: action.requestData.edgeId,
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}
function* fetchConfiguredData(action: browseTypes.IGetConfiguredData): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.get<any>(
      `${apiEndPoints.getConfiguredData}/${action.selectedServerId}/variableBindings`
    );
    const receivedData: browseTypes.IConfiguredDataBinding[] =
      !envSettings.isOfflineMode
        ? [...fetchedData.data]
        : [...fetchedData.data.data];
    const formattedConfiguredData = formatConfiguredData([...receivedData]);
    yield put({
      type: "RECEIVED_CONFIGURED_DATA",
      rawConfiguredData: [...receivedData],
      formattedConfiguredData: [...formattedConfiguredData],
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}
function* updateConfiguredData(action: browseTypes.IUpdateConfiguredData): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.put<any>(
      `${apiEndPoints.updateConfiguredData}/${action.serverId}/variableBindings`,
      [...action.selectedConfiguredData]
    );
    const receivedData: browseTypes.IDataBindingResponse =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `${action.selectedConfiguredData.length} data bindings updated`,
    });
    yield put({
      type: "ON_SUCCESS_BROWSE",
    });
    yield put({
      type: "GET_CONFIGURED_DATA",
      selectedUaModuleId: action.uaModuleId,
      selectedServerId: action.serverId,
      edgeId: action.edgeId,
    });
    yield put({
      type: "GET_ENABLED_METHOD_DATA",
      selectedUaModuleId: action.uaModuleId,
      selectedServerId: action.serverId,
      edgeId: action.edgeId,
    });
    if (action.selectedNode && action.selectedNode.nodeId) {
      yield put({
        type: "GET_DATA_BINDING_RESPONSE",
        serverId: action.serverId,
        selectedNode: { ...action.selectedNode },
      });
    }
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Error updating data bindings",
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

function* deleteConfiguredData(action: browseTypes.IDeleteConfiguredData): any {
  const deleteIds: string[] = action.selectedConfiguredData.map((item) => {
    return item.id;
  });
  try {
    setCommonHeaders();
    const fetchedData = yield axios.post<any>(
      `${apiEndPoints.updateConfiguredData}/${action.serverId}/variableBindings/delete`,
      [...deleteIds]
    );
    const receivedData: browseTypes.IDataBindingResponse =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `${action.selectedConfiguredData.length} data bindings deleted`,
    });
    yield put({
      type: "ON_SUCCESS_BROWSE",
    });
    yield put({
      type: "GET_CONFIGURED_DATA",
      selectedUaModuleId: action.uaModuleId,
      selectedServerId: action.serverId,
      edgeId: action.edgeId,
    });
    yield put({
      type: "GET_ENABLED_METHOD_DATA",
      selectedUaModuleId: action.uaModuleId,
      selectedServerId: action.serverId,
      edgeId: action.edgeId,
    });
    if (action.selectedNode && action.selectedNode.nodeId) {
      yield put({
        type: "GET_DATA_BINDING_RESPONSE",
        serverId: action.serverId,
        selectedNode: { ...action.selectedNode },
      });
    }
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Error deleting data bindings",
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

function* fetchEnabledMethodData(
  action: browseTypes.IGetEnabledMethodData
): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.get<any>(
      `${apiEndPoints.getEnabledMethodData}/${action.selectedServerId}/methodBindings`
    );
    const receivedData: browseTypes.IEnabledMethodObj[] =
      !envSettings.isOfflineMode
        ? [...fetchedData.data]
        : [...fetchedData.data.data];
    const [formattedEnabledMethodsData, flattenedMethodData] =
      formatEnabledMethodData([...receivedData]);
    yield put({
      type: "RECEIVED_ENABLED_METHOD_DATA",
      rawEnabledMethodData: [...receivedData],
      formattedEnabledMethodData: [...formattedEnabledMethodsData],
      flattenedMethodData,
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

function* disableMethods(action: browseTypes.IDisableMethods): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.delete<any>(
      `${apiEndPoints.getEnabledMethodData}/${action.payload[0].endpointId}/methodBindings`,
      {
        data: [...action.payload],
      }
    );
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `Disabled methods.`,
    });
    yield put({
      type: "ON_SUCCESS_BROWSE",
      info: "disablesuccess",
    });
    yield put({
      type: "GET_ENABLED_METHOD_DATA",
      selectedUaModuleId: action.uaModuleId,
      selectedServerId: action.serverId,
      edgeId: action.edgeId,
    });
    if (action.selectedNode && action.selectedNode.nodeId) {
      yield put({
        type: "GET_DATA_BINDING_RESPONSE",
        serverId: action.serverId,
        selectedNode: { ...action.selectedNode },
      });
    }
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    }
    yield put({
      type: "ON_FAIL_BROWSE",
    });
  }
}

export default function* actionWatcherBrowse() {
  yield takeEvery("GET_BROWSE_TREE_DATA", fetchBrowseTreeData);
  yield takeEvery(
    "GET_SELECTED_SERVER_FOR_BROWSE",
    fetchSelectedServerForBrowse
  );
  yield takeEvery("GET_DATA_BINDING_RESPONSE", fetchDataBinding);
  yield takeEvery("GET_TYPE_INFO", fetchTypeInfo);
  yield takeEvery("GET_DESIRED_TYPE", fetchDesiredType);
  yield takeEvery("HANDLE_FORM_COLLECT", handleCollect);
  yield takeEvery("HANDLE_FORM_MODIFY", handleModify);
  yield takeEvery("GET_CONFIGURED_DATA", fetchConfiguredData);
  yield takeEvery("UPDATE_CONFIGURED_DATA_PUBLISHING", updateConfiguredData);
  yield takeEvery("DELETE_CONFIGURED_DATA_PUBLISHING", deleteConfiguredData);
  yield takeEvery("GET_ENABLED_METHOD_DATA", fetchEnabledMethodData);
  yield takeEvery("DISABLE_METHODS", disableMethods);
}
