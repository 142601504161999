import { AxiosResponse } from "axios";
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from "redux-saga/effects";
import {
  ErrorNotification,
  NodeSetEditorByIdResponseData,
  NodeSetEditorByIdResponseObject,
  NodeSetEditorData,
  NodeSetEditorResponseObject,
  SaveResponse,
  SaveTargetNodeData,
} from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import {
  isLoadingFor,
  FetchNodeSetDataByIdActionType,
  getNodeSetEditorByIdDataList,
  SaveTargetNodeSetDataType,
  validationToastMessagesReducer,
  ImportCsvDataActionType,
  importTargetCSVDataList,
  SAVE_TARGET_NODE_SET_DATA,
  FETCH_NODE_BY_ID_SET_DATA,
  IMPORT_TARGET_CSV_DATA,
} from "../../Action/nodeTreeStore";
import {
  getNameSpaceByIDData,
  saveTargetNodeSetData,
  importCSVFileDataService,
} from "../../ServicesAPIs/nodeTreeStructureServices/nodeTreeStructureService";
import { IShowNotification } from "../../../../Common/Type/TypeCommon";

function* showErrorMessage(error: any) {
  yield put(isLoadingFor(false));
  const fiveHundred = error?.response?.status === 500 ? true : false;
  if (!fiveHundred) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.length > 0
    ) {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: error.response.data.errors[0]?.message,
      });
    } else {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Unknown error",
      });
    }
  } else {
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "alarm",
      message: error?.response?.data,
    });
  }
}

function* getNodeSetEditorByIdDataSaga(action: FetchNodeSetDataByIdActionType):
  | Generator<
      | PutEffect<{
          payload:
            | NodeSetEditorByIdResponseObject
            | boolean
            | string[]
            | string
            | NodeSetEditorByIdResponseData
            | IShowNotification;
          type: string;
        }>
      | CallEffect<AxiosResponse<NodeSetEditorByIdResponseObject>>,
      void,
      NodeSetEditorByIdResponseObject
    >
  | any {
  try {
    yield put(isLoadingFor(true));
    const response = yield call(getNameSpaceByIDData, action.payload);
    const dataRes = response.data;
    if (dataRes) {
      yield put(getNodeSetEditorByIdDataList(dataRes));
    }
    yield put(isLoadingFor(false));
  } catch (error: any) {
    yield* showErrorMessage(error);
  }
}

function* saveTargetNodeSetDataSaga(action: SaveTargetNodeSetDataType):
  | Generator<
      | PutEffect<{
          payload:
            | SaveTargetNodeData[]
            | boolean
            | string[]
            | string
            | ErrorNotification[]
            | SaveResponse;
          type: string;
        }>
      | CallEffect<AxiosResponse<SaveResponse>>,
      void,
      SaveResponse
    >
  | any {
  try {
    yield put(isLoadingFor(true));
    const response = yield call(saveTargetNodeSetData, action.payload);
    const dataRes = response;

    if (dataRes.status === 200) {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "success",
        message: "Target Nodeset is successfully saved.",
      });
      action.callBack();
    }
    yield put(isLoadingFor(false));
  } catch (error: any) {
    yield put(isLoadingFor(false));

    const fiveHundred = error?.response?.status === 500 ? true : false;
    if (!fiveHundred) {
      yield put(validationToastMessagesReducer(error?.response?.data?.errors));
    } else {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: error?.response?.data,
      });
    }
  }
}

function* importCsvDataSaga(action: ImportCsvDataActionType):
  | Generator<
      | PutEffect<{
          payload:
            | NodeSetEditorResponseObject
            | boolean
            | NodeSetEditorData[]
            | string[]
            | string;
          type: string;
        }>
      | CallEffect<AxiosResponse<NodeSetEditorResponseObject>>,
      void,
      NodeSetEditorResponseObject
    >
  | any {
  try {
    yield put(isLoadingFor(true));
    const response = yield call(importCSVFileDataService, action.payload);
    const dataRes = response.data;

    if (dataRes) {
      yield put(importTargetCSVDataList(dataRes));
    }
    yield put(isLoadingFor(false));
  } catch (error: any) {
    yield* showErrorMessage(error);
  }
}

export default function* nodeSetEditorSagaFunction(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([takeLatest(SAVE_TARGET_NODE_SET_DATA, saveTargetNodeSetDataSaga)]);
  yield all([
    takeLatest(FETCH_NODE_BY_ID_SET_DATA, getNodeSetEditorByIdDataSaga),
    takeLatest(IMPORT_TARGET_CSV_DATA, importCsvDataSaga),
  ]);
}
