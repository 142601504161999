import axios, { AxiosResponse } from "axios";
import {
  NodeSetEditorResponseObject,
  NodeSetEditorByIdResponseObject,
  SaveTargetNodeData,
  SaveResponse,
} from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import { mappingToolAPI } from "../../../../Utils/ConstantsMappingTool";

axios.defaults.baseURL = mappingToolAPI.localhost;

export const fetchNodeSetImportXMLData = (req: {
  id: string;
  document: FormData;
  append: boolean | null;
}): Promise<AxiosResponse<NodeSetEditorResponseObject>> => {
  const callUrl = `/OpcServers/${req.id}/source/import/xml?append=${req.append}`;

  return axios.post<NodeSetEditorResponseObject>(callUrl, req.document, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
//getNameSpaceID

export const getNameSpaceByIDData = (
  namespaceId: string
): Promise<AxiosResponse<NodeSetEditorByIdResponseObject>> => {
  return axios.get<NodeSetEditorByIdResponseObject>(
    `/OpcServers/${namespaceId}/nodesets`
  );
};

export const downloadTargetXmlApi = (id: string, fileType: string) => {
  return axios.get(`/CustomNodeset/${id}/${fileType}`);
};

export const saveTargetNodeSetData = (request: {
  id: string;
  data: SaveTargetNodeData[];
  saveType: number;
  name: string;
  uri: string;
  version: string;
}): Promise<AxiosResponse<SaveResponse>> => {
  return axios.post<SaveResponse>(
    // `/OpcServers/${request.id}/target?type=${request.saveType}`,
    `/OpcServers/${request.id}/configureTargetNodeSetFiles?type=${request.saveType}&name=${request.name}&uri=${request.uri}&versionType=${request.version}`,
    request.data
  );
};
// import csv
export const importCSVFileDataService = (req: {
  id: string;
  document: FormData;
}): Promise<AxiosResponse<NodeSetEditorResponseObject>> => {
  const callUrl = `OpcServers/${req.id}/nodesets/target/transform`;

  return axios.post<NodeSetEditorResponseObject>(callUrl, req.document, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
