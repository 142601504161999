import { Button } from "@abb/abb-common-ux-react";
import React from "react";
import { IconDisplay } from "./IconDisplay";
import "./SourceTree.css";

const ItemDisplayElement = ({
  index,
  nodeClass,
  folderOrNot,
  titleTooltip,
  name,
  setSelectedFocusedItem,
  className,
  value,
}: any) => {
  return (
    <div className="mainDivElement">
      {nodeClass && (
        <IconDisplay folderIs={folderOrNot} NodeClassName={nodeClass} />
      )}
      <div
        lang="de"
        onContextMenu={(e) => {
          setSelectedFocusedItem(e, index);
        }}
        className={className ? className : "flexColumn"}
        id={index as string}
        title={titleTooltip}
      >
        {name}
      </div>
      <Button
        className="ellipsisClass"
        icon="abb/more"
        onClick={(e) => {
          setSelectedFocusedItem(e, index);
        }}
        type="discreet-black"
        sizeClass="small"
      />{" "}
    </div>
  );
};

export default ItemDisplayElement;
