import { NodeSetEditorData } from "../Models/NodeSetEditorModels/NodeSetEditorModels";
import { REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE } from "../Views/Browse/Components/TreeComponent/TreeComponentSourceNode";
import { variablesMappingTool } from "./ConstantsMappingTool";

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    const cryptNum = crypto.getRandomValues(new Uint16Array(1));
    const cryptNumber = cryptNum[0];

    const cryptNumberLength = cryptNumber?.toString()?.length;
    let numberReturn: number = 0.96432;

    if (cryptNumber) {
      numberReturn = cryptNumber / 10 ** cryptNumberLength;
    }

    result += characters.charAt(Math.floor(numberReturn * charactersLength));
  }
  return result;
};

let returnValueFindParent = true;

export const recursiveFunctionToFindParent = (
  value: NodeSetEditorData,
  nodeDataMapSourceNodeKey: Map<string, NodeSetEditorData>
) => {
  if (
    value.NodeClass !== variablesMappingTool.UAOBJECTTYPE &&
    value.ParentNodeId &&
    value.ParentNodeId !== REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
  ) {
    const gotParent = nodeDataMapSourceNodeKey.get(value.ParentNodeId);
    if (!gotParent) {
      returnValueFindParent = false;
    } else if (gotParent) {
      recursiveFunctionToFindParent(gotParent, nodeDataMapSourceNodeKey);
    }
  }
  return returnValueFindParent;
};

export const excludeWrongData = (
  value: NodeSetEditorData,
  nodeDataMapSourceNodeKey: Map<string, NodeSetEditorData>
) => {
  let returnValue = true;
  if (value.NodeClass !== variablesMappingTool.UAOBJECTTYPE) {
    if (!value.ParentNodeId) {
      returnValue = false;
    } else if (
      value.ParentNodeId &&
      value.ParentNodeId !== REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
    ) {
      const gotParent = nodeDataMapSourceNodeKey.get(value.ParentNodeId);
      if (!gotParent) {
        returnValue = false;
      } else if (gotParent) {
        const valueIfParent = recursiveFunctionToFindParent(
          gotParent,
          nodeDataMapSourceNodeKey
        );

        if (!valueIfParent) {
          returnValue = false;
        }
      }
    }
  }
  return returnValue;
};
export const setOfRandomNodeIds = new Set();

export function formattedNodeDataIndexes(
  nodeData: NodeSetEditorData[],
  nodeType: string
) {
  setOfRandomNodeIds.clear();

  let formattedNodeDataTemp: NodeSetEditorData[] = [];
  formattedNodeDataTemp = nodeData.map((data) => {
    const randomString = generateRandomString(8);

    if (nodeType === "target") {
      let checkObjectAsTypeParent = false;
      setOfRandomNodeIds.add(data.NodeId);
      if (data.NodeClass === variablesMappingTool.UAOBJECTTYPE) {
        checkObjectAsTypeParent = true;
      }

      return {
        ...data,
        index: "tar" + randomString,
        isTypeObjectChild: checkObjectAsTypeParent,
      };
    } else {
      return {
        ...data,
        index: randomString,
      };
    }
  });
  return formattedNodeDataTemp;
}
let returnedId = "";

export const uniqueNodeIdGenerator = () => {
  const cryptNum = crypto.getRandomValues(new Uint32Array(1));

  const nodeIdNameStart = "ns=1;i=";
  let newNodeIdChildren: string = nodeIdNameStart + cryptNum[0];

  if (!setOfRandomNodeIds.has(newNodeIdChildren)) {
    setOfRandomNodeIds.add(newNodeIdChildren);

    returnedId = newNodeIdChildren;
  } else {
    uniqueNodeIdGenerator();
  }
  return returnedId;
};

export const getNumFromStr = (str: any) => {
  const extractedNum = str.match(/\d+/);
  if (extractedNum && extractedNum.length > 0) return Number(extractedNum[0]);
};

export const nextNumber = (namesOfFolders: number[]) => {
  let numbersObj = 1;
  namesOfFolders.sort(function (a, b) {
    return a - b;
  });
  namesOfFolders.forEach((xx) => {
    if (xx === numbersObj) {
      numbersObj++;
    }
  });
  return numbersObj;
};
