import * as commonTypes from "../Type/TypeCommon";

export const showNotification = (
  notificationType: "success" | "info" | "warn" | "alarm",
  message: string,
  closeDuration?: number
): commonTypes.IShowNotification => {
  return {
    type: "SHOW_NOTIFICATION",
    notificationType,
    message,
    closeDuration,
  };
};

export const closeNotification = (): commonTypes.ICloseNotification => {
  return {
    type: "CLOSE_NOTIFICATION",
  };
};
export const changePreference = (
  theme: "light" | "dark"
): commonTypes.IChangePreference => {
  return {
    type: "CHANGE_PREFERENCE",
    selectedTheme: theme,
  };
};
export const getPreference = (): commonTypes.IGetPreference => {
  return {
    type: "GET_PREFERENCE",
  };
};

export const getPublicKey = (): commonTypes.IGetPublicKey => {
  return {
    type: "GET_PUBLIC_KEY",
  };
};

