import React from "react";
import "./ReleaseNotes.css";
import {
  AppContainer,
  AppMainContent,
  Icon,
  Table,
} from "@abb/abb-common-ux-react";
import { useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import { envSettings } from "../../../EnviornmentSettings/Settings";

interface IReleaseNotesProps {
  showReleaseNotes: boolean;
  onCloseReleaseNotes: () => void;
}
const ReleaseNotes = (props: IReleaseNotesProps) => {
  const handleEscape = (e: any) => {
    if (e.keyCode === 27) {
      props.onCloseReleaseNotes();
    }
  };
  const commonState = useSelector((state: IState) => state.commonState);
  React.useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);
  const currentTime = new Date();
  let formattedDate = currentTime.toLocaleString();
  formattedDate = formattedDate.replace(/\//g, "-");
  formattedDate = formattedDate.replace(/,/g, "");
  return (
    <div className="release-notes-wrap">
      <div className="notes-heading">
        <span>Release Notes </span>
        <div>
          <Icon
            name="abb/close"
            className="notes-close-icon"
            onClick={() => props.onCloseReleaseNotes()}
          />
        </div>
      </div>
      <AppContainer
        className={`views-container ${
          commonState.theme === "light"
            ? "light-view-container"
            : "dark-view-container"
        }`}
        theme={commonState.theme}
      >
        <AppMainContent>
          <div className="release-note-container">
            <div className="release-note-content">
              <p />
              <div className="release-notes-header-wrap">
                <div className="release-note-version">{`VERSION  2.0.0.0`}</div>
                <div className="release-note-time-stamp">{`${formattedDate}`}</div>
              </div>
              <hr />
              <div className="release-notes-app-details">
                OPC UA Configuration Utility provides the capability to connect
                to southbound OPC UA compatible devices or systems from the
                Edge, browse the OPC UA namespace and add variables to the edge
                data stream which can then be subscribed by other applications.
              </div>
              <p>
                <p className="release-note-contentheaders">Key Features</p>
                <ul>
                  <li>
                    Support for user authentication to configure a secure
                    connection with OPC UA server.
                  </li>
                  <li>
                    Support for user defined nodeset mapping on the Edgenius
                    client without modifying the nodeset on the OPC UA server.
                  </li>
                </ul>
              </p>
              <p>
                <i className="info-text">
                  Note: This version is available only for Disconnected
                  Edgenius.
                </i>
              </p>
              <p>
                <p className="release-note-contentheaders">Limitations</p>
                <ul>
                  <li>
                    Each object can have maximum of 150 variable subscriptions.
                  </li>
                  <li>Supports OPC UA standard data types only.</li>
                  <li>
                    OPC UA Byte Values are mapped to "integer" data type and
                    support decimal notation only.
                  </li>
                </ul>
              </p>
              <p>
                <p className="release-note-contentheaders">
                  Additional Information
                </p>
                <ul>
                  <li>
                    It is required to remove existing data bindings and perform
                    "Upload Nodesets" from "Server settings" page, to be able to
                    perform data collection and method activation on existing
                    servers.
                  </li>
                </ul>
              </p>
              <p>
                <p className="release-note-contentheaders">Known Issues</p>
                <Table>
                  <tr>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Workarounds, Clarifications and helpful hints
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue Id
                      </div>
                    </th>
                  </tr>
                  <tbody>
                    <tr>
                      <td>There are no known issues.</td>
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </Table>
              </p>
            </div>
          </div>
        </AppMainContent>
      </AppContainer>
    </div>
  );
};

export default ReleaseNotes;
