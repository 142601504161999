export const variablesMappingTool = {
  productName: "Edgenius OPC UA Mapping Tool",
  mainPage: "Ed-Genius",
  createNameSpacePage: "Create NameSpace",
  editNodeset: "Edit Nodeset",
  deleteModalPopupMessage: "Delete Confirmation",
  channelName: "Custom Nodeset",
  CreateNewNamespace: "Create Nodeset",
  CreateNodeset: "Nodeset Mapping",
  SourceNodeSet: "Source Nodeset",
  TargetNodeSet: "Target Nodeset",
  CopyType: "Copy Type",
  CopyInstance: "Copy Instance",
  Edit: "Edit",
  Download: "Download",
  Delete: "Delete",
  Save: "Save",
  Add: "Add",
  Update: "Update",
  Cancel: "Cancel",
  light: "light",
  white: "#FFFFFF",
  grey: "#1F1F1F",
  importTitle: "Select file to import",
  importFile: "importFile",
  Export: "Export",
  Import: "Import",

  TYPE: "Type",
  OBJECT: "Object",
  UAOBJECTTYPE: "UAObjectType",
  UAOBJECT: "UAObject",
  UAVARIABLE: "UAVariable",
  UAMETHOD: "UAMethod",
  SERVER_STATE_NINE: 9,
  SERVER_STATE_SEVEN: 7,
  SAVE_ONLY: 1,
  SAVE_AND_SUBMIT: 0,
};

export const mappingToolAPI = {
  // new ones
  getEdgeniusFetch: "/Namespaces",
  deleteNodeData: `/Namespaces?Id=:id`,
  addElementData: `/Namespaces`,
  updateElementData: `/Namespaces`,
  downloadNodeData: `Namespaces`,
  localhost: environmentSettings.webapiUrl,

  //
};
// /Landing
