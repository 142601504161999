import { ITabData } from "../Common/Type/TypeCommon";
import { ITreeDataObj } from "../Common/Components/Tree/Tree";

export const tabData: ITabData[] = [
  {
    id: "servers",
    displayName: "Servers",
    path: "/",
    indexNumber: 0,
  },
];
export const OpcServerStatus: { [key: string]: number } = {
  Connected: 0,
  Connecting: 1,
  Disconnected: 2,
};

export const serversGridFixedWidth = 125;
export const configuredDataGridFixedWidth = 95;
export const variableGridFixedWidth = 430;
export const methodGridFixedWidth = 430;
export const availableTypes = {
  uaDataType: "UADataType",
  uaMethod: "UAMethod",
  uaObject: "UAObject",
  uaObjectType: "UAObjectType",
  uaReferenceType: "UAReferenceType",
  uaVariable: "UAVariable",
  uaVariableType: "UAVariableType",
  uaView: "UAView",
  root: "Root",
};
export const helpPageTreeData: ITreeDataObj[] = [
  {
    id: "%1.introduction%",
    displayName: "Introduction",
    rawData: { nodeId: "%1.introduction%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%1.introduction%%1.1rbac%",
    displayName: "1.1 RBAC",
    rawData: { nodeId: "%1.introduction%%1.1rbac%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%1.introduction%%1.2opcuaconnect%",
    displayName: "1.2 OPC UA Connect",
    rawData: { nodeId: "%1.introduction%%1.2opcuaconnect%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%1.introduction%%1.3installation%",
    displayName: "1.3 Installation",
    rawData: { nodeId: "%1.introduction%%1.3installation%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconfigurationtool%",
    displayName: "OPC UA Configuration Utility",
    rawData: {
      nodeId: "%2.opcuaconfigurationtool%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.1.configuration%",
    displayName: "Configuration",
    rawData: {
      nodeId: "%2.opcuaconfigurationtool%%2.1.configuration%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.1.configuration%2.1.1.connectopcuaserver",
    displayName: "Connect OPC UA Server",
    rawData: {
      nodeId:
        "%2.opcuaconfigurationtool%%2.1.configuration%2.1.1.connectopcuaserver",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.1.configuration%2.1.2.configureopcuaserver",
    displayName: "Configure OPC UA Server",
    rawData: {
      nodeId:
        "%2.opcuaconfigurationtool%%2.1.configuration%2.1.2.configureopcuaserver",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.2.nodesetmapping%",
    displayName: "Nodeset Mapping Tool",
    rawData: {
      nodeId: "%2.opcuaconfigurationtool%%2.2.nodesetmapping%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.3.browseopcuahierarchy%",
    displayName: "Browse OPC UA Hierarchy",
    rawData: {
      nodeId: "%2.opcuaconfigurationtool%%2.3.browseopcuahierarchy%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.4.activatevariablesandmethods%",
    displayName: "Activate Variables and Methods",
    rawData: {
      nodeId: "%2.opcuaconfigurationtool%%2.4.activatevariablesandmethods%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.5.configureopcuatypes%",
    displayName: "Configuring OPC UA Types",
    rawData: {
      nodeId: "%2.opcuaconfigurationtool%%2.5.configureopcuatypes%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconfigurationtool%%2.6.editdeleteserver%",
    displayName: "Edit/Delete OPU UA Server Configuration",
    rawData: {
      nodeId: "%2.opcuaconfigurationtool%%2.6.editdeleteserver%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.troubleshooting%",
    displayName: "Troubleshooting",
    rawData: {
      nodeId: "%3.troubleshooting%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%3.troubleshooting%%3.1.validationerror%",
    displayName: "Validation error - source file has changed",
    rawData: {
      nodeId: "%3.troubleshooting%%3.1.validationerror%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.troubleshooting%%3.2.variablenotmapped%",
    displayName: "Variable is not mapped to any source node",
    rawData: {
      nodeId: "%3.troubleshooting%%3.2.variablenotmapped%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.troubleshooting%%3.2.datatyperemoved%",
    displayName: "Existing data types will be removed",
    rawData: {
      nodeId: "%3.troubleshooting%%3.2.datatyperemoved%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  // {
  //   id: "%4.opcuaconnectondemand%",
  //   displayName: "OPC UA Configuration Utility – Connect-On-Demand mode ",
  //   rawData: {
  //     nodeId: "%4.opcuaconnectondemand%",
  //   } as any,
  //   isExpandable: true,
  //   isExpanded: false,
  //   isVisible: true,
  //   isTitle: false,
  //   level: 1,
  // },
  // {
  //   id: "%4.opcuaconnectondemand%%4.1.installation%",
  //   displayName: "Installation",
  //   rawData: {
  //     nodeId: "%4.opcuaconnectondemand%%4.1.installation%",
  //   } as any,
  //   isExpandable: false,
  //   isExpanded: false,
  //   isVisible: false,
  //   isTitle: false,
  //   level: 2,
  // },
  // {
  //   id: "%4.opcuaconnectondemand%%4.2.routingconfiguration%",
  //   displayName: "Routing Configuration",
  //   rawData: {
  //     nodeId: "%4.opcuaconnectondemand%%4.2.routingconfiguration%",
  //   } as any,
  //   isExpandable: false,
  //   isExpanded: false,
  //   isVisible: false,
  //   isTitle: false,
  //   level: 2,
  // },
  // {
  //   id: "%4.opcuaconnectondemand%%4.3.synchronization%",
  //   displayName: "Synchronization",
  //   rawData: {
  //     nodeId: "%4.opcuaconnectondemand%%4.3.synchronization%",
  //   } as any,
  //   isExpandable: false,
  //   isExpanded: false,
  //   isVisible: false,
  //   isTitle: false,
  //   level: 2,
  // },
];
