import serversState, {
  IServersDetailsStateType,
} from "../Views/Servers/Reducer/ReducerServers";
import browseState, {
  IBrowsePageStateType,
} from "../Views/Browse/Reducer/ReducerBrowse";
import { combineReducers } from "redux";
import commonState, { ICommonStateType } from "../Common/Reducer/ReducerCommon";
import { homePageReducer } from "../Views/Browse/Action/homePageStore";
import { nodeSetEditorReducer } from "../Views/Browse/Action/nodeTreeStore";

export interface IState {
  serversState: IServersDetailsStateType;
  browseState: IBrowsePageStateType;
  commonState: ICommonStateType;
}

const allReducers = combineReducers({
  serversState,
  browseState,
  commonState,
  homePageReduce: homePageReducer,
  nodeSetEditor: nodeSetEditorReducer,
});

export type AppState = ReturnType<typeof allReducers>;
export default allReducers;
