import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  NodeSetEditorContainerState,
  NodeSetEditorData,
  NodeSetEditorByIdResponseData,
  ErrorNotification,
} from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import { ReducerTypesContainerState } from "../../../../Reducer/reducerTypes/reducerTypes";

const initialState: NodeSetEditorContainerState = {
  nodeEditorData: [],
  nodeEditorByIdData: null,
  errorMessage: [],
  isLoading: false,
  targetNodesetNewData: [],
  targetCSVDataList: [],
  notifications: {
    notificationMessage: "",
    notificationCloseDuration: 2000,
    showNotification: false,
  },
};

const nodeSetEditorReducer = createSlice({
  name: "nodeSetEditor",
  initialState,
  reducers: {
    getNodeSetEditorDataList: (
      state,
      { payload }: PayloadAction<NodeSetEditorData[]>
    ) => {
      state.nodeEditorData = [];
      payload.forEach((x) => {
        state.nodeEditorData.push(x);
      });
    },

    isLoadingFor: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    clearNodeSetEditorDataList: (state) => {
      state.nodeEditorData = [];
    },
    getNodeSetEditorByIdDataList: (
      state,
      { payload }: PayloadAction<NodeSetEditorByIdResponseData>
    ) => {
      state.nodeEditorByIdData = payload;
    },
    clearNodeSetEditorByIdDataList: (state) => {
      state.nodeEditorByIdData = null;
      state.nodeEditorData = [];
      state.errorMessage = [];
      state.targetCSVDataList = [];
    },
    validationToastMessagesReducer: (
      state,
      { payload }: PayloadAction<ErrorNotification[]>
    ) => {
      state.errorMessage = [];
      if (Array.isArray(payload) && payload.length > 0) {
        payload.forEach((index) => {
          state.errorMessage.push(index);
        });
      }
    },
    clearErrorValidation: (state) => {
      state.errorMessage = [];
    },

    updateReducerPayloadTargetData: (
      // store the same data available in state; Used to send to api
      state,
      { payload }: PayloadAction<NodeSetEditorData[]>
    ) => {
      state.targetNodesetNewData = [];
      state.targetNodesetNewData = payload;
    },

    importTargetCSVDataList: (
      state,
      { payload }: PayloadAction<NodeSetEditorData[]>
    ) => {
      state.targetCSVDataList = [];
      payload.forEach((x) => {
        state.targetCSVDataList.push(x);
      });
    },
    clearImportTargetCSVDataList: (state) => {
      state.targetCSVDataList = [];
    },

    closeNotification: (state) => {
      state.notifications = {
        notificationMessage: "",
        notificationCloseDuration: 2000,
        showNotification: false,
      };
    },
  },
});

export const {
  getNodeSetEditorDataList,
  clearNodeSetEditorDataList,
  getNodeSetEditorByIdDataList,
  clearNodeSetEditorByIdDataList,
  clearErrorValidation,
  updateReducerPayloadTargetData,
  validationToastMessagesReducer,
  isLoadingFor,
  importTargetCSVDataList,
  clearImportTargetCSVDataList,
  closeNotification,
} = nodeSetEditorReducer.actions;

export const nodeSetEditorValuesSelector = (
  state: ReducerTypesContainerState
): NodeSetEditorContainerState => state.nodeSetEditor;

export default nodeSetEditorReducer.reducer;
