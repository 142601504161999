import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { TreeItemIndex, TreeItem, DraggingPosition } from "react-complex-tree";
import "react-complex-tree/lib/style.css";

import { useDispatch, useSelector } from "react-redux";

import { Updater } from "use-immer";
import { Button, Icon, MessageDialog } from "@abb/abb-common-ux-react";

import "./SourceTree.css";
import NComplexTree from "./TreeComponentChild";

import { enableMapSet } from "immer";
import { CreateComponentModelStates } from "../../../../Models/CreateCompModels/CreateCompModel";
import {
  NodeDisplayName,
  NodeSetEditorData,
  NodeSetEditorDataReferences,
} from "../../../../Models/NodeSetEditorModels/NodeSetEditorModels";
import { variablesMappingTool } from "../../../../Utils/ConstantsMappingTool";
import { isLoadingFor } from "../../Action/nodeTreeStore";
import { nodeSetEditorValuesSelector } from "../../Action/nodeTreeStore/nodeTreeReducers";
import { IconDisplay } from "./IconDisplay";
import {
  excludeWrongData,
  formattedNodeDataIndexes,
  generateRandomString,
  getNumFromStr,
  nextNumber,
  uniqueNodeIdGenerator,
} from "../../../../Utils/HelperFunctionsNodeSet";
import ItemDisplayElement from "./ItemDisplayElement";
import redLine from "./redLine.png";
enableMapSet();

interface Props {
  setState: Updater<CreateComponentModelStates>;
  state: CreateComponentModelStates;
  saveUpdateValidation: (stateData: CreateComponentModelStates) => boolean;
}

export type DragNodes = DraggingPosition & {
  targetItem: TreeItemIndex;
};
// random Node Id set

interface ObjectDND {
  [key: string]: number;
}

export const HAS_TYPE_DEFINITION = "HasTypeDefinition";
export const HAS_SUB_TYPE = "HasSubtype";
export const HAS_COMPONENT = "HasComponent";
export const HAS_PROPERTY = "HasProperty";
export const REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE = "i=85";
export const BASE_OBJECT_REFERENCE_VALUE = "i=58";

const NewComplexTree: React.FC<Props> = ({
  state,
  setState,
  saveUpdateValidation,
}) => {
  const { nodeEditorByIdData, errorMessage, targetCSVDataList } = useSelector(
    nodeSetEditorValuesSelector
  );

  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>([]);
  const [focusedItem, setFocusedItem] = useState<TreeItemIndex>();
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([
    "root",
    "roots",
    "objects",
    "types",
    "target",
    "targetObjects",
    "targetTypes",
  ]);
  const [loadTimer, setLoadTimer] = useState(false);
  const {
    state: { id },
  } = useLocation<any>();

  const [menuFlag, setMenuFlag] = useState(false);

  const loadTimerSet = () => {
    setLoadTimer(true);
  };

  const setSelectedFocusedItem = (
    e: React.MouseEvent<
      HTMLDivElement | HTMLButtonElement | HTMLAnchorElement,
      MouseEvent
    >,
    inputIndex: TreeItemIndex
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedItems([inputIndex]);
    setFocusedItem(inputIndex);
    setMenuFlag(true);
  };

  function displayToastForOverItem(
    parentIdDropFromSourceItems: NodeSetEditorData | undefined,
    sourceCheckValue: NodeSetEditorData,
    targetCheckValue: NodeSetEditorData
  ) {
    if (parentIdDropFromSourceItems) {
      const textDisplaySource =
        sourceCheckValue?.NodeClass === variablesMappingTool.UAOBJECTTYPE
          ? "Types"
          : "Objects";
      const textDisplayTarget =
        targetCheckValue.NodeClass === variablesMappingTool.UAOBJECTTYPE
          ? "Types"
          : "Objects";

      dispatch({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: `Nodes from ${textDisplaySource} cannot be placed inside ${textDisplayTarget}`,
      });
    }
  }

  function addSourceKeys(
    value: NodeSetEditorData,
    sourceTypeKeys: Set<TreeItemIndex>,
    sourceObjectKeys: Set<TreeItemIndex>
  ) {
    if (value.NodeClass === variablesMappingTool.UAOBJECTTYPE) {
      sourceTypeKeys.add(value.index);
    } else if (
      value.NodeClass === variablesMappingTool.UAOBJECT &&
      value.ParentNodeId === REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
    ) {
      sourceObjectKeys.add(value.index);
    }
  }
  // added child of ua property
  function pushIndexToChild(
    formattedNodeData: NodeSetEditorData[],
    value: NodeSetEditorData,
    childrenIndexes: TreeItemIndex[]
  ) {
    for (const element of formattedNodeData) {
      if (element.ParentNodeId === value.NodeId) {
        childrenIndexes.push(element.index);
      }
    }
  }

  function checkChildrens(
    value: NodeSetEditorData,
    hasChild: boolean,
    folderOrNot: boolean,
    nodeDataMapSourceNodeKey: Map<string, NodeSetEditorData>
  ) {
    const folderObj =
      value.References &&
      value.References.length > 0 &&
      value.References?.find((h) => h.ReferenceType === HAS_TYPE_DEFINITION);

    if (folderObj && folderObj.Value === "i=68") {
      hasChild = false;
    }
    if (value.NodeClass === variablesMappingTool.UAOBJECT) {
      if (folderObj && folderObj.Value === "i=61") {
        folderOrNot = true;
      } else if (
        folderObj &&
        folderObj.Value !== "i=61" &&
        folderObj.Value.length > 4
      ) {
        const baseType = nodeDataMapSourceNodeKey.get(folderObj.Value);

        if (baseType?.References.find((h) => h.Value === "i=61")) {
          folderOrNot = true;
        }
      }
    }
    return { hasChild, folderOrNot };
  }
  function setSourceTargetData(
    nodeType: string,
    source: Map<any, any>,
    sourceCopy: Map<any, any>,
    sourceObjectKeys: Set<TreeItemIndex>,
    sourceTypeKeys: Set<TreeItemIndex>,
    target: Map<any, any>
  ) {
    if (nodeType === "source") {
      setState((draft) => {
        draft.sourceNodeItems = Array.from(source.values());

        draft.sourceNodeMappedData = source;
        draft.sourceNodeMappedDataNodeKey = sourceCopy;
        draft.sourceObjectIndexes = sourceObjectKeys;
        draft.sourceTypesIndexes = sourceTypeKeys;
        draft.changeTargetFlag = false;
      });
    } else if (nodeType === "target") {
      setState((draft) => {
        draft.targetNodeItemsBackup = target;
        draft.targetNodeMappedData = target;
        draft.changeTargetFlag = false;
      });
    }
  }

  const setFinalDataMap = (
    value: NodeSetEditorData,
    target: Map<TreeItemIndex, NodeSetEditorData>,
    sourceTypeKeys: Set<TreeItemIndex>,
    sourceObjectKeys: Set<TreeItemIndex>,
    source: Map<TreeItemIndex, NodeSetEditorData>,
    sourceCopy: Map<string, NodeSetEditorData>,
    SourceParamsObject: any
  ) => {
    const {
      nodeType,
      titleTooltip,
      folderOrNot,
      name,
      childrenIndexes,
      key,
      additionalText,
    } = SourceParamsObject;
    if (nodeType === "target") {
      const tarObject = {
        ...value,
        data: (
          <ItemDisplayElement
            index={value.index}
            nodeClass={value.NodeClass}
            folderOrNot={folderOrNot}
            titleTooltip={titleTooltip}
            name={name}
            setSelectedFocusedItem={setSelectedFocusedItem}
          />
        ),
        children: childrenIndexes,
        hasChildren:
          childrenIndexes && childrenIndexes.length > 0 ? true : false,
        isFolder: folderOrNot,
        SourceNodeId: value.SourceNodeId,
        TitleTooltip: titleTooltip,
        modifiedName: name,
        additionalText,
      };
      target.set(key, tarObject);
    } else {
      const sourceObject = {
        ...value,
        data: (
          <div className="mainDivElement">
            <IconDisplay
              folderIs={folderOrNot}
              NodeClassName={value.NodeClass}
            />

            <div
              className="flexColumn"
              id={value.index as string}
              title={titleTooltip}
            >
              {name}
            </div>
          </div>
        ),

        children: childrenIndexes,
        hasChildren:
          childrenIndexes && childrenIndexes.length > 0 ? true : false,
        isFolder: folderOrNot,
        TitleTooltip: titleTooltip,
        modifiedName: name,
        additionalText,
      };
      addSourceKeys(value, sourceTypeKeys, sourceObjectKeys);

      source.set(key, sourceObject);

      sourceCopy.set(sourceObject.NodeId, sourceObject);
    }
  };

  const recursiveMap = useCallback(
    (nodeData: NodeSetEditorData[], nodeType: string) => {
      loadTimerSet();
      dispatch(isLoadingFor(true));

      let formattedNodeData: NodeSetEditorData[] = [];

      formattedNodeData = formattedNodeDataIndexes(nodeData, nodeType);

      const nodeDataMapSource = new Map(
        formattedNodeData.map((obj) => {
          return [obj.index, obj];
        })
      );
      const nodeDataMapSourceNodeKey = new Map(
        formattedNodeData.map((obj) => {
          return [obj.NodeId, obj];
        })
      );
      const sourceCopy: Map<string, NodeSetEditorData> = new Map();

      const source: Map<TreeItemIndex, NodeSetEditorData> = new Map();
      const target: Map<TreeItemIndex, NodeSetEditorData> = new Map();
      const sourceObjectKeys = new Set<TreeItemIndex>();
      const sourceTypeKeys = new Set<TreeItemIndex>();

      for (const [key, value] of nodeDataMapSource) {
        if (excludeWrongData(value, nodeDataMapSourceNodeKey)) {
          const nameToDisplay = getDisplayNames(
            value,
            nodeDataMapSourceNodeKey
          );

          let name: string | JSX.Element = nameToDisplay().nameElement;
          let additionalText: string = nameToDisplay().additionalTextDisplay;
          let folderOrNot = false;
          let childrenIndexes: TreeItemIndex[] = [];

          // exclude
          let hasChild = true;

          ({ hasChild, folderOrNot } = checkChildrens(
            value,
            hasChild,
            folderOrNot,
            nodeDataMapSourceNodeKey
          ));
          pushIndexToChild(formattedNodeData, value, childrenIndexes);

          const titleTooltip = !hasChild
            ? "Property"
            : value?.NodeClass?.replace("UA", "");

          const SourceParamsObject = {
            nodeType,
            titleTooltip,
            folderOrNot,
            name,
            childrenIndexes,
            key,
            additionalText,
          };
          setFinalDataMap(
            value,
            target,
            sourceTypeKeys,
            sourceObjectKeys,
            source,
            sourceCopy,
            SourceParamsObject
          );
        }
      }
      setSourceTargetData(
        nodeType,
        source,
        sourceCopy,
        sourceObjectKeys,
        sourceTypeKeys,
        target
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState, dispatch]
  );

  useEffect(() => {
    if (
      nodeEditorByIdData &&
      nodeEditorByIdData.sourceNodeSet &&
      nodeEditorByIdData.sourceNodeSet.length > 0
    ) {
      recursiveMap(nodeEditorByIdData.sourceNodeSet, "source");
    }
  }, [nodeEditorByIdData, recursiveMap]);

  useEffect(() => {
    if (
      nodeEditorByIdData &&
      nodeEditorByIdData.targetNodeSet &&
      nodeEditorByIdData.targetNodeSet?.length > 0 &&
      state.sourceNodeMappedData &&
      state.sourceNodeMappedData.size > 0
    ) {
      recursiveMap(nodeEditorByIdData.targetNodeSet, "target");
    }
  }, [nodeEditorByIdData, recursiveMap, state.sourceNodeMappedData]);

  //  import target CSV data below
  useEffect(() => {
    if (targetCSVDataList && targetCSVDataList?.length > 0) {
      recursiveMap(targetCSVDataList, "target");
    }
  }, [recursiveMap, targetCSVDataList]);

  // error Messages Icon
  useEffect(() => {
    if (
      errorMessage &&
      errorMessage.length > 0 &&
      state.targetNodeMappedData &&
      state.targetNodeMappedData.size > 0
    ) {
      let errorNodeId: string[] = [];
      errorMessage.forEach((index) => errorNodeId.push(index.nodeId));

      const errorTargetMapped = new Map(state.targetNodeMappedData);

      errorNodeId.forEach((element) => {
        const foundErrorNodeId = state.targetNodeMappedDataNodeKey.get(element);
        if (foundErrorNodeId) {
          errorTargetMapped.set(foundErrorNodeId.index, {
            ...foundErrorNodeId,
            data: (
              <div className="mainDivElement">
                <IconDisplay
                  folderIs={foundErrorNodeId.isFolder}
                  NodeClassName={foundErrorNodeId.NodeClass}
                />
                <div
                  onContextMenu={(e) => {
                    setSelectedFocusedItem(e, foundErrorNodeId.index);
                  }}
                  className="flexColumn"
                  id={foundErrorNodeId.index as string}
                  title={foundErrorNodeId.TitleTooltip || ""}
                >
                  <img
                    src={redLine}
                    alt="logo"
                    className="lineIcons"
                    draggable="false"
                  />
                  {foundErrorNodeId.modifiedName}
                </div>
                <Icon
                  name="abb/warningTriangle"
                  className="errorIcon nodeTypeImage"
                  sizeClass="small"
                  color="#F03040"
                />
                <Button
                  className="ellipsisClass"
                  icon="abb/more"
                  onClick={(e) => {
                    setSelectedFocusedItem(e, foundErrorNodeId.index);
                  }}
                  type="discreet-black"
                  sizeClass="small"
                />
              </div>
            ),
            validationError: true,
          });
        }
      });

      setState((draft) => {
        draft.targetNodeMappedData = errorTargetMapped;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, setState]);

  // Display Tree Object Code Below
  useEffect(() => {
    if (
      (state.sourceObjectIndexes && state.sourceObjectIndexes.size > 0) ||
      (state.sourceTypesIndexes && state.sourceTypesIndexes.size > 0)
    ) {
      const targetObjectsMain =
        (state.targetNodeMappedData &&
          state.targetNodeMappedData.size > 0 &&
          Array.from(state.targetNodeMappedData?.values())
            ?.filter(
              (x) =>
                x.NodeClass === variablesMappingTool.UAOBJECT &&
                x.ParentNodeId === REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
            )
            ?.map((s) => s.index)) ||
        [];

      const targetTypeMain =
        (state.targetNodeMappedData &&
          state.targetNodeMappedData.size > 0 &&
          [...state.targetNodeMappedData.values()]
            .filter((x) => x.NodeClass === variablesMappingTool.UAOBJECTTYPE)
            ?.map((s) => s.index)) ||
        [];
      let treeDisplayElement: any = {
        root: {
          index: "root",
          hasChildren: true,
          children: ["roots"],
          data: "Root",
          canMove: false,
          canRename: false,
        },
        roots: {
          index: "roots",
          hasChildren: true,
          children: ["objects", "types"],
          data: "Root",
          canMove: false,
          canRename: false,
        },
        objects: {
          index: "objects",
          hasChildren: true,
          children:
            (state.sourceObjectIndexes &&
              state.sourceObjectIndexes.size > 0 && [
                ...state.sourceObjectIndexes,
              ]) ||
            [],

          data: "Objects",
          canMove: false,
          canRename: false,
        },
        types: {
          index: "types",
          hasChildren: true,
          children:
            (state.sourceTypesIndexes &&
              state.sourceTypesIndexes.size > 0 && [
                ...state.sourceTypesIndexes,
              ]) ||
            [],
          data: "Types",
          canMove: false,
          canRename: false,
        },
        targetRoot: {
          index: "targetRoot",
          hasChildren: true,
          children: ["target"],
          data: "Target Root",
          canMove: false,
          canRename: false,
        },
        target: {
          index: "target",
          hasChildren: true,
          children: ["targetObjects", "targetTypes"],
          data: "Root",
          canMove: false,
          canRename: false,
        },
        targetObjects: {
          index: "targetObjects",
          hasChildren: true,
          children: targetObjectsMain,
          data: (
            <ItemDisplayElement
              index="targetObjects"
              nodeClass={undefined}
              folderOrNot={false}
              titleTooltip=""
              name="Objects"
              setSelectedFocusedItem={setSelectedFocusedItem}
            />
          ),
          canMove: false,
          canRename: false,
        },
        targetTypes: {
          index: "targetTypes",
          hasChildren: true,
          children: targetTypeMain,
          data: (
            <ItemDisplayElement
              index="targetTypes"
              nodeClass={undefined}
              folderOrNot={false}
              titleTooltip=""
              name="Types"
              setSelectedFocusedItem={setSelectedFocusedItem}
            />
          ),
          canMove: false,
          canRename: false,
        },
      };
      setState((draft) => {
        draft.treeItems = treeDisplayElement;
      });
    }
  }, [
    setState,
    state.sourceObjectIndexes,
    state.sourceTypesIndexes,
    state.targetNodeMappedData,
  ]);

  useEffect(() => {
    if (state.sourceNodeItems && state.sourceNodeItems.length > 0) {
      let concatenatedArray = [];

      if (state.targetNodeMappedData && state.targetNodeMappedData.size > 0) {
        concatenatedArray = state.sourceNodeItems.concat(
          Array.from(state.targetNodeMappedData.values())
        );
      } else {
        concatenatedArray = state.sourceNodeItems;
      }

      const combinedMap = new Map(
        concatenatedArray.map((obj) => {
          return [obj.index, obj];
        })
      );
      const newObj = Object.fromEntries(combinedMap);
      if (loadTimer) {
        dispatch(isLoadingFor(false));
      }
      setTimeout(() => {
        setLoadTimer(false);
      }, 500);
      setState((draft) => {
        draft.treeItems = { ...draft.treeItems, ...newObj };
      });
    } else {
      setState((draft) => {
        draft.treeItems = {};
      });
      if (loadTimer) {
        dispatch(isLoadingFor(false));
      }
      setTimeout(() => {
        setLoadTimer(false);
      }, 500);
    }
  }, [
    dispatch,
    loadTimer,
    setState,
    state.sourceNodeItems,
    state.targetNodeMappedData,
  ]);
  useEffect(() => {
    if (state.targetNodeMappedData && state.targetNodeMappedData.size > 0) {
      const targetItemsMapped = state.targetNodeMappedData;
      const newObjEntries = Object.fromEntries(targetItemsMapped);
      setState((draft) => {
        draft.treeItems = { ...draft.treeItems, ...newObjEntries };
      });

      dispatch(isLoadingFor(false));
    }
  }, [dispatch, setState, state.targetNodeMappedData]);

  const findChilds = (
    childs: TreeItemIndex[],
    parentIndex: TreeItemIndex,
    parentNodeId: string
  ) => {
    let children: NodeSetEditorData[] = [];

    if (state.sourceNodeMappedData && state.sourceNodeMappedData.size > 0) {
      childs.forEach((child) => {
        let findChild: NodeSetEditorData | undefined =
          state.sourceNodeMappedData?.get(child);

        if (findChild) {
          let newNodeIdChildren: string = uniqueNodeIdGenerator();
          const newIndexId: TreeItemIndex = "tar" + generateRandomString(8);
          let newChildObj: NodeSetEditorData = {
            ...findChild,
            sourceIndex: findChild.index,
            index: newIndexId,
            parentIndex: parentIndex,
            isTypeObjectChild:
              findChild.NodeClass === variablesMappingTool.UAVARIABLE
                ? true
                : false,
            ParentNodeId: parentNodeId,
            SourceNodeId: findChild.NodeId,
            NodeId: newNodeIdChildren,
          };

          if (newChildObj.children.length > 0) {
            let getChild = findChilds(
              findChild.children,
              newIndexId,
              newChildObj.NodeId
            );
            children.push(...getChild);
          }
          children.push(newChildObj);
        }
      });
    }

    return children;
  };

  const CreateNewTypeInstanceScroll = (nameIdx: string) => {
    const idOfElement = document.getElementById(nameIdx);

    idOfElement?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });

    setFocusedItem(nameIdx);
  };
  const expandItemsOrNot = (typeOrObject: string) => {
    const expandedEl = [...expandedItems];
    if (typeOrObject === variablesMappingTool.TYPE) {
      expandedEl.push("targetTypes");
    }
    if (typeOrObject === variablesMappingTool.OBJECT) {
      expandedEl.push("targetObjects");
    }
    const uniqueExpandedEl = Array.from(new Set(expandedEl));
    setExpandedItems(uniqueExpandedEl);
  };

  const findSubTypesForType = (
    referenceObject: NodeSetEditorDataReferences[]
  ) => {
    return referenceObject.find(
      (s) =>
        s.ReferenceType === HAS_SUB_TYPE &&
        !s.IsForward &&
        s.Value?.toLowerCase().startsWith("ns")
    );
  };
  const findSubTypesAssociated = (
    findSubtype: NodeSetEditorDataReferences,
    sourceNodeMappedDataNodeKey: Map<string, NodeSetEditorData>,
    parentElements?: Set<string | undefined>
  ) => {
    const foundSubType = sourceNodeMappedDataNodeKey.get(findSubtype.Value);
    if (
      parentElements &&
      parentElements.has(foundSubType?.DisplayName[0].Value)
    ) {
      return;
    }

    let newSubTypeObj: NodeSetEditorData[] = [];
    if (foundSubType) {
      let indexIdSubType = "tar" + generateRandomString(8);
      let newNodeIdSubType: string = uniqueNodeIdGenerator();

      newSubTypeObj.push({
        ...foundSubType,
        sourceIndex: foundSubType.index,
        index: indexIdSubType,
        parentIndex: "targetTypes",
        NodeId: newNodeIdSubType,
        SourceNodeId: foundSubType.NodeId,
        References: foundSubType.References,
      });
      let childObjectsSubType = findChilds(
        foundSubType.children,
        indexIdSubType,
        newNodeIdSubType
      );
      newSubTypeObj.push(...childObjectsSubType);
      if (foundSubType.NodeClass === variablesMappingTool.UAOBJECTTYPE) {
        const getRecursiveType = findSubTypesForType(foundSubType.References);

        if (getRecursiveType) {
          findSubTypesAssociated(getRecursiveType, sourceNodeMappedDataNodeKey);
        }
      }
    }
    return newSubTypeObj;
  };

  function getSubTypeCopyAssociated(
    typeOrObject: string,
    copyType: NodeSetEditorData,
    parentElements: Set<string | undefined>,
    newTargetObjects: NodeSetEditorData[]
  ) {
    if (typeOrObject === variablesMappingTool.TYPE) {
      const findSubtype = findSubTypesForType(copyType.References);

      if (findSubtype) {
        const subTypes = findSubTypesAssociated(
          findSubtype,
          state.sourceNodeMappedDataNodeKey,
          parentElements
        );
        if (subTypes) {
          newTargetObjects.push(...subTypes);
        }
      }
    }
  }

  function setObjectBaseReference(
    x: NodeSetEditorData,
    objectTypeBaseReferences: NodeSetEditorDataReferences[]
  ) {
    if (x.NodeClass === variablesMappingTool.UAOBJECT) {
      objectTypeBaseReferences = x.References.map((y) => {
        if (
          y.ReferenceType === HAS_TYPE_DEFINITION &&
          x.ParentNodeId === REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
        ) {
          return {
            ...y,
            Value: BASE_OBJECT_REFERENCE_VALUE,
          };
        }
        return y;
      });
    }
    return objectTypeBaseReferences;
  }

  function setCopy(
    clonedTargetMapped: Map<TreeItemIndex, NodeSetEditorData>,
    indexId: string
  ) {
    setState((draft) => {
      draft.targetNodeMappedData = clonedTargetMapped;
      draft.changeTargetFlag = true;
    });

    setTimeout(() => {
      CreateNewTypeInstanceScroll(indexId);
    }, 2000);
  }
  const copyTypeOrObject = (
    typeOrObject: string,
    parentElements: Set<string | undefined>,
    clonedTargetMapped: Map<TreeItemIndex, NodeSetEditorData>
  ) => {
    expandItemsOrNot(typeOrObject);

    let newTargetObjects: NodeSetEditorData[] = [];

    let newNodeId: string = uniqueNodeIdGenerator();
    let copyType = state.findSelectedItemSource as NodeSetEditorData;
    let indexId = "tar" + generateRandomString(8);

    const refs = copyType.References.map((y) => {
      if (
        copyType.NodeClass === variablesMappingTool.UAOBJECT &&
        y.ReferenceType === HAS_TYPE_DEFINITION
      ) {
        return {
          ...y,
          Value: BASE_OBJECT_REFERENCE_VALUE,
        };
      }
      return y;
    });

    newTargetObjects.push({
      ...copyType,
      sourceIndex: copyType.index,
      index: indexId,
      parentIndex: "targetTypes",
      ...(typeOrObject === variablesMappingTool.OBJECT && {
        targetObject: true,
      }),
      NodeId: newNodeId,
      SourceNodeId: copyType.NodeId,
      References: refs,
      ...(copyType.NodeClass === variablesMappingTool.UAOBJECT && {
        ParentNodeId: REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE,
      }),
    });

    let childObjects = findChilds(copyType.children, indexId, newNodeId);

    newTargetObjects.push(...childObjects);

    getSubTypeCopyAssociated(
      typeOrObject,
      copyType,
      parentElements,
      newTargetObjects
    );

    for (let x of newTargetObjects) {
      copyTypeMappedFinal(
        x,
        newTargetObjects,
        setObjectBaseReference,
        clonedTargetMapped,
        setSelectedFocusedItem
      );
    }

    setCopy(clonedTargetMapped, indexId);
  };

  const copyTypeFunctionMain = (typeOrObject: string) => {
    const selectedItemsNode = state.findSelectedItemSource as NodeSetEditorData;

    if (selectedItemsNode.NodeClass === variablesMappingTool.UAOBJECT) {
      const sourceCheckParent = sourceCheck([selectedItemsNode]);
      if (sourceCheckParent.NodeClass !== variablesMappingTool.UAOBJECT) {
        dispatch(isLoadingFor(false));

        return;
      }
    }
    const clonedTargetMapped = new Map(state.targetNodeMappedData);

    const targetObjectKeys: string[] =
      state.treeItems["targetObjects"]?.children;
    const targetTypeKeys: string[] = state.treeItems["targetTypes"]?.children;

    const parentElements: Set<string | undefined> = new Set();

    parentElements.clear();

    if (typeOrObject === variablesMappingTool.OBJECT) {
      targetObjectKeys?.forEach((s) => {
        const names = clonedTargetMapped.get(s);

        parentElements.add(names?.DisplayName[0].Value);
      });
    } else if (typeOrObject === variablesMappingTool.TYPE) {
      targetTypeKeys?.forEach((s) => {
        const names = clonedTargetMapped.get(s);
        parentElements.add(names?.DisplayName[0].Value);
      });
    }

    const foundExistingTargetOrInstanceTypes = () => {
      let has = false;
      if (state.targetNodeMappedData && state.targetNodeMappedData.size > 0) {
        if (parentElements.has(selectedItemsNode.DisplayName[0].Value)) {
          has = true;

          return has;
        }
      }
      return has;
    };

    if (foundExistingTargetOrInstanceTypes()) {
      dispatch(isLoadingFor(false));
    }
    if (!foundExistingTargetOrInstanceTypes()) {
      copyTypeOrObject(typeOrObject, parentElements, clonedTargetMapped);
    } else {
      dispatch({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: `${selectedItemsNode?.DisplayName[0]?.Value} Already Exists`,
      });
    }
  };

  const copyTypeFunction = (typeOrObject: string) => {
    if (
      state.sourceNodeMappedData &&
      state.sourceNodeMappedData.size > 0 &&
      state.findSelectedItemSource
    ) {
      copyTypeFunctionMain(typeOrObject);
    }
  };

  const addNewTypeOrInstance = (
    typeOrInstance: string,
    callBackScroll: (name: string) => void
  ) => {
    const randomNumberId = uniqueNodeIdGenerator();

    //  expanding tree when adding instance or type
    expandItemsOrNot(typeOrInstance);
    const clonedTargetMappedNewItem = new Map(state.targetNodeMappedData);

    const instanceReferences = [
      {
        ReferenceType: HAS_COMPONENT,
        IsForward: false,
        Value: REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE,
      },
      {
        ReferenceType: HAS_TYPE_DEFINITION,
        IsForward: true,
        Value: BASE_OBJECT_REFERENCE_VALUE,
      },
    ];

    const typeReferences = [
      {
        ReferenceType: HAS_SUB_TYPE,
        IsForward: false,
        Value: BASE_OBJECT_REFERENCE_VALUE,
      },
    ];

    const typeTrueOrFalse =
      typeOrInstance === variablesMappingTool.TYPE ? true : false;

    const lengthOfArray: TreeItemIndex[] =
      typeOrInstance === variablesMappingTool.TYPE
        ? state.treeItems["targetTypes"].children
        : state.treeItems["targetObjects"].children;

    let numberRandom: number[] = [];
    lengthOfArray.forEach((s) => {
      const instancesOrType = clonedTargetMappedNewItem.get(s);
      if (
        instancesOrType &&
        instancesOrType.DisplayName &&
        instancesOrType.DisplayName[0].Value &&
        instancesOrType.DisplayName[0].Value.toLowerCase().includes(
          `new ${typeOrInstance.toLowerCase()}`
        )
      ) {
        const onlyNumber = getNumFromStr(instancesOrType.DisplayName[0].Value);
        if (onlyNumber) numberRandom.push(onlyNumber);
      }
    });
    let numbersObj = nextNumber(numberRandom);

    const indexNewItem = `tar${typeOrInstance}` + generateRandomString(8);

    const addDefaultNewTypeOrInstance = {
      ...(typeTrueOrFalse && { IsAbstract: false }),
      ...(!typeTrueOrFalse && {
        EventNotifier: 0,
        ParentNodeId: REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE,
        isFolder: false,
        targetObject: true,
      }),
      DisplayName: [
        {
          Locale: "",
          Value: `New ${typeOrInstance}` + numbersObj,
        },
      ],
      Description: [{ Locale: "", Value: `New ${typeOrInstance}` }],
      Category: null,
      Documentation: null,
      References: typeTrueOrFalse ? typeReferences : instanceReferences,
      RolePermissions: null,
      Extensions: null,
      NodeId: randomNumberId,
      BrowseName: `1:New ${typeOrInstance}` + numbersObj,
      WriteMask: 0,
      UserWriteMask: 0,
      AccessRestrictions: 0,
      AccessRestrictionsSpecified: false,
      HasNoPermissions: false,
      SymbolicName: null,
      ReleaseStatus: 0,
      NodeClass: typeTrueOrFalse
        ? variablesMappingTool.UAOBJECTTYPE
        : variablesMappingTool.UAOBJECT,
      data: (
        <ItemDisplayElement
          index={indexNewItem}
          nodeClass={
            typeTrueOrFalse
              ? variablesMappingTool.UAOBJECTTYPE
              : variablesMappingTool.UAOBJECT
          }
          folderOrNot={false}
          titleTooltip={typeTrueOrFalse ? "Type" : "Object"}
          name={`New ${typeOrInstance}` + numbersObj}
          setSelectedFocusedItem={setSelectedFocusedItem}
        />
      ),
      index: indexNewItem,
      children: [],
      hasChildren: false,
      TitleTooltip: typeTrueOrFalse ? "Type" : "Object",
      additionalText: "",
      modifiedName: `New ${typeOrInstance}` + numbersObj,
    };

    clonedTargetMappedNewItem.set(indexNewItem, addDefaultNewTypeOrInstance);

    setState((draft) => {
      draft.targetNodeMappedData = clonedTargetMappedNewItem;
      draft.changeTargetFlag = true;
    });

    setMenuFlag(false);

    setTimeout(() => {
      callBackScroll(addDefaultNewTypeOrInstance.index);
    }, 150);
  };

  const renameDuplicatesValidation = useCallback(
    (
      item: NodeSetEditorData,
      name: string,
      targetItemArray: NodeSetEditorData[]
    ) => {
      let parentFlag = false;
      let renameFlag = false;
      let parentName: string | undefined = "";

      if (
        item &&
        item.ParentNodeId &&
        item.ParentNodeId !== REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE
      ) {
        parentFlag = true;

        const parent = targetItemArray.filter(
          (f) => f.ParentNodeId === item.ParentNodeId
        );

        const parentNamesForId = targetItemArray.find(
          (p) => p.NodeId === parent[0].ParentNodeId
        )?.DisplayName[0].Value;

        const found = parent.find(
          (fnd) =>
            fnd.DisplayName[0].Value?.trim()?.toLowerCase() ===
            name?.trim()?.toLowerCase()
        );
        parentName = parentNamesForId;
        if (!found) renameFlag = true;
      } else {
        const found = targetItemArray.find(
          (fnd) =>
            fnd.DisplayName[0].Value?.trim()?.toLowerCase() ===
            name?.trim()?.toLowerCase()
        );
        parentName = found?.DisplayName[0].Value;

        if (!found) renameFlag = true;
      }

      return { renameFlag, parentFlag, parentName };
    },
    []
  );

  const renameTargetItems = (
    item: NodeSetEditorData,
    name: string,
    treeId: string
  ) => {
    if (
      state.targetNodeMappedData &&
      state.targetNodeMappedData.size > 0 &&
      treeId === "tree-2" &&
      name.trim()
    ) {
      const validationFunction = renameDuplicatesValidation(
        item,
        name,
        Array.from(state.targetNodeMappedData?.values())
      );
      const renameObject = new Map(state.targetNodeMappedData);

      if (validationFunction.renameFlag) {
        const nameDisplay =
          item?.additionalText && item.additionalText?.length > 0
            ? `${name}, ${item?.additionalText}`
            : name;

        renameObject.set(item.index, {
          ...item,
          data: (
            <ItemDisplayElement
              index={item.index}
              nodeClass={item.NodeClass}
              folderOrNot={item.isFolder}
              titleTooltip={item.TitleTooltip || ""}
              name={nameDisplay}
              setSelectedFocusedItem={setSelectedFocusedItem}
            />
          ),
          DisplayName: [{ Locale: "", Value: name }],
          modifiedName: nameDisplay,
        });

        setState((draft) => {
          draft.targetNodeMappedData = renameObject;
          draft.changeTargetFlag = true;
        });
      } else {
        let msg = "";
        if (validationFunction.parentFlag) {
          msg = `${name} Already Exists under this Parent Node - ${validationFunction.parentName}`;
        } else {
          msg = `${validationFunction.parentName} already exists`;
        }
        dispatch({
          type: "SHOW_NOTIFICATION",
          notificationType: "alarm",
          message: msg,
        });
      }
    }
  };

  const clickButtonEvent = () => {
    if (
      selectedItems &&
      state.selectedItemsInTarget &&
      state.targetNodeMappedData
    ) {
      const findValue: NodeSetEditorData | undefined =
        state.selectedItemsInTarget;
      if (findValue && findValue?.DisplayName[0]?.Value) {
        setState((draft) => {
          draft.renamedItemObject = findValue;
        });
        setMenuFlag(false);
      }
    }
  };

  function renameOnBlur(
    targetData: Map<TreeItemIndex, NodeSetEditorData>,
    nameCheck: {
      renameFlag: boolean;
      parentFlag: boolean;
      parentName: string | undefined;
    },
    nameNew: string
  ) {
    setState((updateState) => {
      updateState.targetNodeMappedData = targetData;
      updateState.changeTargetFlag = true;

      if (nameCheck.renameFlag && nameNew && nameNew?.trim().length > 0) {
        updateState.renamedItemObject = null;
      } else if (
        !nameCheck.renameFlag &&
        nameNew?.toLowerCase() !==
          state.renamedItemObject?.DisplayName[0].Value?.toLowerCase()
      ) {
        updateState.renamedItemObject = null;
        let msg = "";
        if (nameCheck.parentFlag) {
          msg = `${nameNew} Already Exists under this Parent Node - ${nameCheck.parentName}`;
        } else {
          msg = `${nameCheck.parentName} already exists`;
        }
        dispatch({
          type: "SHOW_NOTIFICATION",
          notificationType: "alarm",
          message: msg,
        });
      }
    });
  }

  const onBlurEvent = useCallback(
    (nameNew: string) => {
      const nameCheck = renameDuplicatesValidation(
        state.renamedItemObject as NodeSetEditorData,
        nameNew,
        Array.from(state.targetNodeMappedData?.values())
      );
      const targetData = new Map(state.targetNodeMappedData);
      const nameApplied: NodeDisplayName[] = [
        {
          Locale: state.renamedItemObject?.DisplayName[0]?.Locale || "",
          Value:
            nameCheck.renameFlag && nameNew && nameNew?.trim().length > 0
              ? nameNew
              : (state.renamedItemObject?.DisplayName[0].Value as string),
        },
      ];

      const nameDisplay =
        state.renamedItemObject?.additionalText &&
        state.renamedItemObject.additionalText?.length > 0
          ? `${nameNew}, ${state.renamedItemObject?.additionalText}`
          : nameNew;

      const nameDisplayNoChange =
        state.renamedItemObject?.additionalText &&
        state.renamedItemObject.additionalText?.length > 0
          ? `${state.renamedItemObject.DisplayName[0].Value}, ${state.renamedItemObject?.additionalText}`
          : state.renamedItemObject?.DisplayName[0].Value || "";

      targetData.set(state.renamedItemObject?.index as TreeItemIndex, {
        ...(state.renamedItemObject as NodeSetEditorData),
        DisplayName: nameApplied,
        data: (
          <ItemDisplayElement
            index={state.renamedItemObject?.index as string}
            nodeClass={state.renamedItemObject?.NodeClass}
            folderOrNot={state.renamedItemObject?.isFolder as boolean}
            titleTooltip={state.renamedItemObject?.TitleTooltip || ""}
            name={
              nameCheck?.renameFlag && nameNew?.trim().length > 0
                ? nameDisplay
                : nameDisplayNoChange
            }
            setSelectedFocusedItem={setSelectedFocusedItem}
          />
        ),
        modifiedName:
          nameCheck?.renameFlag && nameNew?.trim().length > 0
            ? nameDisplay
            : nameDisplayNoChange,
        canMove: true,
      });

      renameOnBlur(targetData, nameCheck, nameNew);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      renameDuplicatesValidation,
      setState,
      state.renamedItemObject,
      state.renamedSelectedIndex,
    ]
  );

  useEffect(() => {
    if (
      state.renamedItemObject &&
      state.renamedItemObject.index &&
      state.renamedItemObject.DisplayName[0].Value
    ) {
      let nameNew = state.renamedItemObject.DisplayName[0].Value;
      const targetData = new Map(state.targetNodeMappedData);

      const references = state.renamedItemObject?.References;

      targetData.set(state.renamedItemObject.index, {
        ...state.renamedItemObject,
        DisplayName: state.renamedItemObject.DisplayName,
        References: references,
        NodeId: state.renamedItemObject?.NodeId,
        NodeClass: state.renamedItemObject?.NodeClass,
        index: state.renamedItemObject.index as string,
        children: state.renamedItemObject.children as string[],
        canMove: false,
        // canRename: true,

        data: (
          <input
            className="inputWidth"
            id={state.renamedItemObject?.index as string}
            defaultValue={nameNew}
            placeholder="Name"
            maxLength={75}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                e.preventDefault();
              }
              e.stopPropagation();
            }}
            onKeyUp={(e) => {
              const text = e.target as HTMLTextAreaElement;

              e.stopPropagation();
              e.preventDefault();

              if (e.key === "Enter") {
                onBlurEvent(text.value?.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
              } else {
                nameNew = text.value;
              }
            }}
            onBlur={() => {
              onBlurEvent(nameNew?.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
            }}
          />
        ),
      });
      setState((updateState) => {
        updateState.targetNodeMappedData = targetData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBlurEvent, setState, state.renamedItemObject]);

  useEffect(() => {
    if (
      state.renamedItemObject &&
      selectedItems[0] !== state.renamedItemObject?.index
    ) {
      const dataTagName = document.getElementById(
        state.renamedItemObject?.index as string
      );
      const findTagName = dataTagName?.tagName?.toLowerCase();
      if (findTagName === "input") {
        onBlurEvent(state.renamedItemObject.DisplayName[0].Value);
        setTimeout(() => {
          setState((updateState) => {
            updateState.renamedItemObject = null;
          });
        }, 300);
      }
    }
  }, [onBlurEvent, selectedItems, setState, state.renamedItemObject]);

  const alreadyExistCheckDND = (target: TreeItemIndex) => {
    let namesSet: string[] = [];
    const compareToWhat = target;
    const nodeToDropItem = state.targetNodeMappedData.get(compareToWhat);
    nodeToDropItem?.children.forEach((p) => {
      const childrenExist = state.targetNodeMappedData.get(p);
      if (childrenExist) {
        const originalName = childrenExist.DisplayName[0].Value;

        namesSet.push(originalName);
      }
    });
    let count: ObjectDND = {};
    namesSet.forEach((i) => {
      count[i] = (count[i] || 0) + 1;
    });

    return namesSet;
  };

  function addNewItemsTargetUaVariable(
    number: number,
    nameDragged: string,
    sourceItemDragged: NodeSetEditorData,
    dragAndDropMap: Map<TreeItemIndex, NodeSetEditorData>,
    parentNodeId: string,
    targetItem: TreeItemIndex,
    droppedFindIndex: NodeSetEditorData
  ) {
    let newItemIndex: TreeItemIndex = "tar" + generateRandomString(8);
    let newNodeId: string = uniqueNodeIdGenerator();
    const nameValue = number > 0 ? `${nameDragged}(${number})` : nameDragged;
    const nameDisplayNew = [
      {
        Locale: sourceItemDragged.DisplayName[0].Locale,
        Value: nameValue,
      },
    ];
    dragAndDropMap.set(newItemIndex, {
      ...sourceItemDragged,
      DisplayName: nameDisplayNew,
      index: newItemIndex,
      NodeId: newNodeId,
      ParentNodeId: parentNodeId,
      data: (
        <ItemDisplayElement
          index={newItemIndex}
          nodeClass={sourceItemDragged.NodeClass}
          folderOrNot={sourceItemDragged.isFolder}
          titleTooltip={sourceItemDragged.TitleTooltip || ""}
          name={`${nameValue} , ${sourceItemDragged.additionalText}`}
          setSelectedFocusedItem={setSelectedFocusedItem}
        />
      ),

      SourceNodeId: sourceItemDragged.NodeId,
      children:
        sourceItemDragged.NodeClass === variablesMappingTool.UAMETHOD
          ? sourceItemDragged.children
          : [],
      hasChildren:
        sourceItemDragged.NodeClass === variablesMappingTool.UAMETHOD &&
        sourceItemDragged.children &&
        sourceItemDragged.children.length > 0
          ? true
          : false,
    });

    dragAndDropMap.set(targetItem, {
      ...droppedFindIndex,

      hasChildren: true,
      children: [...droppedFindIndex.children, newItemIndex],
    });

    setTimeout(() => {
      CreateNewTypeInstanceScroll(newItemIndex as string);
    }, 150);
  }

  function dragItemAddNewItemsInTargetNode(
    newMethodObjects: NodeSetEditorData[],
    x: NodeSetEditorData,
    dragAndDropMap: Map<TreeItemIndex, NodeSetEditorData>
  ) {
    let childrenIndexes: TreeItemIndex[] = [];

    childrenIndexes = newMethodObjects
      .filter((y) => y.parentIndex === x.index)
      .map((z) => z.index);

    let objectTypeBaseReferences: NodeSetEditorDataReferences[] = [];
    objectTypeBaseReferences = setObjectBaseReference(
      x,
      objectTypeBaseReferences
    );
    dragAndDropMap.set(x.index, {
      ...x,
      children: childrenIndexes,
      TypeNodeId: "",
      data: (
        <ItemDisplayElement
          index={x.index}
          nodeClass={x.NodeClass}
          folderOrNot={x.isFolder}
          titleTooltip={x.TitleTooltip || ""}
          name={
            x.NodeClass === variablesMappingTool.UAOBJECT
              ? x.DisplayName[0].Value
              : x.modifiedName
          }
          setSelectedFocusedItem={setSelectedFocusedItem}
        />
      ),
      References:
        x.NodeClass === variablesMappingTool.UAOBJECT
          ? objectTypeBaseReferences
          : x.References,
      additionalText:
        x.NodeClass === variablesMappingTool.UAOBJECT ? "" : x.additionalText,
    });
  }

  const addNewItemInTargetNode = (
    items: TreeItem[] | NodeSetEditorData[],
    targetItem: TreeItemIndex,
    parentNodeId: string
  ) => {
    const sourceItemDragged = items[0] as NodeSetEditorData;

    const alreadyExist = alreadyExistCheckDND(targetItem);
    const nameDragged = sourceItemDragged.DisplayName[0].Value;
    let number = 0;

    for (const r of alreadyExist) {
      if (r.includes(nameDragged)) {
        number++;
      }
    }

    let childsDrag: NodeSetEditorData[] = [];
    const dragAndDropMap = new Map(state.targetNodeMappedData);

    const droppedFindIndex: NodeSetEditorData | undefined = dragAndDropMap.get(
      targetItem
    ) as NodeSetEditorData;

    if (
      sourceItemDragged &&
      droppedFindIndex &&
      sourceItemDragged.NodeClass === variablesMappingTool.UAVARIABLE
    ) {
      addNewItemsTargetUaVariable(
        number,
        nameDragged,
        sourceItemDragged,
        dragAndDropMap,
        parentNodeId,
        targetItem,
        droppedFindIndex
      );
    } else if (
      sourceItemDragged &&
      droppedFindIndex &&
      (sourceItemDragged.NodeClass === variablesMappingTool.UAMETHOD ||
        sourceItemDragged.NodeClass === variablesMappingTool.UAOBJECT) &&
      droppedFindIndex.NodeClass !== variablesMappingTool.UAVARIABLE
    ) {
      if (number > 0) {
        dispatch({
          type: "SHOW_NOTIFICATION",
          notificationType: "alarm",
          message: `${sourceItemDragged.DisplayName[0].Value} already exists inside ${droppedFindIndex.DisplayName[0].Value} `,
        });

        return;
      }
      let newItemIndex: TreeItemIndex = "tar" + generateRandomString(8);
      let newNodeId: string = uniqueNodeIdGenerator();
      let newMethodObjects: NodeSetEditorData[] = [];
      newMethodObjects.push({
        ...sourceItemDragged,
        sourceIndex: sourceItemDragged.index,
        index: newItemIndex,
        NodeId: newNodeId,
        SourceNodeId: sourceItemDragged.NodeId,
        ParentNodeId: parentNodeId,
      });
      childsDrag = findChilds(
        sourceItemDragged.children,
        newItemIndex,
        newNodeId
      );
      newMethodObjects.push(...childsDrag);

      for (let x of newMethodObjects) {
        dragItemAddNewItemsInTargetNode(newMethodObjects, x, dragAndDropMap);
      }
      dragAndDropMap.set(targetItem, {
        ...droppedFindIndex,

        hasChildren: true,
        children: [...droppedFindIndex.children, newItemIndex],
      });
      setTimeout(() => {
        CreateNewTypeInstanceScroll(newItemIndex as string);
      }, 150);
    }

    setExpandedItems([...expandedItems, targetItem]);

    setState((updateState) => {
      updateState.targetNodeMappedData = dragAndDropMap;
    });
  };

  let returnedEl: NodeSetEditorData;

  const sourceCheck = (item: NodeSetEditorData[]) => {
    if (state.sourceNodeMappedData && item) {
      const sourceData: NodeSetEditorData | undefined =
        state.sourceNodeMappedDataNodeKey.get(item[0].ParentNodeId as string);
      returnedEl = sourceData ? sourceData : item[0];
      if (
        sourceData &&
        sourceData.ParentNodeId &&
        (sourceData.NodeClass !== variablesMappingTool.UAOBJECTTYPE ||
          (sourceData.NodeClass !== variablesMappingTool.UAOBJECT &&
            sourceData.ParentNodeId !== REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE))
      ) {
        sourceCheck([sourceData]);
      }
    }

    return returnedEl;
  };
  let elementTarget = null;

  const targetCheckParent = (target: NodeSetEditorData) => {
    const targetObjectElement = state.targetNodeMappedDataNodeKey.get(
      target.ParentNodeId as string
    );

    elementTarget = targetObjectElement ? targetObjectElement : target;

    if (
      targetObjectElement &&
      targetObjectElement.ParentNodeId &&
      (targetObjectElement.NodeClass !== variablesMappingTool.UAOBJECTTYPE ||
        (targetObjectElement.NodeClass !== variablesMappingTool.UAOBJECT &&
          targetObjectElement.ParentNodeId !==
            REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE))
    ) {
      targetCheckParent(targetObjectElement);
    }
    return elementTarget;
  };

  function dropOverItemMain(
    foundSourceNode: NodeSetEditorData,
    parentIdDropFromSourceItems: NodeSetEditorData,
    dropToTargetMapped: Map<TreeItemIndex, NodeSetEditorData>,
    target: DragNodes
  ) {
    let childsDragMap: NodeSetEditorData[] = [];

    if (foundSourceNode.NodeClass === variablesMappingTool.UAMETHOD) {
      childsDragMap = findChilds(
        foundSourceNode.children,
        parentIdDropFromSourceItems.index,
        parentIdDropFromSourceItems.NodeId
      );

      const childrensMapping = parentIdDropFromSourceItems.children;
      childrensMapping.forEach((s, idx) => {
        const sourceDataToMap = childsDragMap[idx];

        const findIndexObject = dropToTargetMapped.get(s);
        if (findIndexObject && sourceDataToMap) {
          dropToTargetMapped.set(s, {
            ...findIndexObject,
            data: (
              <ItemDisplayElement
                index={findIndexObject.index}
                nodeClass={findIndexObject.NodeClass}
                folderOrNot={findIndexObject.isFolder}
                titleTooltip={findIndexObject.TitleTooltip || ""}
                name={sourceDataToMap.modifiedName}
                setSelectedFocusedItem={setSelectedFocusedItem}
              />
            ),
            SourceNodeId: findIndexObject.NodeId,
          });
        }
      });
    }

    dropToTargetMapped.set(target.targetItem, {
      ...parentIdDropFromSourceItems,
      data: (
        <ItemDisplayElement
          index={parentIdDropFromSourceItems.index}
          nodeClass={parentIdDropFromSourceItems.NodeClass}
          folderOrNot={parentIdDropFromSourceItems.isFolder}
          titleTooltip={parentIdDropFromSourceItems.TitleTooltip || ""}
          name={foundSourceNode.modifiedName}
          setSelectedFocusedItem={setSelectedFocusedItem}
        />
      ),
      DisplayName: foundSourceNode.DisplayName,
      SourceNodeId: foundSourceNode.NodeId,
      modifiedName: foundSourceNode.modifiedName,
      additionalText: foundSourceNode.additionalText,
    });
    setExpandedItems([...expandedItems, target.targetItem]);

    setState((draft) => {
      draft.targetNodeMappedData = dropToTargetMapped;
    });
  }

  function dropOverItem(
    parentIdDropFromSourceItems: NodeSetEditorData,
    foundSourceNode: NodeSetEditorData,
    dropToTargetMapped: Map<TreeItemIndex, NodeSetEditorData>,
    target: DragNodes,
    items: NodeSetEditorData[] | TreeItem<any>[]
  ) {
    let isAssociatedItem = parentIdDropFromSourceItems?.elementAssociated;
    let targetNodeClassType = parentIdDropFromSourceItems?.NodeClass;

    const isParentUAMethod =
      targetNodeClassType === variablesMappingTool.UAMETHOD;

    const checkTargetItemIsChildOnly =
      parentIdDropFromSourceItems?.NodeClass ===
        variablesMappingTool.UAVARIABLE &&
      parentIdDropFromSourceItems.References.find((f) => f.Value === "i=68");
    const checkSourceItemChildOnly =
      foundSourceNode?.NodeClass === variablesMappingTool.UAVARIABLE &&
      foundSourceNode.References.find((p) => p.Value === "i=68");
    if (
      isAssociatedItem &&
      foundSourceNode.NodeClass === parentIdDropFromSourceItems.NodeClass &&
      ((checkTargetItemIsChildOnly && checkSourceItemChildOnly) ||
        (!checkTargetItemIsChildOnly && !checkSourceItemChildOnly) ||
        (foundSourceNode.NodeClass === variablesMappingTool.UAMETHOD &&
          isParentUAMethod))
    ) {
      dropOverItemMain(
        foundSourceNode,
        parentIdDropFromSourceItems,
        dropToTargetMapped,
        target
      );
    } else if (!checkTargetItemIsChildOnly && !isParentUAMethod) {
      if (parentIdDropFromSourceItems && parentIdDropFromSourceItems.NodeId) {
        addNewItemInTargetNode(
          items,
          target.targetItem,
          parentIdDropFromSourceItems?.NodeId
        );
      }
    }
  }

  function dropOverItemFromSource(
    dropToTargetMapped: Map<TreeItemIndex, NodeSetEditorData>,
    target: DragNodes,
    sourceCheckValue: NodeSetEditorData,

    foundSourceNode: NodeSetEditorData,
    items: NodeSetEditorData[] | TreeItem<any>[]
  ) {
    const parentIdDropFromSourceItems = dropToTargetMapped.get(
      target.targetItem
    );
    const targetCheckValue = targetCheckParent(
      parentIdDropFromSourceItems as NodeSetEditorData
    );
    if (
      (parentIdDropFromSourceItems &&
        sourceCheckValue?.NodeClass === variablesMappingTool.UAOBJECTTYPE &&
        targetCheckValue.NodeClass === variablesMappingTool.UAOBJECTTYPE) ||
      (parentIdDropFromSourceItems &&
        sourceCheckValue?.NodeClass === variablesMappingTool.UAOBJECT &&
        sourceCheckValue?.ParentNodeId ===
          REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE)
    ) {
      dropOverItem(
        parentIdDropFromSourceItems,
        foundSourceNode,
        dropToTargetMapped,
        target,
        items
      );
    } else {
      displayToastForOverItem(
        parentIdDropFromSourceItems,
        sourceCheckValue,
        targetCheckValue
      );
    }
  }

  function dropFromTarget(
    dropToTargetMapped: Map<TreeItemIndex, NodeSetEditorData>,
    target: DragNodes,
    items: NodeSetEditorData[] | TreeItem<any>[]
  ) {
    const droppingItem = dropToTargetMapped.get(target.targetItem);

    let checkIfVariableProperty = false;
    if (
      droppingItem?.NodeClass === variablesMappingTool.UAVARIABLE &&
      droppingItem?.References.find(
        (s) => s.ReferenceType === HAS_TYPE_DEFINITION && s.Value === "i=68"
      )
    ) {
      checkIfVariableProperty = true;
    }

    const draggedItem = dropToTargetMapped.get(items[0].index);
    const parentItem = state.targetNodeMappedDataNodeKey.get(
      draggedItem?.ParentNodeId as string
    );

    if (!checkIfVariableProperty && parentItem && draggedItem && droppingItem) {
      const parentItemObject = dropToTargetMapped.get(parentItem.index);

      const parentItemChildrens: TreeItemIndex[] | undefined =
        parentItemObject?.children.filter(
          (child) => child !== draggedItem.index
        );

      const referenceItems: NodeSetEditorDataReferences[] | undefined =
        parentItemObject?.References.filter(
          (child) => child.Value !== draggedItem?.NodeId
        );

      const referenceMappedToItem: NodeSetEditorDataReferences[] | undefined =
        parentItemObject?.References.filter(
          (child) => child.Value === draggedItem?.NodeId
        );

      const updateReferenceNodes: NodeSetEditorDataReferences[] | undefined =
        draggedItem?.References.map((item) => {
          if (item.Value === parentItem.NodeId) {
            return {
              ...item,
              Value: droppingItem.NodeId,
            };
          }
          return item;
        });
      if (
        parentItemChildrens &&
        referenceItems &&
        referenceMappedToItem &&
        updateReferenceNodes
      ) {
        dropToTargetMapped.set(parentItem.index, {
          ...parentItem,
          children: parentItemChildrens,
          References: referenceItems,
        });
        dropToTargetMapped.set(target.targetItem, {
          ...droppingItem,

          hasChildren: true,
          children: [...droppingItem.children, draggedItem.index],
        });

        dropToTargetMapped.set(items[0].index, {
          ...draggedItem,
          References: updateReferenceNodes,
          ParentNodeId: droppingItem.NodeId,
        });
        setState((updateState) => {
          updateState.targetNodeMappedData = dropToTargetMapped;
        });
      }
    }
  }

  const OnDropOfSourceMain = (
    items: TreeItem[] | NodeSetEditorData[],
    target: DragNodes | DraggingPosition
  ) => {
    const dropToTargetMapped: Map<TreeItemIndex, NodeSetEditorData> = new Map(
      state.targetNodeMappedData
    );
    const sourceCheckValue = sourceCheck(items as NodeSetEditorData[]);
    const foundSourceNode = state.sourceNodeMappedData?.get(items[0].index);

    if (
      target.treeId === "tree-2" &&
      target.targetType === "item" &&
      target.parentItem !== "target" &&
      target.parentItem !== "targetRoot" &&
      foundSourceNode
    ) {
      dropOverItemFromSource(
        dropToTargetMapped,
        target,
        sourceCheckValue,
        foundSourceNode,
        items
      );
    } else if (
      target.treeId === "tree-2" &&
      target.targetType === "between-items" &&
      target.parentItem !== "targetObjects" &&
      target.parentItem !== "targetTypes" &&
      target.parentItem !== "targetRoot" &&
      target.parentItem !== "target" &&
      foundSourceNode
    ) {
      const parentIdBetweenItems = state.targetNodeMappedData.get(
        target.parentItem
      );

      const targetCheckValue = targetCheckParent(
        parentIdBetweenItems as NodeSetEditorData
      );
      if (
        (parentIdBetweenItems &&
          parentIdBetweenItems.NodeId &&
          parentIdBetweenItems.NodeClass !== variablesMappingTool.UAMETHOD &&
          sourceCheckValue?.NodeClass === variablesMappingTool.UAOBJECTTYPE &&
          targetCheckValue.NodeClass === variablesMappingTool.UAOBJECTTYPE) ||
        (parentIdBetweenItems &&
          sourceCheckValue?.NodeClass === variablesMappingTool.UAOBJECT &&
          sourceCheckValue?.ParentNodeId ===
            REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE)
      ) {
        addNewItemInTargetNode(
          items,
          target.parentItem,
          parentIdBetweenItems?.NodeId
        );
      } else {
        displayToastForOverItem(
          parentIdBetweenItems,
          sourceCheckValue,
          targetCheckValue
        );
      }
    }
  };

  function dropToOrFromTarget(
    target: DraggingPosition | DragNodes,
    targetIdx: NodeSetEditorData | undefined,
    items: NodeSetEditorData[] | TreeItem<any>[],
    foundSourceNode: NodeSetEditorData | undefined
  ) {
    if (
      target.treeId === "tree-2" &&
      targetIdx &&
      target.targetType === "item" &&
      target.parentItem !== "targetRoot" &&
      target.parentItem !== "target"
    ) {
      dropFromTargetToTarget(items, target, targetIdx);
    } else if (
      foundSourceNode?.NodeClass !== variablesMappingTool.UAOBJECTTYPE
    ) {
      OnDropOfSourceMain(items, target);
    }
  }
  const OnDropOfSource = (
    items: TreeItem[] | NodeSetEditorData[],
    target: DragNodes | DraggingPosition
  ) => {
    const foundSourceNode = state.sourceNodeMappedData?.get(items[0].index);
    const checkParentItems = items[0] as NodeSetEditorData;

    if (
      (foundSourceNode?.NodeClass === variablesMappingTool.UAOBJECTTYPE &&
        target.targetType === "between-items" &&
        target.parentItem === "targetTypes") ||
      (foundSourceNode?.NodeClass === variablesMappingTool.UAOBJECTTYPE &&
        target.targetType === "item" &&
        target.targetItem === "targetTypes")
    ) {
      copyTypeFunction(variablesMappingTool.TYPE);
    } else if (
      (foundSourceNode?.NodeClass === variablesMappingTool.UAOBJECT &&
        target.targetType === "between-items" &&
        target.parentItem === "targetObjects") ||
      (foundSourceNode?.NodeClass === variablesMappingTool.UAOBJECT &&
        target.targetType === "item" &&
        target.targetItem === "targetObjects")
    ) {
      copyTypeFunction(variablesMappingTool.OBJECT);
    } else {
      const targetIdx = state.targetNodeMappedData.get(items[0].index);

      const checkItemParentNodeIsMethod =
        checkParentItems &&
        checkParentItems.NodeClass === variablesMappingTool.UAVARIABLE &&
        checkParentItems.ParentNodeId &&
        state.sourceNodeMappedDataNodeKey.get(checkParentItems.ParentNodeId)
          ?.NodeClass === variablesMappingTool.UAMETHOD;
      if (checkItemParentNodeIsMethod) {
        return;
      }

      const checkItemParentNodeIsMethodTarget =
        checkParentItems &&
        checkParentItems.NodeClass === variablesMappingTool.UAVARIABLE &&
        checkParentItems.ParentNodeId &&
        state.targetNodeMappedDataNodeKey.get(checkParentItems.ParentNodeId)
          ?.NodeClass === variablesMappingTool.UAMETHOD;
      if (checkItemParentNodeIsMethodTarget) {
        return;
      }
      dropToOrFromTarget(target, targetIdx, items, foundSourceNode);
    }
  };

  const dropFromTargetToTarget = (
    items: TreeItem[] | NodeSetEditorData[],
    target: DragNodes,
    targetIdx: NodeSetEditorData
  ) => {
    const dropToTargetMapped = new Map(state.targetNodeMappedData);
    if (target.parentItem === targetIdx.index) return;
    const targetIdxOfParent = state.targetNodeMappedData.get(target.targetItem);
    if (targetIdxOfParent?.children.find((f) => f === targetIdx.index)) {
      return;
    }

    const checkItemParentNodeIfMethod =
      targetIdx.NodeClass === variablesMappingTool.UAVARIABLE &&
      targetIdx.ParentNodeId &&
      state.targetNodeMappedDataNodeKey.get(targetIdx.ParentNodeId)
        ?.NodeClass === variablesMappingTool.UAMETHOD;

    if (checkItemParentNodeIfMethod) {
      return;
    }

    if (
      (targetIdx.NodeClass === variablesMappingTool.UAMETHOD &&
        targetIdxOfParent?.NodeClass === variablesMappingTool.UAVARIABLE) ||
      targetIdxOfParent?.NodeClass === variablesMappingTool.UAMETHOD
    ) {
      return;
    }

    const parentIdTargetOnly = state.targetNodeMappedData.get(
      target.targetItem
    );

    const targetCheckValueSource = targetCheckParent(
      items[0] as NodeSetEditorData
    );
    const targetCheckValueTarget = targetCheckParent(
      parentIdTargetOnly as NodeSetEditorData
    );

    if (
      (parentIdTargetOnly &&
        targetCheckValueSource?.NodeClass ===
          variablesMappingTool.UAOBJECTTYPE &&
        targetCheckValueTarget.NodeClass ===
          variablesMappingTool.UAOBJECTTYPE) ||
      (parentIdTargetOnly &&
        targetCheckValueSource?.NodeClass === variablesMappingTool.UAOBJECT &&
        targetCheckValueTarget.NodeClass === variablesMappingTool.UAOBJECT &&
        targetCheckValueSource?.ParentNodeId ===
          REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE)
    ) {
      dropFromTarget(dropToTargetMapped, target, items);
    } else {
      dispatch({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Nodes from different nodes cannot be placed. ",
      });
    }
  };

  const getChilds = useCallback(
    (childs: TreeItemIndex[], parentIndex: TreeItemIndex) => {
      let getChildrens: NodeSetEditorData[] = [];
      if (state.targetNodeMappedData && state.targetNodeMappedData.size > 0) {
        childs.forEach((child) => {
          let findChild: NodeSetEditorData | undefined =
            state.targetNodeMappedData.get(child);

          if (findChild) {
            let deleteChildObj: NodeSetEditorData = {
              ...findChild,
              parentIndex: parentIndex,
            };
            if (deleteChildObj.children.length > 0) {
              let getItsChilds = getChilds(findChild.children, findChild.index);
              getChildrens.push(...getItsChilds);
            }
            getChildrens.push(deleteChildObj);
          }
        });
      }
      return getChildrens;
    },
    [state.targetNodeMappedData]
  );

  const disableDelete = () => {
    let disableDeleteTemp = false;
    if (state.selectedItemsInTarget) {
      const getKey = state.targetNodeMappedDataNodeKey.get(
        state.selectedItemsInTarget?.ParentNodeId as string
      );

      if (getKey?.NodeClass === variablesMappingTool.UAMETHOD) {
        disableDeleteTemp = true;
      }
    }

    if (
      selectedItems[0] === "targetObjects" ||
      selectedItems[0] === "targetTypes"
    ) {
      disableDeleteTemp = true;
    }
    return disableDeleteTemp;
  };

  const findInstanceAssociatedWithTypeToBeDeleted = (
    toBeDeletedType: NodeSetEditorData
  ) => {
    let foundInstance = true;
    if (toBeDeletedType?.NodeClass !== variablesMappingTool.UAOBJECTTYPE) {
      return true;
    }

    const targetObjectKeysDel: string[] =
      state.treeItems["targetObjects"]?.children;
    if (
      targetObjectKeysDel &&
      targetObjectKeysDel.length > 0 &&
      toBeDeletedType
    ) {
      targetObjectKeysDel.forEach((t) => {
        const foundType = state.targetNodeMappedData.get(t);
        if (
          foundType &&
          foundType.DisplayName &&
          foundType.DisplayName[0].Value
        ) {
          const foundReferenceAssociated = foundType.References.find(
            (re) => re.Value === toBeDeletedType?.NodeId
          );
          if (foundReferenceAssociated) {
            setState((updateState) => {
              updateState.associatedInstanceFound =
                foundType.DisplayName[0].Value;
              updateState.openAssociatedTypeModal = true;
            });
            foundInstance = false;
            associateTypeModalRenderer(
              variablesMappingTool.TYPE,
              undefined,
              undefined,
              foundType.DisplayName[0].Value
            );

            return;
          }
        }
      });
    }
    return foundInstance;
  };

  const deleteItemsInTargetNode = useCallback(() => {
    if (
      state.targetNodeMappedData &&
      state.targetNodeMappedData.size > 0 &&
      state.selectedItemsInTarget
    ) {
      let newDeleteTargetObjects: NodeSetEditorData[] = [];

      let selectedItem: NodeSetEditorData = state.selectedItemsInTarget;

      const deleteMaps = new Map(state.targetNodeMappedData);

      if (selectedItem) {
        newDeleteTargetObjects.push({ ...selectedItem });
        let deleteChildTargetObject = getChilds(
          selectedItem.children,
          selectedItem.index
        );
        newDeleteTargetObjects.push(...deleteChildTargetObject);

        let deleteIndexs = newDeleteTargetObjects.map((x) => x.index);
        deleteIndexs.forEach((p) => {
          deleteMaps.delete(p);
        });
        const gotParentNodeKey = new Map(state.targetNodeMappedDataNodeKey);

        if (
          selectedItem.NodeClass !== variablesMappingTool.UAOBJECTTYPE &&
          selectedItem.ParentNodeId &&
          selectedItem.ParentNodeId !==
            REFERENCE_UA_PARENT_OBJECT_EIGHTY_FIVE &&
          gotParentNodeKey
        ) {
          deleteChildrenIndexFromParent(
            gotParentNodeKey,
            selectedItem,
            deleteMaps
          );
        }
      }

      setState((draft) => {
        draft.targetNodeMappedData = deleteMaps;
        draft.changeTargetFlag = true;
      });
      setMenuFlag(false);
      setSelectedItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getChilds,
    setState,
    state.selectedItemsInTarget,
    state.targetNodeMappedData,
  ]);

  useEffect(() => {
    const handleClick = () => setMenuFlag(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  // Create New Folder Below
  const newFolderCreation = () => {
    if (
      state.targetNodeMappedData &&
      state.targetNodeMappedData.size > 0 &&
      state.selectedItemsInTarget &&
      (state.selectedItemsInTarget.NodeClass ===
        variablesMappingTool.UAOBJECT ||
        state.selectedItemsInTarget.NodeClass ===
          variablesMappingTool.UAOBJECTTYPE)
    ) {
      const parentType = state.selectedItemsInTarget;

      //  expanding tree when adding instance or type
      const expandedEl = [...expandedItems];

      expandedEl.push(parentType.index);

      const uniqueExpandedEl = Array.from(new Set(expandedEl));

      setExpandedItems(uniqueExpandedEl);
      //  expanding tree when adding instance or type
      const clonedTargetMappedNewFolder = new Map(state.targetNodeMappedData);

      const namesOfFolders: number[] = [];
      parentType.children.forEach((y) => {
        const folderIsTrue = clonedTargetMappedNewFolder.get(y);
        if (
          folderIsTrue &&
          folderIsTrue.isFolder &&
          folderIsTrue.DisplayName &&
          folderIsTrue.DisplayName[0].Value &&
          folderIsTrue.DisplayName[0].Value.toLowerCase().includes("new folder")
        ) {
          const onlyNumber = getNumFromStr(folderIsTrue.DisplayName[0].Value);
          if (onlyNumber) namesOfFolders.push(onlyNumber);
        }
      });

      let numbersObj = nextNumber(namesOfFolders);

      const folderName = "New Folder" + numbersObj;

      const newIdOfFolderCreated = uniqueNodeIdGenerator();

      const newItemIndex = "tar" + generateRandomString(8);

      const referencesTypes: NodeSetEditorDataReferences = {
        ReferenceType: HAS_COMPONENT,
        IsForward: true,
        Value: newIdOfFolderCreated,
      };

      if (
        parentType &&
        Array.isArray(parentType.References) &&
        Array.isArray(parentType.children)
      ) {
        const folderObject = {
          EventNotifier: 1,
          ParentNodeId: parentType.NodeId,
          DisplayName: [
            {
              Locale: "",
              Value: folderName,
            },
          ],
          Description: null,
          Category: null,
          Documentation: null,
          References: [
            {
              ReferenceType: HAS_TYPE_DEFINITION,
              IsForward: true,
              Value: "i=61",
            },

            {
              ReferenceType: HAS_COMPONENT,
              IsForward: false,
              Value: parentType.NodeId,
            },
          ],

          RolePermissions: null,
          Extensions: null,
          NodeId: newIdOfFolderCreated,
          BrowseName: "1:" + folderName,
          WriteMask: 0,
          UserWriteMask: 0,
          AccessRestrictions: 0,
          AccessRestrictionsSpecified: false,
          HasNoPermissions: false,
          SymbolicName: null,
          ReleaseStatus: 0,
          NodeClass: variablesMappingTool.UAOBJECT,
          data: (
            <ItemDisplayElement
              index={newItemIndex}
              nodeClass={variablesMappingTool.UAOBJECT}
              folderOrNot={true}
              titleTooltip="Object"
              name={folderName}
              setSelectedFocusedItem={setSelectedFocusedItem}
            />
          ),
          index: newItemIndex,
          children: [],
          hasChildren: false,
          isFolder: true,
          TitleTooltip: "Object",
          modifiedName: folderName,
          canRename: true,
        };

        let newReference: NodeSetEditorDataReferences[] = [
          ...parentType.References,
        ];

        newReference.push(referencesTypes);
        clonedTargetMappedNewFolder.set(newItemIndex, folderObject);

        let childrenNew = [...parentType.children];
        childrenNew.push(newItemIndex);

        clonedTargetMappedNewFolder.set(parentType.index, {
          ...parentType,
          References: newReference,
          children: childrenNew,
          hasChildren: true,
        });

        setState((updateState) => {
          updateState.targetNodeMappedData = clonedTargetMappedNewFolder;
          updateState.changeTargetFlag = true;
        });
      }
      setTimeout(() => {
        setMenuFlag(false);
        setSelectedItems([]);
        CreateNewTypeInstanceScroll(newItemIndex);
      }, 500);
    }
  };

  const getAssociateItems = (
    childs: TreeItemIndex[],
    parentIndex: TreeItemIndex,
    parentNodeId: string
  ) => {
    let itemAssociated: NodeSetEditorData[] = [];
    childs.forEach((child) => {
      let childInfo: NodeSetEditorData | undefined =
        state.targetNodeMappedData.get(child);

      if (childInfo) {
        const newIndex: TreeItemIndex = "tar" + generateRandomString(8);

        let newItemObj: NodeSetEditorData = {
          ...childInfo,
          sourceIndex: childInfo.index,
          index: newIndex,
          parentIndex: parentIndex,
          DisplayName: [{ Locale: "", Value: childInfo.DisplayName[0].Value }],
          NodeId: uniqueNodeIdGenerator(),
          data: childInfo.DisplayName[0].Value,
          TypeNodeId: childInfo.NodeId,
          ParentNodeId: parentNodeId,
          SourceNodeId: "",
        };
        if (newItemObj.children.length > 0) {
          let getObjs = getAssociateItems(
            childInfo.children,
            newIndex,
            newItemObj.NodeId
          );
          itemAssociated.push(...getObjs);
        }
        itemAssociated.push(newItemObj);
      }
    });

    return itemAssociated;
  };

  const associatedSetData = (
    selectedTargetInstance: NodeSetEditorData,
    associateReferences: NodeSetEditorDataReferences[],
    selectedType: NodeSetEditorData,
    associatedTargetMapped: Map<TreeItemIndex, NodeSetEditorData>,
    references: TreeItemIndex[],
    modifiedAssociatedObjects: Map<TreeItemIndex, NodeSetEditorData>
  ) => {
    let referenceArray = [
      ...selectedTargetInstance.References,
      ...associateReferences,
    ];
    const referenceArrayIndex = referenceArray.findIndex(
      (s) => s.ReferenceType === HAS_TYPE_DEFINITION
    );

    if (referenceArrayIndex !== -1) {
      referenceArray.splice(referenceArrayIndex, 1, {
        ReferenceType: HAS_TYPE_DEFINITION,
        IsForward: true,
        Value: selectedType.NodeId,
      });
    }

    associatedTargetMapped.set(selectedType?.index, {
      ...selectedType,
      isAnAssociatedType: true,
    });

    associatedTargetMapped.set(selectedTargetInstance?.index, {
      ...selectedTargetInstance,
      References: referenceArray,
      children: [...references],
      TypeNodeId: selectedType.NodeId,
      hasChildren: true,
      modifiedName: `${selectedTargetInstance.DisplayName[0].Value}, ${selectedType.DisplayName[0].Value}`,
      additionalText: selectedType.DisplayName[0].Value,
      data: (
        <ItemDisplayElement
          index={selectedTargetInstance.index}
          nodeClass={selectedTargetInstance.NodeClass}
          folderOrNot={selectedTargetInstance.isFolder}
          titleTooltip={selectedTargetInstance.TitleTooltip || ""}
          name={`${selectedTargetInstance.DisplayName[0].Value}, ${selectedType.DisplayName[0].Value}`}
          setSelectedFocusedItem={setSelectedFocusedItem}
        />
      ),
    });
    for (const [key, value] of modifiedAssociatedObjects) {
      associatedTargetMapped.set(key, value);
    }

    setState((draft) => {
      draft.targetNodeMappedData = associatedTargetMapped;
      draft.changeTargetFlag = true;
    });
    setMenuFlag(false);
  };

  const disassociateTypeExisting = (
    selectedDisassociateTargetInstance: NodeSetEditorData
  ) => {
    const disassociatedTargetMapped = new Map(state.targetNodeMappedData);

    let disassociateAlreadyAssociatedType: NodeSetEditorData[] = getChilds(
      selectedDisassociateTargetInstance.children,
      selectedDisassociateTargetInstance.index
    );
    let deleteAssociateIndexes: TreeItemIndex[] =
      disassociateAlreadyAssociatedType.map((x) => x.index);

    let referenceArrayDisassociated =
      selectedDisassociateTargetInstance.References.map((sx) => {
        if (sx.ReferenceType === HAS_TYPE_DEFINITION) {
          return {
            ...sx,
            Value: BASE_OBJECT_REFERENCE_VALUE,
          };
        }
        return sx;
      });

    const newObjectDisassociated = {
      ...selectedDisassociateTargetInstance,
      References: referenceArrayDisassociated,
      modifiedName: `${selectedDisassociateTargetInstance.DisplayName[0].Value}`,
      additionalText: "",
      data: (
        <ItemDisplayElement
          index={selectedDisassociateTargetInstance.index}
          nodeClass={selectedDisassociateTargetInstance.NodeClass}
          folderOrNot={selectedDisassociateTargetInstance.isFolder}
          titleTooltip={selectedDisassociateTargetInstance.TitleTooltip || ""}
          name={`${selectedDisassociateTargetInstance.DisplayName[0].Value}`}
          setSelectedFocusedItem={setSelectedFocusedItem}
        />
      ),
    };

    disassociatedTargetMapped.set(
      selectedDisassociateTargetInstance?.index,
      newObjectDisassociated
    );
    deleteAssociateIndexes.forEach((d) => {
      const foundObjectsToDissociate = disassociatedTargetMapped.get(d);
      if (foundObjectsToDissociate && foundObjectsToDissociate.index) {
        const classNameOpaque = foundObjectsToDissociate.isFolder
          ? "flexColumn"
          : "flexColumn setOpacity";

        const childObjects = {
          ...foundObjectsToDissociate,
          data: (
            <ItemDisplayElement
              index={foundObjectsToDissociate.index}
              nodeClass={foundObjectsToDissociate.NodeClass}
              folderOrNot={foundObjectsToDissociate.isFolder}
              titleTooltip={foundObjectsToDissociate.TitleTooltip || ""}
              name={foundObjectsToDissociate.modifiedName}
              setSelectedFocusedItem={setSelectedFocusedItem}
              className={classNameOpaque}
            />
          ),
          elementAssociated: true,
        };

        disassociatedTargetMapped.set(
          foundObjectsToDissociate?.index,
          childObjects
        );
      }
    });
    setState((draft) => {
      draft.targetNodeMappedData = disassociatedTargetMapped;
      draft.changeTargetFlag = true;
    });
    setMenuFlag(false);
  };

  const associatedTypeToInstance = (
    selectedType: NodeSetEditorData,
    selectedTargetInstance: NodeSetEditorData
  ) => {
    const associatedTargetMapped = new Map(state.targetNodeMappedData);
    setLoadTimer(true);

    let deleteAlreadyAssociatedType: NodeSetEditorData[] = getChilds(
      selectedTargetInstance.children,
      selectedTargetInstance.index
    );
    let deleteIndexes: TreeItemIndex[] = deleteAlreadyAssociatedType.map(
      (x) => x.index
    );

    deleteIndexes.forEach((p) => {
      associatedTargetMapped.delete(p);
    });

    let associateReferences: NodeSetEditorDataReferences[] = [];

    let itemsAssociated = getAssociateItems(
      selectedType.children,
      selectedType.index,
      selectedTargetInstance.NodeId
    );

    // finding associated children
    const modifiedAssociatedObjects = new Map(
      itemsAssociated.map((x) => {
        let childrenAssociates: TreeItemIndex[] = [];

        childrenAssociates = itemsAssociated
          .filter((y) => y.parentIndex === x.index)
          .map((z) => z.index);

        // Type Node Id=== Node ID of children of UAOBJECTTYPE
        //  ParentIndex=== index of UA Object Type

        let createReference: NodeSetEditorDataReferences[] = [];

        createReference = x.References?.map(
          (y: NodeSetEditorDataReferences) => {
            let nodeId: string | undefined | boolean = y.Value;

            const referenceTrue =
              y.ReferenceType === HAS_TYPE_DEFINITION ||
              y.ReferenceType === HAS_COMPONENT ||
              y.ReferenceType === HAS_PROPERTY;

            nodeId =
              referenceTrue &&
              (!y.IsForward
                ? x.ParentNodeId
                : itemsAssociated.find((xy) => xy.TypeNodeId === y.Value)
                    ?.NodeId);

            return {
              ...y,
              Value: nodeId ? nodeId : y.Value,
            };
          }
        );

        const classNameOpaque = x.isFolder
          ? "flexColumn"
          : "flexColumn setOpacity";

        return [
          x.index,
          {
            ...x,
            children: childrenAssociates,
            data: (
              <ItemDisplayElement
                index={x.index}
                nodeClass={x.NodeClass}
                folderOrNot={x.isFolder}
                titleTooltip={x.TitleTooltip || ""}
                name={x.modifiedName}
                setSelectedFocusedItem={setSelectedFocusedItem}
                className={classNameOpaque}
              />
            ),
            References: createReference,
            elementAssociated: true,
          },
        ];
      })
    );
    // finding associated children

    let references: TreeItemIndex[] = itemsAssociated
      .filter((y) => y.parentIndex === selectedType.index)
      .map((z) => z.index);

    references &&
      references.length > 0 &&
      references.forEach((refs) => {
        let refsAssociatedNodeId: NodeSetEditorData | undefined =
          itemsAssociated.find((sa) => sa.index === refs);

        if (refsAssociatedNodeId) {
          const associateReferencesValue =
            state.targetNodeMappedDataNodeKey.get(
              refsAssociatedNodeId?.TypeNodeId as string
            );

          let referenceExists: NodeSetEditorDataReferences[] | undefined =
            refsAssociatedNodeId.References.filter(
              (x) => x.Value === associateReferencesValue?.ParentNodeId
            );

          referenceExists &&
            referenceExists.length > 0 &&
            associateReferences.push({
              ...referenceExists[0],
              IsForward: true,
              Value: refsAssociatedNodeId.NodeId,
            });
        }
      });

    // with new Map Associated

    associatedSetData(
      selectedTargetInstance,
      associateReferences,
      selectedType,
      associatedTargetMapped,
      references,
      modifiedAssociatedObjects
    );
  };

  useEffect(() => {
    if (state.targetNodeMappedData && !state.searchTargetFlag) {
      const errorTargetMapped = new Map();

      state.targetNodeMappedData.forEach((v) => {
        errorTargetMapped.set(v.NodeId, v);
      });

      setState((draft) => {
        draft.targetNodeMappedDataNodeKey = errorTargetMapped;
      });
    }
  }, [setState, state.searchTargetFlag, state.targetNodeMappedData]);

  //  disable instance and type buttons below
  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedElements = selectedItems[0] as string;

      let objectInstanceDisabled = true;
      let objectTypeDisabled = true;

      let selectedItemsInTarget: NodeSetEditorData | undefined | null = null;
      let findSelectedItemSource: NodeSetEditorData | undefined | null = null;

      // find Selected Element in source tree
      if (state.sourceNodeMappedData && !selectedElements?.includes("tar")) {
        findSelectedItemSource =
          state.sourceNodeMappedData.get(selectedElements);

        const findObject = findSelectedItemSource?.NodeClass;

        if (findObject === variablesMappingTool.UAOBJECTTYPE) {
          objectTypeDisabled = false;
        }
        if (findObject === variablesMappingTool.UAOBJECT) {
          objectInstanceDisabled = false;
        }
      }

      // find Selected Element in target tree
      if (selectedElements?.includes("tar") && state.targetNodeMappedData) {
        selectedItemsInTarget =
          state.targetNodeMappedData.get(selectedElements);
      }

      setState((updateState) => {
        updateState.objectTypeDisabled = objectTypeDisabled;
        updateState.objectInstanceDisabled = objectInstanceDisabled;

        updateState.findSelectedItemSource = findSelectedItemSource;
        updateState.selectedItemsInTarget = selectedItemsInTarget;
      });
    }
  }, [
    selectedItems,
    setState,
    state.sourceNodeMappedData,
    state.targetNodeMappedData,
  ]);

  useEffect(() => {
    if (state.selectedItemsInTarget && state.selectedItemsInTarget.data) {
      const selectedElements = state.selectedItemsInTarget.index as string;
      const selectedElementsTarget = selectedElements.includes("tar");
      const deleteKeyHandler = (event: any) => {
        event.stopPropagation();
        if (
          selectedElementsTarget &&
          !state.renamedItemObject &&
          event.key === "Delete"
        ) {
          deleteItemsInTargetNode();
        }
      };
      document.addEventListener("keyup", deleteKeyHandler);
      return () => {
        document.removeEventListener("keyup", deleteKeyHandler);
      };
    }
  }, [
    deleteItemsInTargetNode,
    state.renamedItemObject,
    state.selectedItemsInTarget,
  ]);

  const closeAssociatedTypeModal = () => {
    setState((updateState) => {
      updateState.openAssociatedTypeModal = false;
    });
    dispatch(isLoadingFor(false));
  };

  const associateTypeModalRenderer = useCallback(
    (
      instanceOrTarget: string,
      selectedType?: NodeSetEditorData,
      selectedTargetInstance?: NodeSetEditorData,
      associatedName?: string
    ) => {
      let message: string | JSX.Element = "";

      switch (instanceOrTarget) {
        case variablesMappingTool.TYPE:
          message = `This ${state.selectedItemsInTarget?.DisplayName[0].Value} is associated with the instance ${associatedName}. Are you sure you want to delete?`;

          break;

        case variablesMappingTool.OBJECT:
          message = (
            <div className="messagesAssociate">
              <p>
                Selected object will be structured as per associated type and
                existing child nodes will be removed to match the type
                structure.
              </p>
              <p>
                (Hint: Child nodes different from type structure can be added to
                an object post type association).{" "}
              </p>
              <p>Please confirm ok to continue  or cancel to revert.</p>
            </div>
          );
          break;
        default:
          message = "";
      }
      setState((prevState) => {
        prevState.openAssociatedTypeModalConfirm = instanceOrTarget;
        prevState.openAssociatedTypeModalMessage = message;
        prevState.openAssociatedTypeModal = true;
        if (selectedType && selectedTargetInstance) {
          prevState.associatedTypeAssociationRender = {
            selectedType,
            selectedTargetInstance,
          };
        }
      });
    },
    [setState, state.selectedItemsInTarget?.DisplayName]
  );
  return (
    <>
      {state.treeItems && (
        <NComplexTree
          items={state.treeItems}
          edId={id}
          copyTypeFunction={copyTypeFunction}
          menuFlag={menuFlag}
          addNewTypeOrInstance={addNewTypeOrInstance}
          CreateNewTypeInstanceScroll={CreateNewTypeInstanceScroll}
          renameTargetItems={renameTargetItems}
          OnDropOfSource={OnDropOfSource}
          deleteItems={deleteItemsInTargetNode}
          disableDelete={disableDelete}
          clickButtonEvent={clickButtonEvent}
          newFolderCreation={newFolderCreation}
          selectedItems={selectedItems}
          focusedItem={focusedItem}
          expandedItems={expandedItems}
          setSelectedItems={setSelectedItems}
          setFocusedItem={setFocusedItem}
          setExpandedItems={setExpandedItems}
          objectInstanceDisabled={state.objectInstanceDisabled}
          objectTypeDisabled={state.objectTypeDisabled}
          disabledExportSource={
            !state.sourceNodeMappedData ||
            state.sourceNodeMappedData?.size === 0
          }
          disableDownloadAndExportTarget={
            !state.targetNodeMappedData ||
            state.targetNodeMappedData?.size === 0
          }
          setState={setState}
          state={state}
          associatedTypeToInstance={associatedTypeToInstance}
          associateTypeModalRenderer={associateTypeModalRenderer}
          disassociateTypeExisting={disassociateTypeExisting}
          setMenuFlag={setMenuFlag}
          saveUpdateValidation={saveUpdateValidation}
          loadTimerSet={loadTimerSet}
          findInstanceAssociatedWithTypeToBeDeleted={
            findInstanceAssociatedWithTypeToBeDeleted
          }
        />
      )}
      <div
        className="buttons"
        style={{
          marginTop: "430px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      ></div>

      <div className="popup">
        <div>
          <MessageDialog
            className={
              state.openAssociatedTypeModalConfirm ===
              variablesMappingTool.OBJECT
                ? "popupNewAssociation"
                : "popupExistingAssociation"
            }
            isOpen={state.openAssociatedTypeModal}
            buttons="okcancel"
            message={state.openAssociatedTypeModalMessage}
            title="Confirm"
            onExit={closeAssociatedTypeModal}
            onCancel={closeAssociatedTypeModal}
            onOk={() => {
              if (
                state.openAssociatedTypeModalConfirm ===
                variablesMappingTool.TYPE
              ) {
                deleteItemsInTargetNode();
                closeAssociatedTypeModal();
              } else {
                associatedTypeToInstance(
                  state.associatedTypeAssociationRender
                    ?.selectedType as NodeSetEditorData,
                  state.associatedTypeAssociationRender
                    ?.selectedTargetInstance as NodeSetEditorData
                );
                closeAssociatedTypeModal();
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
export { NewComplexTree };

function copyTypeMappedFinal(
  x: NodeSetEditorData,
  newTargetObjects: NodeSetEditorData[],
  setObjectBaseReference: (
    x: NodeSetEditorData,
    objectTypeBaseReferences: NodeSetEditorDataReferences[]
  ) => NodeSetEditorDataReferences[],
  clonedTargetMapped: Map<TreeItemIndex, NodeSetEditorData>,
  setSelectedFocusedItem: (
    e: React.MouseEvent<
      HTMLDivElement | HTMLButtonElement | HTMLAnchorElement,
      MouseEvent
    >,
    inputIndex: TreeItemIndex
  ) => void
) {
  let childrenIndexes: TreeItemIndex[] = [];
  if (x.hasChildren) {
    childrenIndexes = newTargetObjects
      .filter((y) => y.parentIndex === x.index)
      .map((z) => z.index);
  }

  let objectTypeBaseReferences: NodeSetEditorDataReferences[] = [];
  objectTypeBaseReferences = setObjectBaseReference(
    x,
    objectTypeBaseReferences
  );
  clonedTargetMapped.set(x.index, {
    ...x,
    children: childrenIndexes,
    TypeNodeId: "",
    data: (
      <ItemDisplayElement
        index={x.index}
        nodeClass={x.NodeClass}
        folderOrNot={x.isFolder}
        titleTooltip={x.TitleTooltip || ""}
        name={
          x.NodeClass === variablesMappingTool.UAOBJECT
            ? x.DisplayName[0].Value
            : x.modifiedName
        }
        setSelectedFocusedItem={setSelectedFocusedItem}
      />
    ),

    isFolder: x.isFolder,
    References:
      x.NodeClass === variablesMappingTool.UAOBJECT
        ? objectTypeBaseReferences
        : x.References,
    additionalText:
      x.NodeClass === variablesMappingTool.UAOBJECT ? "" : x.additionalText,
    modifiedName:
      x.NodeClass === variablesMappingTool.UAOBJECT
        ? x.DisplayName[0].Value
        : x.modifiedName,
  });
}

function deleteChildrenIndexFromParent(
  gotParentNodeKey: Map<string, NodeSetEditorData>,
  selectedItem: NodeSetEditorData,
  deleteMaps: Map<TreeItemIndex, NodeSetEditorData>
) {
  const getNodeIdOfParent = gotParentNodeKey.get(
    selectedItem.ParentNodeId as string
  );
  if (getNodeIdOfParent) {
    const getParentOfChildren = deleteMaps.get(getNodeIdOfParent.index);

    if (getParentOfChildren) {
      const newChildren = getParentOfChildren?.children.filter(
        (s) => s !== selectedItem?.index
      );
      deleteMaps.set(getParentOfChildren?.index, {
        ...getParentOfChildren,
        children: newChildren || [],
      });
    }
  }
}

function getDisplayNames(
  value: NodeSetEditorData,
  nodeDataMapSourceNodeKey: Map<string, NodeSetEditorData>
) {
  return () => {
    let nameElement = value.DisplayName?.[0].Value;
    let additionalTextDisplay = "";

    if (value.NodeClass === variablesMappingTool.UAVARIABLE) {
      nameElement = `${value.DisplayName?.[0].Value}, ${value.DataType || ""}`;
      additionalTextDisplay = value.DataType || "";
    } else if (value.NodeClass === variablesMappingTool.UAOBJECT) {
      const foundTypeDef = value.References.find(
        (s) => s.IsForward && s.ReferenceType === HAS_TYPE_DEFINITION
      );

      if (foundTypeDef && foundTypeDef.Value && foundTypeDef.Value.length > 4) {
        const foundTypeElement = nodeDataMapSourceNodeKey.get(
          foundTypeDef.Value
        );
        additionalTextDisplay = foundTypeElement?.DisplayName[0].Value || "";
        nameElement = foundTypeElement
          ? `${value.DisplayName?.[0].Value}, ${
              foundTypeElement?.DisplayName[0].Value || ""
            }`
          : value.DisplayName?.[0].Value;
      }
    }
    return { nameElement, additionalTextDisplay };
  };
}
