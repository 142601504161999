import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import actionWatcherServers, {
  watchHandleServerStatus,
  watchHandleCurrentServerBackgroundCall,
  watchSessionStateCall,
  watchServerAfterUpload,
} from "../Views/Servers/Saga/SagaServers";
import actionWatcherCommons from "../Common/Saga/SagaCommon";
import actionWatcherBrowse from "../Views/Browse/Saga/SagaBrowse";
import nodeSetEditorSagaFunction from "../Views/Browse/Saga/NodeTreeSagas/nodeTreeSaga";

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<unknown>>,
  void,
  unknown
> {
  yield all([
    actionWatcherServers(),
    watchHandleServerStatus(),
    watchHandleCurrentServerBackgroundCall(),
    watchSessionStateCall(),
    watchServerAfterUpload(),
    actionWatcherBrowse(),
    actionWatcherCommons(),
    fork(nodeSetEditorSagaFunction),
  ]);
}
